import { UiButtonModule } from "@air-gmbh/ui/button";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RecalculateButtonComponent } from "./recalculate-button/recalculate-button.component";

@NgModule({
  imports: [CommonModule, UiButtonModule],
  declarations: [RecalculateButtonComponent],
  exports: [RecalculateButtonComponent],
})
export class FeatureRecalculateButtonModule {}
