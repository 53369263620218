import { RANKED_ROLES, ROLES, RolesCheck } from "@air-gmbh/util/constants";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AuthService } from "./auth.service";

@Injectable()
export class UserService {
  constructor(
    private readonly auth: AuthService,
    private readonly router: Router
  ) {}

  getUserId(): Observable<string> {
    return this.auth
      .getUserData()
      .pipe(map((userData) => userData.userData.airId));
  }

  getRoles(): Observable<RolesCheck> {
    return this.getOrderedRoles().pipe(
      map((roles) => {
        return {
          isAdmin: roles.includes(ROLES.ADMIN),
          isCompanion: roles.includes(ROLES.COMPANION),
          isClient: roles.includes(ROLES.CLIENT),
        };
      })
    );
  }

  getHighestRole(): Observable<ROLES> {
    return this.getOrderedRoles().pipe(
      map((roles) => {
        if (!roles[0]) {
          this.router.navigate(["error"]);
          throw new Error("User has no roles");
        }
        return roles[0];
      })
    );
  }

  /**
   * Get roles in order from most to least permissions
   */
  private getOrderedRoles(): Observable<Array<ROLES>> {
    return this.auth.getUserData().pipe(
      map(({ userData }) => {
        const filtered = userData.realm_roles.filter((role) =>
          Object.values<string>(ROLES).includes(role)
        );
        const roles = filtered.map((roleName) => roleName as ROLES);
        return roles.sort((roleA, roleB) => {
          return RANKED_ROLES[roleA] - RANKED_ROLES[roleB];
        });
      })
    );
  }
}
