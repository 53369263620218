export class Profile {
  id?: string;
  firstName?: string;
  lastName?: string;
  birthdate?: string;
  get fullName(): string {
    return [this.firstName, this.lastName].join(" ").trim();
  }

  constructor(id: string, firstName: string, lastName: string);
  constructor(id: string, firstName?: string, lastName?: string);
  constructor(id: string | undefined, firstName: string, lastName: string);
  constructor(id?: string, firstName?: string, lastName?: string) {
    this.id = id;
    this.firstName = firstName;
    this.lastName = lastName;
  }
}
