import { Component, Input } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { AbstractInput } from "../abstract-input";

@Component({ template: "" })
export abstract class CustomInputComponent extends AbstractInput {
  @Input() id = "";
  @Input() placeholder = "";
  @Input() class = "";
  @Input() showError = true;
  @Input() showErrorMessages = false;
  @Input() hasExternalError = false;

  constructor(protected override readonly fb: UntypedFormBuilder) {
    super(fb);
  }

  get classes(): string {
    return (this.errors || this.hasExternalError) && this.showError
      ? `error ${this.class}`
      : this.class;
  }
}
