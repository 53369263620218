import { FlagsService } from "@air-gmbh/util/environment";
import { ErrorUtil, GraphQLNativeError } from "@air-gmbh/util/error";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { first } from "rxjs/operators";
import { ErrorService } from "./error.service";

@Injectable()
export class GraphQLService {
  constructor(
    private readonly flagsService: FlagsService,
    private readonly router: Router,
    private readonly errorService: ErrorService
  ) {}

  handleUnexpectedError(error: GraphQLNativeError): Observable<any> {
    const featureObservable = this.flagsService.hasFeature("stable");
    featureObservable.subscribe((isStable) => {
      if (!isStable) {
        this.errorService.handleError(ErrorUtil.transformError(error));
      }
    });
    return featureObservable;
  }

  handleInvalidClientError(error: GraphQLNativeError): Observable<any> {
    const featureObservable = this.flagsService.hasFeature("stable");
    featureObservable.pipe(first()).subscribe((isStable) => {
      if (!isStable) {
        if (
          error.graphQLErrors &&
          error.graphQLErrors[0] &&
          error.graphQLErrors[0].extensions
        ) {
          const code = (
            error.graphQLErrors[0].extensions["codes"] as string[]
          ).find((item) =>
            ["INVALID_INPUT", "FORMAT", "OPERATION_FAULT"].includes(item)
          );

          if (code != null) {
            if (code === "INVALID_INPUT" || code === "FORMAT") {
              this.router.navigate(["error"]);
            } else if (code === "OPERATION_FAULT") {
              this.router.navigate(["not-found"]);
            }
          }
          throw error;
        }
      }
    });
    return featureObservable;
  }
}
