import { UiIconModule } from "@air-gmbh/ui/icon";
import { PipesModule } from "@air-gmbh/util/pipes";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { WishCategoryComponent } from "./wish-category/wish-category.component";
import { WishComponent } from "./wish/wish.component";

@NgModule({
  declarations: [WishCategoryComponent, WishComponent],
  imports: [
    CommonModule,
    UiIconModule,
    TranslateModule,
    PipesModule,
    UiIconModule,
  ],
  exports: [WishCategoryComponent, WishComponent],
})
export class UiWishModule {}
