<div
  *ngIf="!collapsed; else collapsedTpl"
  class="step-bar flex flex-row justify-between gap-8 bg-grey-light"
>
  <div class="step-left my-4 flex flex-none flex-row justify-start">
    <div
      *ngIf="showPreviousButton"
      class="chevron-wrapper cursor-pointer"
      (click)="goPrevious()"
    >
      <air-icon faClasses="angle-left"></air-icon>
    </div>
  </div>
  <ng-container *ngFor="let step of this.steps; let i = index">
    <app-step
      class="flex grow flex-row justify-center"
      [ngClass]="{
        'cursor-pointer': canVisit(i),
        active: i === activeStep
      }"
      [step]="step"
      [active]="i === activeStep"
      [visited]="i <= highestVisitedStep"
      (click)="goTo(i)"
    >
    </app-step>
    <div
      *ngIf="i < steps.length - 1"
      class="my-7 h-0.5 grow"
      [class.bg-primary]="i < highestVisitedStep"
      [class.bg-grey]="i >= highestVisitedStep"
    ></div>
  </ng-container>

  <div class="step-right my-4 flex flex-none flex-row justify-end">
    <div
      class="chevron-wrapper"
      [ngClass]="{
        'cursor-pointer': canVisitNext,
        'text-grey': !canVisitNext
      }"
      (click)="goNext()"
    >
      <air-icon faClasses="angle-right"></air-icon>
    </div>
  </div>
</div>

<ng-template #collapsedTpl>
  <div class="step-bar flex flex-row justify-center gap-8 bg-grey-light">
    <div
      class="step-left my-4 flex flex-none cursor-pointer flex-row justify-start"
    >
      <div
        *ngIf="showPreviousButton"
        class="chevron-wrapper"
        (click)="goPrevious()"
      >
        <air-icon faClasses="angle-left"></air-icon>
      </div>
    </div>
    <app-step
      class="flex flex-row justify-center"
      [step]="steps[activeStep]"
      [collapsed]="true"
    >
    </app-step>

    <div
      class="step-right my-4 flex flex-none cursor-pointer flex-row justify-end"
    >
      <div
        class="chevron-wrapper"
        [ngClass]="{
          'cursor-pointer': canVisitNext,
          'text-grey': !canVisitNext
        }"
        (click)="goNext()"
      >
        <air-icon faClasses="angle-right"></air-icon>
      </div>
    </div>
  </div>
</ng-template>
