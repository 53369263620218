import { Component, Inject, InjectionToken, Input } from "@angular/core";
import { WishCategory } from "../wish";

/**
 * The base path for category translation entries.
 * <base-path>.<category-name> must be a valid translation entry
 * for all category-names.
 */
export const WISH_CATEGORY_TRANSLATION_PATH = new InjectionToken<string>(
  "WISH_CATEGORY_TRANSLATION_PATH"
);

@Component({
  selector: "air-wish-category",
  templateUrl: "./wish-category.component.html",
})
export class WishCategoryComponent {
  @Input() category?: WishCategory;

  constructor(
    @Inject(WISH_CATEGORY_TRANSLATION_PATH)
    private readonly translationPath: string
  ) {}

  get categoryTranslationKey(): string {
    if (this.category) {
      return `${this.translationPath}.${this.category.name}`;
    } else {
      return "";
    }
  }
}
