import { UserService } from "@air-gmbh/data-access/auth";
import { HouseholdService } from "@air-gmbh/data-access/household";
import { AirboardStatus, AIRBOARD_EDIT, ROLES } from "@air-gmbh/util/constants";
import { AirError, MissingIdError } from "@air-gmbh/util/error";
import { ParamsService } from "@air-gmbh/util/general";
import { Profile } from "@air-gmbh/util/types";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { combineLatest, partition, Subscription } from "rxjs";
import { map, switchMap, tap } from "rxjs/operators";
import { C } from "ts-toolbelt";
import { DropdownElement } from "../../shared/components/button-dropdown/dropdown-element";
import { MenuDropdownItem } from "../menu-dropdown/menu-dropdown.type";

class UnexpectedRoleError extends AirError {
  constructor(
    source: C.Class | string,
    expectedRoles: ROLES[],
    role: ROLES | string | undefined
  ) {
    super(source, `Expected one of ${expectedRoles} roles, but got: ${role}`);
  }
}

@Component({
  template: "",
  providers: [ParamsService],
})
export class ClientAccountComponent implements OnInit, OnDestroy {
  profile?: Profile;
  items: MenuDropdownItem[] = [
    {
      text: "client.details",
      show: true,
      handler: this.goToClientDetails.bind(this),
    },
    {
      text: "tour.personalInformation.title",
      show: true,
      handler: this.goToPersonalInformation.bind(this),
    },
    {
      text: "airboard.ref",
      show: true,
      handler: this.goToAIRboard.bind(this),
    },
  ];

  // Delete once the ClientBadgeComponent can be removed
  open = false;
  elements: DropdownElement[] = [];

  get profileFullName(): string {
    if (this.profile == null) {
      return "";
    }
    return this.profile.fullName;
  }

  private sub = new Subscription();
  private householdId?: string;
  private clientId?: string;

  constructor(
    protected readonly router: Router,
    protected readonly paramsService: ParamsService,
    protected readonly userService: UserService,
    protected readonly translate: TranslateService,
    protected readonly householdService: HouseholdService
  ) {}

  ngOnInit(): void {
    this.sub.add(
      this.paramsService
        .getParam("householdId")
        .pipe(
          tap((householdId) => {
            this.householdId = householdId;
          }),
          switchMap((householdId) =>
            combineLatest([
              this.householdService.fetchClientId(householdId),
              this.householdService.getHouseholdProfiles(householdId),
            ])
          )
        )
        .subscribe(([clientId, profiles]) => {
          this.clientId = clientId;
          this.profile = profiles.contactMember;
        })
    );

    this.elements = this.items.map((item) => ({
      title: item.text != null ? this.translate.instant(item.text) : "",
      show: true,
      textColor: "text-black",
      click: item.handler ?? ((): void => {}),
    }));
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  private goToClientDetails(): void {
    const clientId = this.clientId;
    if (clientId == null) {
      throw new MissingIdError(ClientAccountComponent, "clientId");
    }

    const [companion$, admin$] = partition(
      this.userService.getHighestRole(),
      (role) => {
        if ([ROLES.COMPANION, ROLES.ADMIN].includes(role)) {
          return role === ROLES.COMPANION;
        } else {
          throw new UnexpectedRoleError(
            ClientAccountComponent,
            [ROLES.COMPANION, ROLES.ADMIN],
            role
          );
        }
      }
    );

    this.sub.add(
      companion$.subscribe(() => {
        this.router.navigate(["companion", "clients", clientId]);
      })
    );

    this.sub.add(
      admin$
        .pipe(
          switchMap(() =>
            this.householdService.fetchIds(clientId).pipe(
              map((res) => {
                const companionId = res.companion.current;
                if (companionId == null) {
                  throw new AirError(
                    ClientAccountComponent,
                    "Companion id doesn't exist"
                  );
                }
                return companionId;
              })
            )
          )
        )
        .subscribe((companionId) => {
          this.router.navigate([
            "admin",
            "companions",
            companionId,
            "client",
            clientId,
          ]);
        })
    );

    this.open = false;
  }

  private goToPersonalInformation(): void {
    if (this.householdId != null) {
      this.router.navigate([
        "airboard",
        this.householdId,
        "edit",
        AIRBOARD_EDIT[AirboardStatus.PersonalInformation].redirect,
      ]);
    }
    this.open = false;
  }

  private goToAIRboard(): void {
    if (this.householdId) {
      this.router.navigate(["airboard", this.householdId]);
    }

    this.open = false;
  }
}
