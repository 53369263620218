import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AccordionService {
  private readonly collapsed = new BehaviorSubject<boolean>(true);
  areCollapsed(): Observable<boolean> {
    return this.collapsed.asObservable();
  }

  collapseAll(): void {
    this.collapsed.next(true);
  }

  expandAll(): void {
    this.collapsed.next(false);
  }
}
