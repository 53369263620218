import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ContentLoaderModule } from "@ngneat/content-loader";
import { ChipComponent } from "./chip.component";

@NgModule({
  imports: [CommonModule, ContentLoaderModule],
  declarations: [ChipComponent],
  exports: [ChipComponent],
})
export class UiChipModule {}
