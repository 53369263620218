<div airOuterClick (clickOutside)="close()" class="w-100 flex justify-end">
  <div class="relative inline-block text-left">
    <air-button
      (click)="toggle()"
      [variant]="buttonVariant"
      [color]="buttonColor"
      [leftIcon]="buttonLeftIcon"
      [rightIcon]="showChevron ? 'angle-down' : buttonRightIcon"
      [onlyIcon]="buttonOnlyIcon"
    >
      {{ title }}
    </air-button>

    <div
      [ngClass]="{ hidden: !open }"
      class="absolute right-0 mt-2 w-56 origin-top-right divide-y-2 divide-grey-light rounded-lg bg-white-pure p-1 shadow-lg focus:outline-none"
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="menu-button"
      tabindex="-1"
      data-cy="dropdown-menu"
    >
      <ng-container *ngFor="let elem of elements">
        <div
          *ngIf="elem.show"
          (click)="elem.click()"
          [ngClass]="{
            'px-2 py-3': withPadding
          }"
          class="cursor-pointer hover:bg-grey-light {{ elem.textColor }}"
          role="none"
        >
          <span *ngIf="getString(elem.title) as title">
            {{ title }}
          </span>
          <ng-container *ngIf="getTemplateRef(elem.title) as title">
            <ng-container *ngTemplateOutlet="title"></ng-container>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </div>
</div>
