import { CreateVehicleV2GQL } from "@air-gmbh/data-access/graphql";
import { ErrorUtil } from "@air-gmbh/util/error";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { CreateVehicle } from "./types";

@Injectable()
export class VehicleService {
  constructor(private readonly createVehicleGQL: CreateVehicleV2GQL) {}

  createVehicle(
    householdId: string,
    costs: number
  ): Observable<CreateVehicle | null> {
    return this.createVehicleGQL
      .mutate({
        input: {
          householdId,
          costs,
        },
      })
      .pipe(
        map((res) => {
          if (res.data?.createVehicleV2.errors != null) {
            // TODO: Throw bussines logic errors
          }
          return res.data?.createVehicleV2.vehicle ?? null;
        }),
        catchError((err) => {
          throw ErrorUtil.transformError(err);
        })
      );
  }
}
