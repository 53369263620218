import { CreateEmploymentInput as CreateEmploymentInputGQL } from "@air-gmbh/data-access/graphql";
import { CreateEmploymentInput } from "@air-gmbh/util/types";
import { Injectable } from "@angular/core";
import { TypeMapper } from "../../common/mapper.interface";
import { EmploymentCategoriesToGQL } from "./categories";
import { LocationToGQL } from "./location";

@Injectable()
export class CreateEmploymentInputMapper
  implements TypeMapper<CreateEmploymentInputGQL, CreateEmploymentInput>
{
  toGraphQLType(rawType: CreateEmploymentInput): CreateEmploymentInputGQL {
    return {
      memberId: rawType.memberId,
      employmentIsPhysicalWork: rawType.isPhysicalWork,
      yearlyGrossIncome: rawType.yearlyGrossIncome,
      hasPensionObligation: rawType.hasPensionPaymentObligation,
      employmentCategory:
        rawType.employmentCategory != null
          ? EmploymentCategoriesToGQL[rawType.employmentCategory]
          : null,
      employmentFederalState:
        rawType.federalState != null
          ? LocationToGQL[rawType.federalState]
          : null,
    };
  }
}
