import { FinancialStructureBalance, LoadingAmount } from "@air-gmbh/util/types";
import { Injectable } from "@angular/core";
import { Nullable } from "ts-toolbelt/out/Union/Nullable";
import { TypeMapper } from "../common/mapper.interface";

export type FinancialStructureBalanceGraphQL = {
  id: string;
  riskBuffer: number;
  financialStructureBalanceV2?: Nullable<{
    costs: LoadingAmount;
    netIncomeAmount: LoadingAmount;
  }>;
  contactMember: { id: string; creditRate: number };
  partner?: Nullable<{
    id: string;
    creditRate: number;
  }>;
};

@Injectable()
export class FinancialStructureBalanceMapper
  implements
    TypeMapper<FinancialStructureBalanceGraphQL, FinancialStructureBalance>
{
  toRawType(
    graphQLType: FinancialStructureBalanceGraphQL
  ): FinancialStructureBalance {
    const balance: LoadingAmount = graphQLType.financialStructureBalanceV2
      ?.costs ?? { amount: 0, isRecalculating: true };
    const netIncome: LoadingAmount = graphQLType.financialStructureBalanceV2
      ?.netIncomeAmount ?? { amount: 0, isRecalculating: true };

    const ret: FinancialStructureBalance = {
      balance,
      netIncome,
      contactMemberCreditRate: graphQLType.contactMember.creditRate,
      partnerCreditRate: graphQLType.partner?.creditRate ?? undefined,
      riskBuffer: graphQLType.riskBuffer,
    };

    return ret;
  }
}
