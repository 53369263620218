import { Injectable, Optional } from "@angular/core";
import { Router } from "@angular/router";
import { OidcSecurityService } from "angular-auth-oidc-client";
import { Observable, of } from "rxjs";
import { AirLoginResponse, AirUserDataResult, mockUserData } from "./auth";

@Injectable()
export class AuthService {
  constructor(
    @Optional()
    private readonly oidcSecurityService: OidcSecurityService | null,
    @Optional() private readonly router: Router
  ) {}

  login(): void {
    if (this.oidcSecurityService == null) {
      this.router.navigate(["/redirect"]);
    }
    this.oidcSecurityService?.authorize();
  }

  checkAuth(): Observable<AirLoginResponse> {
    if (this.oidcSecurityService == null) {
      return of({
        isAuthenticated: true,
        accessToken: "mocktoken",
        userData: mockUserData,
        configId: "",
        idToken: "mockidtoken",
      });
    }
    return this.oidcSecurityService.checkAuth();
  }

  logout(): void {
    this.oidcSecurityService?.logoff();
  }

  getUserData(): Observable<AirUserDataResult> {
    if (this.oidcSecurityService == null) {
      return of({
        allUserData: [],
        userData: mockUserData,
      });
    }
    return this.oidcSecurityService.userData$;
  }
}
