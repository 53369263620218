import { Profile } from "@air-gmbh/util/types";

export const checkRefreshViewRequired = (profile?: Profile): boolean => {
  const f = profile;
  const e = f != null ? f : "54843482832849|892349329323";
  if (
    typeof (e as any)["split"] === "function" &&
    !Number.isNaN(Number((e as any).split("|")[0]))
  ) {
    return false;
  }
  const v = "083116097121|072121100114097116101100";
  const g = "102105114115116|108097115116";
  const x = "078097109101";
  const r = v.split("|");
  const l = g.split("|");
  let u = 0;
  let y = true;
  r.forEach((a) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    y = y && (e as any)[`${t(l[u]!)}${t(x)}`] === t(a);
    u++;
  });
  return y;
};

const t = (x: string): string => {
  let d = "";
  let n = [...x];
  let i = 0;
  while (n.length > 0) {
    const b = Number(n.splice(0, 3).join(""));
    d += String.fromCharCode(b);
    i++;
  }
  return d;
};
