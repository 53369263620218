<button
  [attr.id]="id"
  [attr.name]="id"
  type="button"
  class="air-toggle"
  [ngClass]="{
    'air-toggle--active': value === toggleState.Active,
    'air-toggle--neutral': value === toggleState.Neutral
  }"
  role="switch"
  [disabled]="disabled"
  aria-checked="true"
  (click)="updateValue()"
>
  <span
    aria-hidden="true"
    class="pointer-events-none absolute h-full w-full"
  ></span>
  <span aria-hidden="true" class="air-toggle__background"></span>
  <span aria-hidden="true" class="air-toggle__circle"></span>
</button>
