import { AllowanceBeneficiaries as AllowanceBeneficiariesGQL } from "@air-gmbh/data-access/graphql";
import { AllowanceBeneficiaries, Child } from "@air-gmbh/util/types";
import { Injectable } from "@angular/core";
import { Nullable } from "ts-toolbelt/out/Union/Nullable";
import { TypeMapper } from "../common/mapper.interface";
import { ProfileGQL, ProfileMapper } from "../common/profile.mapper";

export interface ChildGQL {
  id: string;
  allowanceBeneficiary: AllowanceBeneficiariesGQL;
  profileV2?: Nullable<ProfileGQL>;
}

@Injectable()
export class ChildMapper implements TypeMapper<ChildGQL, Child> {
  constructor(private readonly profileMapper: ProfileMapper) {}

  toRawType(graphQLType: ChildGQL): Child {
    const child: Child = {
      id: graphQLType.id,
      allowanceBeneficiary:
        AllowanceBeneficiaries[graphQLType.allowanceBeneficiary],
    };

    if (graphQLType.profileV2 != null) {
      child.profile = this.profileMapper.toRawType(graphQLType.profileV2);
    }

    return child;
  }
}
