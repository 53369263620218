import { AuthService } from "@air-gmbh/data-access/auth";
import { SessionStorageService } from "@air-gmbh/data-access/session-storage";
import { GraphqlSetupService } from "@air-gmbh/feature/graphql";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { ApolloClientOptions } from "@apollo/client/core";
import { AuthInterceptor } from "angular-auth-oidc-client";
import { ApolloModule, APOLLO_OPTIONS } from "apollo-angular";
import { HttpLink } from "apollo-angular/http";
import { environment } from "../../environments/environment";

const createApollo = (
  setupService: GraphqlSetupService
): ApolloClientOptions<any> => {
  return setupService.createApollo();
};

@NgModule({
  imports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [GraphqlSetupService, HttpLink, AuthService, SessionStorageService],
    },
    // TODO: Compile time check that needs to be refactored
    environment.flags.features.auth
      ? { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
      : [],
  ],
})
export class GraphQLModule {}
