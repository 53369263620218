import { UserData } from "@air-gmbh/data-access/auth";
import { Observable, of, throwError } from "rxjs";
import { IProfileService, ProfileData } from "./i-profile-service";

export class EmptyGuidProfileService implements IProfileService {
  constructor(private readonly userData: UserData) {}

  get profileData(): Observable<ProfileData> {
    return of({
      contactEmailAddress: this.userData.preferred_username ?? "User",
    });
  }

  get name(): Observable<string> {
    return of(this.userData.preferred_username ?? "User");
  }

  updateEmail(): Observable<string> {
    return throwError("User with empty Guids can't update their email.");
  }

  updatePhoneNumber(): Observable<string> {
    return throwError("User with empty Guids can't update their phone number");
  }
}
