import { TourStatus } from "@air-gmbh/util/constants";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

export class Step {
  constructor(
    public tourStatuses: TourStatus[],
    public icon: string | undefined = undefined,
    public name: string = ""
  ) {}
}

@Component({
  selector: "app-step",
  templateUrl: "./step.component.html",
  styleUrls: ["./step.component.css"],
})
export class StepComponent implements OnInit {
  @Input() step?: Step;
  @Input() active: boolean = true;
  @Input() visited: boolean = false;
  @Input() collapsed: boolean = false;

  @Output() collapsedChange = new EventEmitter<boolean>();

  ngOnInit(): void {
    if (this.step == null) {
      throw new Error("Error in StepComponent: No Step provided.");
    }
  }
}
