import { Lifestyle } from "@air-gmbh/util/types";
import { LIFESTYLE_KEYS } from "./lifestyle.constant";

export class LifestyleUtil {
  /**
   * Gets the total cost of a lifestyle
   * @param lifestyle
   * @returns Sum of all the lifestyle categories
   */
  static getLifestyleTotalCosts(lifestyle: Partial<Lifestyle>): number {
    return LIFESTYLE_KEYS.map((key) =>
      lifestyle[key] ? (lifestyle[key] as number) : 0
    ).reduce((prev, curr) => prev + curr);
  }
}
