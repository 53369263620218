import { UpdateLifestyleInput } from "@air-gmbh/data-access/graphql";
import { UpdateLifestyle } from "@air-gmbh/util/types";
import { Injectable } from "@angular/core";
import { TypeMapper } from "../common/mapper.interface";

export type UpdateLifestyleGraphQL = UpdateLifestyleInput;

@Injectable()
export class LifestyleInputMapper
  implements TypeMapper<UpdateLifestyleGraphQL, UpdateLifestyle>
{
  toGraphQLType(rawType: UpdateLifestyle): UpdateLifestyleGraphQL {
    return {
      lifestyleId: rawType.id,
      buffer: rawType.buffer,
      clothing: rawType.clothing,
      education: rawType.education,
      food: rawType.food,
      media: rawType.media,
      miscellaneous: rawType.miscellaneous,
      mobility: rawType.mobility,
      utilityCost: rawType.utilityCost,
      rent: rawType.rent,
      vacation: rawType.vacation,
    };
  }
}
