import { AuthService } from "@air-gmbh/data-access/auth";
import { SessionStorageService } from "@air-gmbh/data-access/session-storage";
import { ENVIRONMENT_CONFIG, IEnvironment } from "@air-gmbh/util/environment";
import { Inject, Injectable } from "@angular/core";
import { ApolloClientOptions, InMemoryCache, split } from "@apollo/client/core";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";
import { HttpLink } from "apollo-angular/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { SubscriptionClient } from "subscriptions-transport-ws";

@Injectable({
  providedIn: "root",
})
export class GraphqlSetupService {
  private webSocketClient?: SubscriptionClient;

  constructor(
    @Inject(ENVIRONMENT_CONFIG) private readonly environment: IEnvironment,
    private readonly httpLink: HttpLink,
    private readonly authService: AuthService,
    private readonly sessionStorageService: SessionStorageService
  ) {}

  createApollo(): ApolloClientOptions<any> {
    const httpLink = this.httpLink.create({
      uri: this.environment.graphql.uri,
    });
    this.webSocketClient = new SubscriptionClient(
      this.environment.graphql.wsUri,
      {
        lazy: true,
        reconnect: true,
        connectionParams: (): Promise<{ authToken: string }> => {
          return this.getWSConnectionParams().toPromise();
        },
      }
    );
    const webSocketLink = new WebSocketLink(this.webSocketClient);

    const link = split(
      // split based on operation type
      ({ query }) => {
        const definition = getMainDefinition(query);
        if (definition.kind === "OperationDefinition") {
          return definition.operation === "subscription";
        }
        return false;
      },
      webSocketLink,
      httpLink
    );

    return {
      cache: new InMemoryCache(),
      defaultOptions: {
        query: {
          fetchPolicy: "network-only",
        },
        watchQuery: {
          fetchPolicy: "cache-and-network",
          nextFetchPolicy: "cache-first",
        },
      },
      link,
    };
  }

  private getWSConnectionParams(): Observable<{ authToken: string }> {
    return this.authService
      .checkAuth()
      .pipe(map((loginResponse) => ({ authToken: loginResponse.accessToken })));
  }
}
