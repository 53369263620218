import {
  EmploymentsByClientIdGQL,
  GetHouseholdIdsGQL,
  GetHouseholdIdsV2GQL,
  HouseholdClientCompanionIdGQL,
  HouseholdClientIdV2GQL,
  HouseholdHasChangedGQL,
  HouseholdHasChangedQuery,
  HouseholdProfileV2GQL,
  MemberTypes,
  OnHouseholdHasChangedDocument,
  OnHouseholdHasChangedSubscription,
} from "@air-gmbh/data-access/graphql";
import {
  HouseholdIdsMapper,
  MemberEmploymentCategoryGQL,
  MemberRetirementMapper,
  ProfileMapper,
} from "@air-gmbh/data-access/mappers";
import { OnSubscriptionChange } from "@air-gmbh/data-access/types";
import { FlagsService } from "@air-gmbh/util/environment";
import { AirError, ErrorUtil } from "@air-gmbh/util/error";
import { deepCopy } from "@air-gmbh/util/general";
import { HouseholdIds, Profile } from "@air-gmbh/util/types";
import { Injectable } from "@angular/core";
import { isNotNullish } from "@tool-belt/type-predicates";
import { Observable } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { HouseholdProfile } from "./types";

@Injectable({
  providedIn: "root",
})
export class HouseholdService {
  constructor(
    private readonly householdIdsGQL: GetHouseholdIdsGQL,
    private readonly householdIdsV2GQL: GetHouseholdIdsV2GQL,
    private readonly householdClientId: HouseholdClientIdV2GQL,
    private readonly householdClientCompanionId: HouseholdClientCompanionIdGQL,
    private readonly householdHasChangedGQL: HouseholdHasChangedGQL,
    private readonly householdProfileV2GQL: HouseholdProfileV2GQL,
    private readonly profileMapper: ProfileMapper,
    private readonly householdIdsMapper: HouseholdIdsMapper,
    private readonly flagService: FlagsService,
    private readonly employmentsByClientIdGQL: EmploymentsByClientIdGQL,
    private readonly memberRetirementMapper: MemberRetirementMapper
  ) {}

  fetchIds(clientId: string): Observable<HouseholdIds> {
    return this.flagService.hasFeature("airboardComparing").pipe(
      switchMap((hasFeature) => {
        if (hasFeature) {
          return this.householdIdsV2GQL.fetch({ input: { clientId } }).pipe(
            map((res) => {
              const clientHouseholds = res.data.clientById.client?.households;
              if (clientHouseholds == null || clientHouseholds.length === 0) {
                throw new AirError(
                  HouseholdService,
                  "Client has no households"
                );
              }
              return this.householdIdsMapper.toRawType(clientHouseholds);
            })
          );
        }
        return this.householdIdsGQL.fetch({ input: { clientId } }).pipe(
          map((res) => {
            return {
              companion: {
                current: res.data.clientById.client?.companionHouseholdV2?.id,
              },
              client: {
                current: res.data.clientById.client?.clientHouseholdV2?.id,
              },
            };
          })
        );
      })
    );
  }

  fetchClientId(householdId: string): Observable<string> {
    return this.householdClientId.fetch({ id: householdId }).pipe(
      map((res) => res.data.household.client?.id),
      map((clientId) => {
        if (clientId == null) {
          throw new AirError(HouseholdService, "Client in household is empty");
        } else {
          return clientId;
        }
      }),
      catchError((err) => {
        throw ErrorUtil.transformError(err);
      })
    );
  }

  fetchClientCompanionId(
    householdId: string
  ): Observable<{ clientId?: string; companionId?: string }> {
    return this.householdClientCompanionId
      .fetch({ input: { householdId } })
      .pipe(
        map((res) => res.data.householdById.household),
        map((household) => {
          if (household == null) {
            throw new AirError(HouseholdService, "Household is empty");
          }
          return {
            clientId: household.client?.id,
            companionId: household.client?.companionV2?.id,
          };
        }),
        catchError((err) => {
          throw ErrorUtil.transformError(err);
        })
      );
  }

  hasHouseholdChanged(householdId: string): Observable<boolean> {
    const householdHasChangedQuery = this.householdHasChangedGQL.watch({
      input: { householdId },
    });

    householdHasChangedQuery.subscribeToMore<OnHouseholdHasChangedSubscription>(
      {
        document: OnHouseholdHasChangedDocument,
        variables: { input: { householdId } },
        updateQuery: (
          prev: HouseholdHasChangedQuery,
          newData: OnSubscriptionChange<OnHouseholdHasChangedSubscription>
        ) => {
          let result = deepCopy(prev);
          if (result.householdById.household != null) {
            result.householdById.household.hasChanged =
              newData.subscriptionData.data.householdHasChanged.householdChangeState;
            return result;
          }
          return result;
        },
      }
    );

    return householdHasChangedQuery.valueChanges.pipe(
      map((res) => {
        if (res.data.householdById.household != null) {
          return res.data.householdById.household.hasChanged;
        }
        return false;
      })
    );
  }

  getHouseholdProfiles(householdId: string): Observable<HouseholdProfile> {
    return this.householdProfileV2GQL
      .fetch({
        input: { householdId },
      })
      .pipe(
        map((res) => res.data.householdById.household?.members),
        map((members) => {
          if (members != null) {
            const contactMemberProfile = members.find(
              (member) =>
                member != null && member.type === MemberTypes.ContactMember
            )?.profileV2;

            if (contactMemberProfile == null) {
              throw new AirError(HouseholdService, "Missing contact member");
            }

            const partnerMemberProfile = members.find(
              (member) => member != null && member.type === MemberTypes.Partner
            )?.profileV2;
            const newPartnerProfile: Profile | undefined =
              partnerMemberProfile != null
                ? this.profileMapper.toRawType(partnerMemberProfile)
                : undefined;

            return {
              contactMember: this.profileMapper.toRawType(contactMemberProfile),
              partner: newPartnerProfile,
            };
          }
          throw new AirError(HouseholdService, "Household is null");
        }),
        catchError((err) => {
          throw ErrorUtil.transformError(err);
        })
      );
  }

  areMembersRetiredFromClient(clientId: string): Observable<boolean> {
    return this.employmentsByClientIdGQL.fetch({ input: { clientId } }).pipe(
      map((res) => {
        if (res.data.clientById.client?.companionHousehold.members != null) {
          return res.data.clientById.client?.companionHousehold.members.filter(
            isNotNullish
          );
        }
        throw new AirError(
          HouseholdService,
          "There aren't any members in the household"
        );
      }),
      catchError((err) => {
        throw ErrorUtil.transformError(err);
      }),
      map((members: MemberEmploymentCategoryGQL[]) =>
        this.memberRetirementMapper.toRawType(members)
      )
    );
  }
}
