import { DataAccessSettingsModule } from "@air-gmbh/data-access/configuration";
import { DataAccessRecalculateModule } from "@air-gmbh/data-access/recalculate";
import { UiChipModule } from "@air-gmbh/ui/chip";
import { UiIconModule } from "@air-gmbh/ui/icon";
import { UiToggleModule } from "@air-gmbh/ui/toggle";
import { UiTooltipModule } from "@air-gmbh/ui/tooltip";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { ButtonDropdownModule } from "../button-dropdown/button-dropdown.module";
import { SettingsDropdownComponent } from "./settings-dropdown.component";

@NgModule({
  declarations: [SettingsDropdownComponent],
  imports: [
    CommonModule,
    ButtonDropdownModule,
    TranslateModule,
    UiToggleModule,
    UiChipModule,
    UiTooltipModule,
    UiIconModule,
    DataAccessRecalculateModule,
    DataAccessSettingsModule,
  ],
  exports: [SettingsDropdownComponent],
})
export class SettingsDropdownModule {}
