import { Injectable } from "@angular/core";
import { SessionStorageKey } from "./types";

@Injectable({
  providedIn: "root",
})
export class SessionStorageService {
  getItem(key: SessionStorageKey): string | null {
    return window.sessionStorage.getItem(key);
  }

  setItem(key: SessionStorageKey, payload: string): void {
    window.sessionStorage.setItem(key, payload);
  }

  removeItem(key: SessionStorageKey): void {
    window.sessionStorage.removeItem(key);
  }

  clear(): void {
    window.sessionStorage.clear();
  }
}
