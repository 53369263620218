import { UiIconModule } from "@air-gmbh/ui/icon";
import { UiLoaderModule } from "@air-gmbh/ui/loader";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ButtonComponent } from "./button.component";

@NgModule({
  declarations: [ButtonComponent],
  imports: [CommonModule, UiIconModule, UiLoaderModule],
  exports: [ButtonComponent],
})
export class UiButtonModule {}
