import { Profile } from "@air-gmbh/util/types";
import { Injectable } from "@angular/core";
import { TypeMapper } from "./mapper.interface";

export interface ProfileGQL {
  id: string;
  firstName: string;
  lastName: string;
  birthdate?: string | null;
}

@Injectable({ providedIn: "root" })
export class ProfileMapper implements TypeMapper<ProfileGQL, Profile> {
  toRawType(graphQLType: ProfileGQL): Profile {
    const profile = new Profile(
      graphQLType.id,
      graphQLType.firstName,
      graphQLType.lastName
    );
    profile.birthdate = graphQLType.birthdate ?? undefined;
    return profile;
  }
}
