import { InjectionToken } from "@angular/core";
import { LogLevel } from "angular-auth-oidc-client";

export const ENVIRONMENT_CONFIG = new InjectionToken<IEnvironment>(
  "Environment"
);

interface IFlagsStructure {
  features: Record<string, boolean>;
  variants: Record<string, unknown>;
}

export interface IFlags extends IFlagsStructure {
  features: {
    auth: boolean;
    stable: boolean;
    airboardComparing: boolean;
    airboardRedesign: boolean;
    deletePartnerMaritalStatus: boolean;
    civilServantPensioner: boolean;
    editPageLayout: boolean;
    assetRedesign: boolean;
  };
  variants: {};
}

export interface IEnvironment {
  environmentName: string;
  production: boolean;
  locale: {
    language: string;
    fallbackLanguage: string; // Fail rather than fall back.
    currency: string;
  };
  frontend_url: string;
  keycloak: {
    base: string;
    authority: string;
    wellknownEndpoint: string;
    redirectUrl: string;
    postLogoutRedirectUri: string;
    clientId: string;
    scope: string;
    responseType: string;
    logLevel: LogLevel;
    ignoreNonceAfterRefresh: boolean;
  };
  graphql: {
    uri: string;
    wsUri: string;
  };
  sentry: {
    reporting: boolean;
    dsn: string;
  };
  hubspot: {
    contactForm: {
      portalId: string;
      formId: string;
      emailFieldName: string;
      companionTypeFormName: string;
    };
    meetingSrc: string;
  };
  flags: IFlags;
  debounceTime: number;
  alertTime: number;
  loadingDebounce: number;
  printDelay: number;
  externalURLs: {
    AIRGmbh: string;
    imprint: string;
  };
}
