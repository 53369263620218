<div
  class="relative"
  [ngClass]="{
    'hidden sm:block': hideInSmallScreen
  }"
>
  <div
    class="flex h-6 w-6 items-center justify-center"
    airMouseOver
    [(isOver)]="showTooltip"
  >
    <air-icon
      class="cursor-pointer"
      faClasses="question-circle"
      size="sm"
    ></air-icon>
  </div>
  <air-tooltip
    class="absolute top-6"
    [class.-left-4]="tooltipPlacement === 'right'"
    [class.-right-4]="tooltipPlacement === 'left'"
    [ngClass]="tooltipWidth"
    [(visible)]="showTooltip"
    [placement]="tooltipPlacement"
  >
    {{ hint }}
  </air-tooltip>
</div>
