export enum LifestyleTypes {
  CURRENT = "CURRENT",
  RETIREMENT = "RETIREMENT",
  MINIMUM = "MINIMUM",
}

export type Lifestyle = {
  id: string;
  type: LifestyleTypes;
  food: number;
  utilityCost: number;
  rent: number;
  clothing: number;
  education: number;
  media: number;
  vacation: number;
  mobility: number;
  miscellaneous: number;
  buffer: number;
  total: number;
};

export type UpdateLifestyle = Omit<Lifestyle, "total" | "type">;

export type HouseholdLifestyle = {
  householdId: string;
  lifestyles: Record<LifestyleTypes, Lifestyle>;
  areMembersRetired: boolean;
};
