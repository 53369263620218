import { Component, Input } from "@angular/core";
import { ChipColor, chipLoadingConfig, ChipVariant } from "./chip-config";

@Component({
  selector: "air-chip",
  templateUrl: "./chip.component.html",
  styleUrls: ["./chip.component.css"],
})
export class ChipComponent {
  @Input() color: ChipColor = "secondary";
  @Input() variant: ChipVariant = "light";
  @Input() isLoading = false;

  get loadingBackground(): string {
    return chipLoadingConfig[this.color][this.variant].background;
  }

  get loadingForeground(): string {
    return chipLoadingConfig[this.color][this.variant].foreground;
  }
}
