import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AuthModule } from "angular-auth-oidc-client";
import { environment } from "../environments/environment";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    // TODO: Compile time check that needs to be refactored
    environment.flags.features.auth
      ? AuthModule.forRoot({
          config: {
            authority:
              environment.keycloak.base + environment.keycloak.authority,
            authWellknownEndpointUrl:
              environment.keycloak.base +
              environment.keycloak.wellknownEndpoint,
            postLoginRoute: environment.keycloak.redirectUrl,
            postLogoutRedirectUri:
              environment.frontend_url +
              environment.keycloak.postLogoutRedirectUri,
            redirectUrl:
              environment.frontend_url + environment.keycloak.redirectUrl,
            clientId: environment.keycloak.clientId,
            scope: environment.keycloak.scope,
            responseType: environment.keycloak.responseType,
            silentRenew: true,
            useRefreshToken: true,
            logLevel: environment.keycloak.logLevel,
            secureRoutes: [environment.graphql.uri],
            ignoreNonceAfterRefresh:
              environment.keycloak.ignoreNonceAfterRefresh,
          },
        })
      : [],
  ],
})
export class AppAuthModule {}
