export enum AssetCategory {
  REAL_ESTATE = "real-estate",
  ILLIQUID_ASSETS = "illiquid-assets",
  PENSION_INSURANCES = "pension-insurances",
  LOANS = "loans",
  LIQUID_ASSETS = "liquid-assets",
  CASH = "cash",
}

export type AssetSubcategory =
  | RealEstateSubcategory
  | IlliquidAssetsSubcategory
  | RetirementProvisionsSubcategory
  | LoansSubcategory
  | LiquidAssetsSubcategory
  | CashSubcategory;

export enum RealEstateSubcategory {
  SELF_USED = "SELF_USED",
  RENTED_OUT = "RENTED_OUT",
  RENTED_OUT_UNTIL_PENSION = "RENTED_OUT_UNTIL_PENSION",
}

export enum IlliquidAssetsSubcategory {
  ACTIVE_HOLDING = "ACTIVE_HOLDING",
  PASSIVE_HOLDING = "PASSIVE_HOLDING",
  VEHICLES = "VEHICLES",
  BUILDING_SAVINGS_CONTRACT = "BUILDING_SAVINGS_CONTRACT",
  OTHER = "OTHER",
}

export enum RetirementProvisionsSubcategory {
  PRIVATE = "PRIVATE",
  RIESTER = "RIESTER",
  BAV = "BAV",
  RUERUP = "RUERUP",
  RUERUP_WITH_GUARANTEE = "RUERUP_WITH_GUARANTEE",
}

export enum LoansSubcategory {
  REPAYMENT = "REPAYMENT",
  MORTGAGE = "MORTGAGE",
}

export enum LiquidAssetsSubcategory {
  INVESTMENTS_IN_CAPITAL_MARKET = "INVESTMENTS_IN_CAPITAL_MARKET",
}

export enum CashSubcategory {
  CASH = "CASH",
}

export enum IncomeTaxCategory {
  OUT_RENTING = "OUT_RENTING",
  BUSINESS_OPERATIONS = "BUSINESS_OPERATIONS",
}
