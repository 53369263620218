import {
  Directive,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from "@angular/core";

@Directive({
  selector: "[airMouseOver]",
})
export class MouseOverDirective {
  @Input() isOver = false;
  @Output() isOverChange: EventEmitter<boolean> = new EventEmitter();

  @HostListener("mouseover")
  onMouseOver(): void {
    if (!this.isOver) {
      this.isOverChange.emit(true);
      this.isOver = true;
    }
  }

  @HostListener("mouseout")
  onMouseOut(): void {
    if (this.isOver) {
      this.isOverChange.emit(false);
      this.isOver = false;
    }
  }
}
