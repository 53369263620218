import { FederalStates as FederalStatesGQL } from "@air-gmbh/data-access/graphql";
import { FederalStates } from "@air-gmbh/util/types";

export const LocationToGQL = {
  [FederalStates.UNKNOWN]: FederalStatesGQL.Unknown,
  [FederalStates.BADEN_WUERTTEMBERG]: FederalStatesGQL.BadenWuerttemberg,
  [FederalStates.BAVARIA]: FederalStatesGQL.Bavaria,
  [FederalStates.BERLIN]: FederalStatesGQL.Berlin,
  [FederalStates.BRANDENBURG]: FederalStatesGQL.Brandenburg,
  [FederalStates.BREMEN]: FederalStatesGQL.Bremen,
  [FederalStates.HAMBURG]: FederalStatesGQL.Hamburg,
  [FederalStates.HESSE]: FederalStatesGQL.Hesse,
  [FederalStates.LOWER_SAXONY]: FederalStatesGQL.LowerSaxony,
  [FederalStates.MECKLENBURG_WEST_POMERANIA]:
    FederalStatesGQL.MecklenburgWestPomerania,
  [FederalStates.NORTH_RHINE_WESTPHALIA]: FederalStatesGQL.NorthRhineWestphalia,
  [FederalStates.HESSE]: FederalStatesGQL.Hesse,
  [FederalStates.RHINELAND_PALATINATE]: FederalStatesGQL.RhinelandPalatinate,
  [FederalStates.SAARLAND]: FederalStatesGQL.Saarland,
  [FederalStates.SAXONY]: FederalStatesGQL.Saxony,
  [FederalStates.SAXONY_ANHALT]: FederalStatesGQL.SaxonyAnhalt,
  [FederalStates.SCHLESWIG_HOLSTEIN]: FederalStatesGQL.SchleswigHolstein,
  [FederalStates.THURINGIA]: FederalStatesGQL.Thuringia,
};
