import { IlliquidAssetTypes } from "@air-gmbh/data-access/graphql";
import { Asset, AssetCategory } from "@air-gmbh/util/types";
import { Injectable } from "@angular/core";
import { TypeMapper } from "../../common/mapper.interface";

export interface IlliquidAssetGQL {
  id: string;
  name: string;
  type: IlliquidAssetTypes;
  value: number;
  isInventory: boolean;
}

@Injectable()
export class IlliquidAssetMapper
  implements TypeMapper<IlliquidAssetGQL, Asset>
{
  toRawType(illiquidAsset: IlliquidAssetGQL): Asset {
    return {
      id: illiquidAsset.id,
      name: illiquidAsset.name,
      category: AssetCategory.ILLIQUID_ASSETS,
      subcategory: illiquidAsset.type,
      amount: illiquidAsset.value,
      isInventory: illiquidAsset.isInventory,
    };
  }
}
