import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "air-tooltip",
  templateUrl: "./tooltip.component.html",
})
export class TooltipComponent {
  @Input() visible = false;
  @Input() placement: "left" | "right" = "right";
  @Output() visibleChange = new EventEmitter<boolean>();

  private outsideClick = false;

  show(): void {
    this.visible = true;
    this.visibleChange.emit(true);
  }

  hide(): void {
    if (this.outsideClick) {
      this.outsideClick = false;
      this.visible = false;
      this.visibleChange.emit(false);
    } else {
      this.outsideClick = true;
    }
  }
}
