import { Vehicle } from "@air-gmbh/util/types";
import { Injectable } from "@angular/core";
import { TypeMapper } from "../common/mapper.interface";

export interface VehicleGQL {
  id: string;
  name: string;
  costs: number;
}

@Injectable()
export class VehicleMapper implements TypeMapper<VehicleGQL, Vehicle> {
  toRawType(graphQLType: VehicleGQL): Vehicle {
    return {
      id: graphQLType.id,
      name: graphQLType.name,
      costs: graphQLType.costs,
    };
  }
}
