import { AuthService, UserService } from "@air-gmbh/data-access/auth";
import { OptInService } from "@air-gmbh/data-access/data-protection";
import { HouseholdService } from "@air-gmbh/data-access/household";
import { RolesCheck, TourStatus } from "@air-gmbh/util/constants";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { combineLatest, merge, of, partition, Subscription } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { DropdownElement } from "../../shared/components/button-dropdown/dropdown-element";
import { Guid } from "../../shared/helpers/guid-helper";
import { ProfileService } from "../../shared/services/profile/profile-service";
import { TourNavigationService } from "../../shared/services/tour-navigation.service";
import { MenuDropdownItem } from "../menu-dropdown/menu-dropdown.type";

@Component({ template: "" })
export class UserAccountComponent implements OnInit, OnDestroy {
  username = "";
  items: MenuDropdownItem[] = [];

  // Delete once the AccountDropdownComponent can be removed
  elements: DropdownElement[] = [];
  open = false;

  get userExists(): boolean {
    return this.userId != null;
  }

  private householdId?: string;
  private userId?: string;
  private sub: Subscription = new Subscription();

  constructor(
    protected readonly router: Router,
    protected readonly authService: AuthService,
    protected readonly userService: UserService,
    protected readonly tourNavService: TourNavigationService,
    protected readonly profileService: ProfileService,
    protected readonly optInService: OptInService,
    protected readonly householdService: HouseholdService,
    protected readonly translate: TranslateService
  ) {}

  ngOnInit(): void {
    const [$client, $other] = partition(
      this.userService
        .getRoles()
        .pipe(
          switchMap((role) =>
            combineLatest([this.userService.getUserId(), of(role)])
          )
        ),
      ([_, role]) => role.isClient
    );

    this.sub.add(
      $client
        .pipe(
          switchMap(([airId]) => {
            return combineLatest([
              this.householdService
                .fetchIds(airId)
                .pipe(map((householdIds) => householdIds.client.current)),
            ]);
          })
        )
        .subscribe(([currentClientHouseholdId]) => {
          this.householdId = currentClientHouseholdId;
        })
    );

    this.sub.add(
      this.profileService.profileData.subscribe((profile) => {
        this.username = profile.contactEmailAddress;
      })
    );

    this.sub.add(
      merge(
        $client.pipe(
          switchMap(([airId, roles]) =>
            combineLatest([
              of(airId),
              of(roles),
              this.tourNavService.fetchTourStatus(airId),
              this.optInService.isOptInApproved(airId),
            ])
          )
        ),
        $other
      ).subscribe(([airId, roles, tourStatus, hasPrivacyInfoApproved]) => {
        this.userId = airId;
        this.items = [
          /**
           * client, companion, admin: My profile
           */
          {
            text: "user.profile",
            show: this.showProfile(roles, hasPrivacyInfoApproved, tourStatus),
            handler: this.goToProfile.bind(this),
          },
          /**
           * For multi-role user whose roles include client:
           * Show airboard-link iff tour is finished
           * -----
           * companion: Client overview
           * admin: Admin interface
           */
          {
            text: "airboard.ref",
            show:
              roles.isClient &&
              (roles.isCompanion || roles.isAdmin) &&
              tourStatus === TourStatus.Airboard,
            handler: this.goToAIRboard.bind(this),
          },
          {
            text: "client.overview",
            show: roles.isCompanion,
            handler: this.goToClientOverview.bind(this),
          },
          {
            text: "companion.overview",
            show: roles.isAdmin,
            handler: this.goToAdminInterface.bind(this),
          },
          /**
           *   client, companion, admin: Logout
           */
          {
            text: "action.logout",
            show: true,
            class: "text-secondary",
            handler: this.logout.bind(this),
          },
        ];

        this.elements = this.items.map((item) => ({
          title: item.text != null ? this.translate.instant(item.text) : "",
          show: item.show,
          textColor:
            item.text === "action.logout" ? "text-secondary" : "text-black",
          click: item.handler ?? ((): void => {}),
        }));
      })
    );
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  private goToProfile(): void {
    /**
     * householdId defined => client,
     * not defined => admin || companion
     * TODO: consider case in which user is both e.g. companion and client
     */
    if (this.householdId) {
      this.router.navigate([
        "airboard",
        this.householdId,
        "edit",
        "personal-information",
      ]);
    } else {
      this.router.navigate(["profile"]);
    }
    this.open = false;
  }

  private goToAIRboard(): void {
    if (this.householdId) {
      this.router.navigate(["airboard", this.householdId]);
    }
    this.open = false;
  }

  private goToClientOverview(): void {
    this.router.navigate(["companion/clients"]);
    this.open = false;
  }

  private goToAdminInterface(): void {
    this.router.navigate(["admin/companions"]);
    this.open = false;
  }

  private logout(): void {
    this.authService.logout();
  }

  private showProfile(
    roles: RolesCheck,
    hasClientPrivacyInfoApproved?: boolean,
    tourStatus?: TourStatus
  ): boolean {
    if (roles.isAdmin) {
      return this.userId != null ? !Guid.isEmpty(this.userId) : false;
    } else if (roles.isClient && hasClientPrivacyInfoApproved != null) {
      return hasClientPrivacyInfoApproved && tourStatus === TourStatus.Airboard;
    }

    // Show profile if the user has any role
    const hasRole = Object.values(roles).find((role) => role === true);
    return hasRole != null;
  }
}
