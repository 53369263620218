import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class PrintService {
  print(): void {
    window.print();
  }
}
