import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Output,
} from "@angular/core";

@Directive({
  selector: "[airOuterClick]",
})
export class OuterClickDirective {
  @Output() clickOutside: EventEmitter<void> = new EventEmitter();

  private self: HTMLElement;

  constructor(element: ElementRef) {
    this.self = element.nativeElement;
  }

  @HostListener("document:mouseup", ["$event"])
  onGlobalClick(event: MouseEvent): void {
    if (event.target) {
      if (this.isOutside(this.self, event.target as HTMLElement)) {
        this.clickOutside.emit();
      }
    }
  }

  private isOutside(container: HTMLElement, element: HTMLElement): boolean {
    return container !== element && !container.contains(element);
  }
}
