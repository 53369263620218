/**
 * From an array of key-value pairs
 * create an object having keys with respective values
 */
export function fromEntries<TKey extends string, TValue>(
  entries: Array<[TKey, TValue]>
): Partial<Record<TKey, TValue>> {
  const res: Partial<Record<TKey, TValue>> = {};
  entries.forEach(([key, value]) => {
    res[key] = value;
  });
  return res;
}
