import { UiButtonModule } from "@air-gmbh/ui/button";
import { UiChipModule } from "@air-gmbh/ui/chip";
import { UiIconModule } from "@air-gmbh/ui/icon";
import { UiInputsModule } from "@air-gmbh/ui/inputs";
import { UiToggleModule } from "@air-gmbh/ui/toggle";
import { UiTooltipModule } from "@air-gmbh/ui/tooltip";
import { PipesModule } from "@air-gmbh/util/pipes";
import { CommonModule, CurrencyPipe } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ContentLoaderModule } from "@ngneat/content-loader";
import { TranslateModule } from "@ngx-translate/core";
import { ButtonsModule } from "./components/buttons/buttons.module";
import { GroupModule } from "./components/group/group.module";
import { InputWrapperModule } from "./components/input-wrapper/input-wrapper.module";
import { LoadingModule } from "./components/loading/loading.module";
import { RefreshViewModule } from "./components/refresh-view/refresh-view.module";
import { SettingsDropdownModule } from "./components/settings-dropdown/settings-dropdown.module";
import { GraphQLService } from "./services/graphQL.service";

@NgModule({
  declarations: [],
  imports: [CommonModule, PipesModule],
  exports: [
    PipesModule,
    ButtonsModule,
    UiIconModule,
    FormsModule,
    GroupModule,
    ReactiveFormsModule,
    TranslateModule,
    InputWrapperModule,
    UiInputsModule,
    LoadingModule,
    UiToggleModule,
    UiButtonModule,
    UiChipModule,
    UiTooltipModule,
    SettingsDropdownModule,
    RefreshViewModule,
    ContentLoaderModule,
  ],
  providers: [GraphQLService, CurrencyPipe],
})
export class SharedModule {}
