import { UiIconModule } from "@air-gmbh/ui/icon";
import { UtilDirectivesModule } from "@air-gmbh/util/directives";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { QuestionMarkComponent } from "./question-mark/question-mark.component";
import { TooltipComponent } from "./tooltip/tooltip.component";

@NgModule({
  declarations: [TooltipComponent, QuestionMarkComponent],
  imports: [CommonModule, UiIconModule, UtilDirectivesModule],
  exports: [TooltipComponent, QuestionMarkComponent],
})
export class UiTooltipModule {}
