import { RecalculateService } from "@air-gmbh/data-access/recalculate";
import { MissingIdError } from "@air-gmbh/util/error";
import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "air-recalculate-button",
  templateUrl: "./recalculate-button.component.html",
})
export class RecalculateButtonComponent {
  @Input() householdId?: string;
  @Output() buttonClicked = new EventEmitter<void>();

  constructor(private readonly recalculateService: RecalculateService) {}

  recalculate(): void {
    if (this.householdId == null) {
      throw new MissingIdError(RecalculateButtonComponent, "householdId");
    }
    this.recalculateService.recalculate(this.householdId);
    this.buttonClicked.emit();
  }
}
