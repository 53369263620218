<app-button-dropdown
  *ngIf="householdId"
  buttonVariant="text"
  buttonColor="black"
  buttonOnlyIcon="screwdriver-wrench"
  [elements]="elements"
  [(open)]="open"
  [withPadding]="false"
  [showChevron]="false"
></app-button-dropdown>

<ng-template #inflation>
  <div class="flex items-center gap-2 px-2 py-3">
    <air-toggle
      [value]="inflationToggleState"
      [disableInternalClick]="true"
    ></air-toggle>
    <label class="body-lg grow cursor-pointer">
      {{ "airboard.inflation" | translate }}
    </label>
    <air-question-mark
      [hint]="'hint.inflation' | translate"
      tooltipPlacement="left"
    ></air-question-mark>
  </div>
</ng-template>

<ng-template #inventory>
  <div class="flex items-center gap-2 px-2 py-3">
    <air-toggle
      [value]="inventoryToggleState"
      [disableInternalClick]="true"
    ></air-toggle>
    <label class="body-lg grow cursor-pointer">
      {{ "airboard.inventory" | translate }}
    </label>
  </div>
</ng-template>

<ng-template #retirement>
  <div class="flex items-center gap-2 px-2 py-3">
    <air-toggle
      [value]="retirementToggleState"
      [disableInternalClick]="true"
    ></air-toggle>
    <label class="body-lg grow cursor-pointer">
      {{ "airboard.retirement" | translate }}
    </label>
    <air-question-mark
      [hint]="'hint.retirement' | translate"
      tooltipPlacement="left"
    ></air-question-mark>
  </div>
</ng-template>

<ng-template #recalculate>
  <div class="flex h-12 w-full items-center gap-2">
    <air-icon
      aria-hidden="true"
      class="w-10 text-center"
      faClasses="rotate-exclamation"
      size="lg"
    >
    </air-icon>
    <a class="items-center text-black no-underline hover:text-black">
      {{ "action.forceRecalculation" | translate }}
    </a>
  </div>
</ng-template>

<ng-template #consultancyDocumentation>
  <div class="flex h-12 w-full items-center gap-2">
    <air-icon
      aria-hidden="true"
      class="w-10 text-center"
      faClasses="print"
      size="lg"
    >
    </air-icon>
    <a class="items-center text-black no-underline hover:text-black"
      >{{ "action.consultancyDocumentation" | translate }}
    </a>
  </div>
</ng-template>
