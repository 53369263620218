import { LIFESTYLE_KEYS } from "@air-gmbh/util/lifestyle";
import { LifestyleCost, UpdateLifestyle } from "@air-gmbh/util/types";
import { Injectable } from "@angular/core";
import { TypeMapper } from "../common/mapper.interface";

@Injectable()
export class LifestyleCostsInputMapper
  implements TypeMapper<UpdateLifestyle, LifestyleCost>
{
  toGraphQLType(rawType: LifestyleCost): UpdateLifestyle {
    const part = Math.round(rawType.costs / LIFESTYLE_KEYS.length);
    return {
      id: rawType.lifestyleId,
      food: part,
      utilityCost: part,
      rent: part,
      clothing: part,
      education: part,
      media: part,
      vacation: part,
      mobility: part,
      miscellaneous: part,
      buffer: part,
    };
  }
}
