import {
  SettingsGQL,
  ToggleInflationGQL,
  UpdateRetirementCostCalculationGQL,
} from "@air-gmbh/data-access/graphql";
import { SettingsMapper } from "@air-gmbh/data-access/mappers";
import { AirError, ErrorUtil } from "@air-gmbh/util/error";
import { Settings } from "@air-gmbh/util/types";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";

@Injectable()
export class SettingsService {
  constructor(
    private readonly settingsGQL: SettingsGQL,
    private readonly toggleInflationGQL: ToggleInflationGQL,
    private readonly toggleRetirementGQL: UpdateRetirementCostCalculationGQL,
    private readonly settingsMapper: SettingsMapper
  ) {}

  getSettings(householdId: string): Observable<Settings> {
    return this.settingsGQL.fetch({ id: householdId }).pipe(
      map((res) => {
        if (res.data.householdById.household != null) {
          return this.settingsMapper.toRawType(
            res.data.householdById.household
          );
        }
        throw new AirError(SettingsService, "Empty household for getSettings");
      }),
      catchError((err) => {
        throw ErrorUtil.transformError(err);
      })
    );
  }

  toggleInflation(householdId: string, isWanted: boolean): Observable<boolean> {
    return this.toggleInflationGQL.mutate({ id: householdId, isWanted }).pipe(
      map((res) => {
        if (res.data != null) {
          return res.data.considerInflation.considerInflation;
        }
        throw new AirError(
          SettingsService,
          "Empty response for toggleInflation"
        );
      }),
      catchError((err) => {
        throw ErrorUtil.transformError(err);
      })
    );
  }

  toggleRetirement(
    householdId: string,
    isWanted: boolean
  ): Observable<boolean> {
    return this.toggleRetirementGQL
      .mutate({
        householdId: householdId,
        isWanted,
      })
      .pipe(
        map((res) => {
          if (res.data != null) {
            return res.data.includeRetirementCostForMinimumCostCalculation;
          }
          throw new AirError(
            SettingsService,
            "Empty response for toggleRetirement"
          );
        }),
        catchError((err) => {
          throw ErrorUtil.transformError(err);
        })
      );
  }
}
