import { LifestyleTypes as LifestyleTypesGraphQL } from "@air-gmbh/data-access/graphql";
import {
  Lifestyle,
  LifestyleTypes,
  MappedLifestyleCosts,
} from "@air-gmbh/util/types";
import { LifestyleGraphQL } from "./lifestyle.mapper";

type MappedHouseholdLifestyles = Record<LifestyleTypes, Lifestyle>;

export class LifestyleMapperHelper {
  static isMappedHouseholdLifestyle(
    x: Partial<MappedHouseholdLifestyles>
  ): x is MappedHouseholdLifestyles {
    return LifestyleMapperHelper.hasLifestyleTypesKeys(x);
  }

  static isMappedLifestyleCosts(
    x: Partial<MappedLifestyleCosts>
  ): x is MappedLifestyleCosts {
    return LifestyleMapperHelper.hasLifestyleTypesKeys(x);
  }

  private static hasLifestyleTypesKeys(
    x: Partial<Record<LifestyleTypes, any>>
  ): boolean {
    return (
      x[LifestyleTypes.CURRENT] != null &&
      x[LifestyleTypes.RETIREMENT] != null &&
      x[LifestyleTypes.MINIMUM] != null
    );
  }

  static getLifestyleType(typeGQL: LifestyleTypesGraphQL): LifestyleTypes {
    return {
      [LifestyleTypesGraphQL.Current]: LifestyleTypes.CURRENT,
      [LifestyleTypesGraphQL.Retirement]: LifestyleTypes.RETIREMENT,
      [LifestyleTypesGraphQL.Minimum]: LifestyleTypes.MINIMUM,
    }[typeGQL];
  }
}

export class LifestyleMapperTestHelper {
  static generateLifestyleGQL(type: LifestyleTypesGraphQL): LifestyleGraphQL {
    return {
      id: `${type}-id`,
      food: 123,
      utilityCost: 456,
      rent: 789,
      clothing: 147,
      education: 258,
      media: 369,
      vacation: 741,
      mobility: 852,
      miscellaneous: 963,
      buffer: 100,
      lifestyleType: type,
    };
  }

  static generateLifestyle(type: LifestyleTypes): Lifestyle {
    return {
      id: `${type}-id`,
      food: 123,
      utilityCost: 456,
      rent: 789,
      clothing: 147,
      education: 258,
      media: 369,
      vacation: 741,
      mobility: 852,
      miscellaneous: 963,
      buffer: 100,
      type: type,
      total: 4798,
    };
  }

  static generateLifestylesGQL(): LifestyleGraphQL[] {
    return Object.values(LifestyleTypesGraphQL).map((lifestyleType) =>
      LifestyleMapperTestHelper.generateLifestyleGQL(lifestyleType)
    );
  }
}
