import { Lifestyle } from "@air-gmbh/util/types";
import { IconName } from "@fortawesome/fontawesome-svg-core";

export interface LifestyleCategory {
  name: keyof Omit<Lifestyle, "id" | "__typename" | "total" | "type">;
  icon: IconName;
}

export const LIFESTYLE_CATEGORIES: LifestyleCategory[] = [
  {
    name: "food",
    icon: "shopping-cart",
  },
  {
    name: "rent",
    icon: "building",
  },
  {
    name: "utilityCost",
    icon: "home",
  },
  {
    name: "clothing",
    icon: "tshirt",
  },
  {
    name: "education",
    icon: "graduation-cap",
  },
  {
    name: "media",
    icon: "mobile",
  },
  {
    name: "vacation",
    icon: "umbrella-beach",
  },
  {
    name: "mobility",
    icon: "car",
  },
  {
    name: "miscellaneous",
    icon: "puzzle-piece",
  },
  {
    name: "buffer",
    icon: "layer-group",
  },
];
