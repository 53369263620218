import { ParamsService } from "@air-gmbh/util/general";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { switchMap } from "rxjs/operators";
import { AirboardConfigurationService } from "./airboard-configuration.service";

@Injectable()
export class InventoryService {
  constructor(
    private readonly paramsService: ParamsService,
    private readonly airboardConfig: AirboardConfigurationService
  ) {}

  isInventory(): Observable<boolean> {
    return this.paramsService.hasParam("clientId").pipe(
      switchMap((value) => {
        if (!value) {
          return this.airboardConfig.isComparing$;
        }
        return of(true);
      })
    );
  }
}
