import {
  EmploymentCategories as EmploymentCategoriesGQL,
  FederalStates as FederalStatesGQL,
} from "@air-gmbh/data-access/graphql";
import {
  Employment,
  EmploymentCategories,
  FederalStates,
} from "@air-gmbh/util/types";
import { Injectable } from "@angular/core";
import { Nullable } from "ts-toolbelt/out/Union/Nullable";
import { TypeMapper } from "../../common/mapper.interface";
import { GrossIncomeGL, GrossIncomeMapper } from "./../gross-income.mapper";

export interface EmploymentGraphQL {
  id: string;
  employmentCategory: EmploymentCategoriesGQL;
  federalState?: FederalStatesGQL;
  isPhysicalWork?: boolean;
  hasPensionPaymentObligation?: boolean;
  grossIncome?: Nullable<GrossIncomeGL>;
}

@Injectable()
export class EmploymentMapper
  implements TypeMapper<EmploymentGraphQL, Employment>
{
  constructor(private readonly grossIncomeMapper: GrossIncomeMapper) {}

  toRawType(graphQLType: EmploymentGraphQL): Employment {
    const result: Employment = {
      id: graphQLType.id,
      federalState:
        graphQLType.federalState != null
          ? FederalStates[graphQLType.federalState]
          : FederalStates.UNKNOWN,
      employmentCategory: EmploymentCategories[graphQLType.employmentCategory],
      isPhysicalWork: graphQLType.isPhysicalWork ?? false,
      hasPensionPaymentObligation:
        graphQLType.hasPensionPaymentObligation ?? false,
    };
    if (graphQLType.grossIncome != null) {
      result.grossIncome = this.grossIncomeMapper.toRawType(
        graphQLType.grossIncome
      );
    }
    return result;
  }
}
