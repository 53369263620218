export enum SpinnerSize {
  LARGE = "large",
  MEDIUM = "medium",
  SMALL = "small",
}

export enum SpinnerColor {
  PRIMARY = "primary",
  SECONDARY = "secondary",
  BLACK = "black",
  WHITE = "white",
  ERROR = "error-700",
  SUCCESS = "success-700",
  INFO = "info-700",
  WARNING = "warning-700",
}
