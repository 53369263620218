import { GrossIncomeTypes } from "@air-gmbh/data-access/graphql";
import { AirError } from "@air-gmbh/util/error";
import {
  FinancialStructureSummary,
  LoadingAmount,
  RiskTolerances,
} from "@air-gmbh/util/types";
import { Injectable } from "@angular/core";
import { isNotNullish } from "@tool-belt/type-predicates";
import { Nullable } from "ts-toolbelt/out/Union/Nullable";
import { TypeMapper } from "../common/mapper.interface";
import {
  IncomeSummaryMapper,
  MemberIncomeSummaryGQL,
} from "./income-summary.mapper";

export interface FinancialStructureHouseholdGQL {
  household?: Nullable<FinancialStructureGQL>;
}
export interface AssetGQL {
  id: string;
  propertiesRented: number;
  propertiesRentedForPension: number;
  holdings: number;
  investmentsCapitalMarket: number;
  illiquidAssets: number;
  cash: number;
  retirementProvision: number;
  loans: number;
  propertiesUsingV2: {
    amount: number;
  };
}
interface GrossIncomeGQL {
  id: string;
  monthlyAmount: number;
  grossIncomeType: GrossIncomeTypes;
}
interface MemberIncomeSummaryGQLV2 extends MemberIncomeSummaryGQL {
  grossIncomesV2: Nullable<GrossIncomeGQL>[];
}

export interface FinancialStructureGQL {
  id: string;
  riskTolerance: RiskTolerances;
  assetV2?: Nullable<AssetGQL>;
  riskBufferV2: {
    amount: number;
  };
  financialStructureBalanceV2?: Nullable<{
    netIncomeAmount: LoadingAmount;
  }>;
  members: Nullable<MemberIncomeSummaryGQLV2>[];
}

@Injectable()
export class FinancialStructureSummaryMapper
  implements
    TypeMapper<FinancialStructureHouseholdGQL, FinancialStructureSummary>
{
  constructor(private readonly incomeSummaryMapper: IncomeSummaryMapper) {}

  toRawType(
    graphQLType: FinancialStructureHouseholdGQL
  ): FinancialStructureSummary {
    if (graphQLType.household != null) {
      const { household } = graphQLType;

      const members = household.members.filter(isNotNullish);
      const incomeSummary = this.incomeSummaryMapper.toRawType(members);

      let netIncome = {
        amount: 0,
        isRecalculating: false,
      };
      if (household.financialStructureBalanceV2 != null) {
        netIncome = {
          ...household.financialStructureBalanceV2?.netIncomeAmount,
        };
      }

      return {
        assets:
          household.assetV2 != null ? getAssetsTotal(household.assetV2) : 0,
        netIncome,
        grossIncome:
          incomeSummary.activeIncome +
          incomeSummary.passiveIncome +
          incomeSummary.statutoryPension,
        riskTolerance: household.riskTolerance,
        riskBuffer: household.riskBufferV2.amount,
      };
    }

    throw new AirError(
      FinancialStructureSummaryMapper,
      "Financial structure summary mapping failed"
    );
  }
}

/**
 * Gets the total sum of the assets
 * @param assets Asset object
 * @returns total value
 */
function getAssetsTotal(assets: AssetGQL): number {
  return (
    assets.propertiesUsingV2.amount +
    assets.propertiesRented +
    assets.propertiesRentedForPension +
    assets.holdings +
    assets.investmentsCapitalMarket +
    assets.illiquidAssets +
    assets.cash +
    assets.retirementProvision -
    assets.loans
  );
}
