import { Component, Input } from "@angular/core";

export type LogoVariant = "default" | "up-to-you" | "airboard";

@Component({
  selector: "air-logo",
  templateUrl: "./logo.component.html",
})
export class LogoComponent {
  @Input() variant: LogoVariant = "default";
}
