import {
  ClientOptInGQL,
  DataPrivacyIdGQL,
  UpdatePrivacyInfoApprovalGQL,
  UpdatePrivacyInfoApprovalMutation,
} from "@air-gmbh/data-access/graphql";
import { ErrorUtil, MissingIdError } from "@air-gmbh/util/error";
import { Injectable } from "@angular/core";
import { FetchResult } from "@apollo/client/core";
import { Observable, throwError } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";

export interface CreatedQQClientDetails {
  accessToken: string;
  clientId: string;
  quickQuizStep: string | null;
  clientHouseholdId: string;
  companionHouseholdId?: string;
}

@Injectable({
  providedIn: "root",
})
export class OptInService {
  constructor(
    private readonly clientOptInGQL: ClientOptInGQL,
    private readonly updatePrivacyInfoApprovalGQL: UpdatePrivacyInfoApprovalGQL,
    private readonly dataPrivacyIdGQL: DataPrivacyIdGQL
  ) {}

  isOptInApproved(clientId: string): Observable<boolean> {
    return this.clientOptInGQL
      .fetch({ clientId })
      .pipe(map((res) => res.data.client.hasApprovedPrivacyInfo));
  }

  updateOptIn(
    clientId: string,
    hasApproved: boolean
  ): Observable<FetchResult<UpdatePrivacyInfoApprovalMutation>> {
    return this.fetchPrivacyPolicyId().pipe(
      switchMap((privacyInfoId) => {
        return this.updatePrivacyInfoApprovalGQL.mutate({
          input: {
            clientId: clientId,
            privacyInfoId: privacyInfoId,
            hasApproved,
          },
        });
      }),
      catchError((error) => throwError(ErrorUtil.transformError(error)))
    );
  }

  private fetchPrivacyPolicyId(): Observable<string> {
    return this.dataPrivacyIdGQL.fetch().pipe(
      map((res) => {
        const privacyInfoId =
          res.data.organization.organization.currentPrivacyInfo.id;
        if (privacyInfoId == null) {
          throw new MissingIdError(OptInService, "privacyInfoId");
        }
        return privacyInfoId;
      })
    );
  }
}
