import { FlagsService } from "@air-gmbh/util/environment";
import { ErrorCategory, ErrorInfo, ErrorUtil } from "@air-gmbh/util/error";
import { Injectable } from "@angular/core";
import { first } from "rxjs/operators";
import { AlertService } from "./alert.service";

/**
 * The ErrorService is meant to be used in the components for handling errors
 * that the services from the data-access library throw.
 */
@Injectable({ providedIn: "root" })
export class ErrorService {
  private isStable = true;

  constructor(
    protected readonly alertService: AlertService,
    protected readonly flagsService: FlagsService
  ) {
    this.flagsService
      .hasFeature("stable")
      .pipe(first())
      .subscribe((flag) => {
        this.isStable = flag;
      });
  }

  handleError(error: ErrorInfo | unknown): void {
    if (ErrorUtil.hasKnownCategory(error)) {
      this.handleIdentifiedError(error);
    } else if (!this.isStable) {
      throw error;
    }
  }

  private handleIdentifiedError(error: ErrorInfo): void {
    const handler = {
      [ErrorCategory.GRAPHQL]: (): void => {
        this.alertService.showError(error.message);
        if (!this.isStable) {
          throw error.trace;
        }
      },
      [ErrorCategory.FRONTEND]: (): void => {
        if (!this.isStable) {
          throw error.trace;
        }
      },
    }[error.category];

    if (handler != null) {
      handler();
    }
  }
}
