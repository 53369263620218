<div [ngClass]="class" airOuterClick (clickOutside)="hideDateInput()">
  <div class="relative">
    <input
      class="w-full cursor-default py-3 pl-4 pr-10 uppercase leading-none print:py-2"
      [attr.id]="id"
      type="date"
      [ngClass]="{
        error: (errors || hasExternalError) && showError && !value.disabled
      }"
      (keyup.escape)="hideDateInput()"
      [placeholder]="placeholder"
      [formControl]="value"
      (blur)="onTouched()"
    />

    <div class="absolute top-0 right-0 px-3 py-2 print:py-[0.375rem]">
      <air-icon
        [ngClass]="{
          'text-grey': value.status === 'DISABLED',
          'cursor-pointer': value.status !== 'DISABLED'
        }"
        faClasses="calendar"
        size="lg"
        (click)="toggleDateInput()"
      ></air-icon>
    </div>

    <!-- Pick a date -->
    <div
      [style.visibility]="showDateInput ? 'visible' : 'hidden'"
      class="dp-selector"
    >
      <div class="flex items-center justify-between">
        <div
          class="flex cursor-pointer items-center gap-1"
          (click)="seeMonthInput()"
        >
          <span class="text-lg font-semibold">{{
            MONTH_FULL_NAMES[month]! | translate
          }}</span>
          <span id="dp-year" class="text-lg text-grey-dark">{{ year }}</span>
          <air-icon
            faClasses="angle-down"
            size="sm"
            class="text-black"
          ></air-icon>
        </div>
        <div class="flex gap-1">
          <button
            type="button"
            class="dp-button dp-nav"
            (click)="previousMonth()"
          >
            <air-icon faClasses="angle-left" size="lg"></air-icon>
          </button>
          <button type="button" class="dp-button dp-nav" (click)="nextMonth()">
            <air-icon faClasses="angle-right" size="lg"></air-icon>
          </button>
        </div>
      </div>

      <div class="-mx-1 flex flex-wrap">
        <div
          *ngFor="let day of DAYS; let index = index"
          class="dp-slot px-1 text-center"
        >
          <span class="text-sm font-normal text-black">
            {{ day | translate }}
          </span>
        </div>
      </div>

      <div class="-mx-1 flex flex-wrap">
        <div
          *ngFor="let blankday of blankdays"
          class="dp-slot border border-transparent p-1 text-center text-sm"
        ></div>
        <div *ngFor="let day of numberOfDays" class="dp-slot mb-1 px-1">
          <div
            (click)="selectDay(day)"
            class="cursor-pointer rounded-full text-center text-sm leading-loose transition duration-100 ease-in-out"
            [ngClass]="{
              'bg-secondary text-white': isSelectedDay(day),
              'text-black hover:bg-grey-light': !isSelectedDay(day)
            }"
          >
            {{ day }}
          </div>
        </div>
      </div>
    </div>
    <!-- END Pick a date -->

    <!-- Pick a month -->
    <div
      [style.visibility]="showMonthInput ? 'visible' : 'hidden'"
      class="dp-selector"
    >
      <div class="flex items-center justify-between">
        <div class="flex gap-1">
          <span class="cursor-default text-lg font-semibold">{{ year }}</span>
        </div>
        <div class="flex gap-1">
          <button
            type="button"
            class="dp-button dp-nav"
            (click)="previousYear()"
          >
            <air-icon faClasses="angle-left" size="lg"></air-icon>
          </button>
          <button type="button" class="dp-button dp-nav" (click)="nextYear()">
            <air-icon faClasses="angle-right" size="lg"></air-icon>
          </button>
        </div>
      </div>

      <div class="-mx-1 flex flex-wrap">
        <div
          *ngFor="let month of MONTH_SHORT_NAMES; let monthIndex = index"
          class="mb-1 w-1/3 cursor-pointer rounded-md p-1 text-center hover:bg-grey-light"
          (click)="selectMonth(monthIndex)"
          [ngClass]="{
            'bg-secondary text-white': isSelectedMonth(monthIndex),
            'text-black hover:bg-grey-light': !isSelectedMonth(monthIndex)
          }"
        >
          {{ month | translate }}
        </div>
      </div>
    </div>
    <!-- END Pick a month -->
  </div>
</div>
