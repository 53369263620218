import {
  AssetCategory,
  AssetSubcategory,
  CashSubcategory,
  IlliquidAssetsSubcategory,
  LiquidAssetsSubcategory,
  LoansSubcategory,
  RealEstateSubcategory,
  RetirementProvisionsSubcategory,
} from "@air-gmbh/util/types";

export const ASSET_SUBCATEGORIES: Record<
  AssetCategory,
  { subcategory: AssetSubcategory; label: string }[]
> = {
  [AssetCategory.CASH]: [
    {
      subcategory: CashSubcategory.CASH,
      label: "assets.subcategories.CASH",
    },
  ],
  [AssetCategory.ILLIQUID_ASSETS]: [
    {
      subcategory: IlliquidAssetsSubcategory.ACTIVE_HOLDING,
      label: "assets.subcategories.ACTIVE_HOLDING",
    },
    {
      subcategory: IlliquidAssetsSubcategory.PASSIVE_HOLDING,
      label: "assets.subcategories.PASSIVE_HOLDING",
    },
    {
      subcategory: IlliquidAssetsSubcategory.VEHICLES,
      label: "assets.subcategories.VEHICLE",
    },
    {
      subcategory: IlliquidAssetsSubcategory.BUILDING_SAVINGS_CONTRACT,
      label: "assets.subcategories.BUILDING_SAVINGS_CONTRACT",
    },
    {
      subcategory: IlliquidAssetsSubcategory.OTHER,
      label: "assets.subcategories.OTHER",
    },
  ],
  [AssetCategory.LIQUID_ASSETS]: [
    {
      subcategory: LiquidAssetsSubcategory.INVESTMENTS_IN_CAPITAL_MARKET,
      label: "assets.subcategories.INVESTMENTS_IN_CAPITAL_MARKET",
    },
  ],
  [AssetCategory.LOANS]: [
    {
      subcategory: LoansSubcategory.REPAYMENT,
      label: "assets.subcategories.REPAYMENT",
    },
    {
      subcategory: LoansSubcategory.MORTGAGE,
      label: "assets.subcategories.MORTGAGE",
    },
  ],
  [AssetCategory.REAL_ESTATE]: [
    {
      subcategory: RealEstateSubcategory.SELF_USED,
      label: "assets.subcategories.OWN_USE",
    },
    {
      subcategory: RealEstateSubcategory.RENTED_OUT,
      label: "assets.subcategories.RENTED",
    },
    {
      subcategory: RealEstateSubcategory.RENTED_OUT_UNTIL_PENSION,
      label: "assets.subcategories.RENTED_FOR_OWN_USE_IN_RETIREMENT",
    },
  ],
  [AssetCategory.PENSION_INSURANCES]: [
    {
      subcategory: RetirementProvisionsSubcategory.PRIVATE,
      label: "assets.subcategories.PRIVATE",
    },
    {
      subcategory: RetirementProvisionsSubcategory.RIESTER,
      label: "assets.subcategories.RIESTER",
    },
    {
      subcategory: RetirementProvisionsSubcategory.BAV,
      label: "assets.subcategories.BAV",
    },
    {
      subcategory: RetirementProvisionsSubcategory.RUERUP,
      label: "assets.subcategories.RUERUP",
    },
    {
      subcategory: RetirementProvisionsSubcategory.RUERUP_WITH_GUARANTEE,
      label: "assets.subcategories.RUERUP_WITH_GUARANTEE",
    },
  ],
};
