import { Component, Input } from "@angular/core";

/**
 * A question-mark icon that opens a tooltip when clicked.
 */
@Component({
  selector: "air-question-mark",
  templateUrl: "./question-mark.component.html",
})
export class QuestionMarkComponent {
  @Input() hint = "";
  @Input() tooltipPlacement: "left" | "right" = "right";
  @Input() tooltipWidth: "w-96" | "w-64" = "w-96";
  @Input() hideInSmallScreen = true;

  showTooltip = false;
}
