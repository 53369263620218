import { Component } from "@angular/core";

import {
  AirboardConfigurationService,
  SettingsService,
} from "@air-gmbh/data-access/configuration";
import { RecalculateService } from "@air-gmbh/data-access/recalculate";
import { ParamsService } from "@air-gmbh/util/general";
import { ToolsComponent } from "../../../user-menu/tools-dropdown/tools.component";

@Component({
  selector: "app-settings-dropdown",
  templateUrl: "./settings-dropdown.component.html",
  styleUrls: ["settings-dropdown.component.css"],
})
export class SettingsDropdownComponent extends ToolsComponent {
  constructor(
    protected override readonly paramsService: ParamsService,
    protected override readonly airboardConfiguration: AirboardConfigurationService,
    protected override readonly recalculateService: RecalculateService,
    protected override readonly settingsService: SettingsService
  ) {
    super(
      paramsService,
      airboardConfiguration,
      recalculateService,
      settingsService
    );
  }
}
