export enum ErrorCategory {
  GRAPHQL = "GRAPHQL",
  FRONTEND = "FRONTEND",
}

// This can serve as an interface to implement later
export interface ErrorInfo {
  category: ErrorCategory;
  message: string;
  trace?: string;
}
