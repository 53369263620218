type NonNullishPredicate<T> = (
  input: T | null | undefined
) => input is NonNullable<T>;

export function throwOnNullish<T>(error: Error): NonNullishPredicate<T> {
  return (input: T | null | undefined): input is NonNullable<T> => {
    if (input == null) {
      throw error;
    }
    return true;
  };
}
