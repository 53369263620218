import {
  CompanionDetailsFragment,
  UpdateCompanionGQL,
  UpdateCompanionMutation,
} from "@air-gmbh/data-access/graphql";
import { MutationResult } from "apollo-angular";
import { Observable, of } from "rxjs";
import { filter, map, tap } from "rxjs/operators";
import { formattedFullName } from "../../helpers/format-helpers";
import { IProfileService, ProfileData } from "./i-profile-service";

export class CompanionProfileService implements IProfileService {
  get profileData(): Observable<ProfileData> {
    return of(this.companionDetails.contactProfile);
  }

  get name(): Observable<string> {
    return of(formattedFullName(this.companionDetails.profile));
  }

  constructor(
    private companionDetails: CompanionDetailsFragment,
    private readonly updateCompanionGQL: UpdateCompanionGQL
  ) {}

  updateEmail(email: string): Observable<string> {
    if (email === this.companionDetails.contactProfile.contactEmailAddress) {
      return of(email);
    } else {
      return this.updateProfile(this.companionDetails.id, {
        contactEmailAddress: email,
        phoneNumber: this.companionDetails.contactProfile.phoneNumber,
      }).pipe(map((res) => res.contactEmailAddress));
    }
  }

  updatePhoneNumber(
    phoneNumber: string
  ): Observable<string | null | undefined> {
    if (phoneNumber === this.companionDetails.contactProfile.phoneNumber) {
      return of(phoneNumber);
    } else {
      return this.updateProfile(this.companionDetails.id, {
        contactEmailAddress:
          this.companionDetails.contactProfile.contactEmailAddress,
        phoneNumber,
      }).pipe(map((res) => res.phoneNumber));
    }
  }

  private updateProfile(
    userId: string,
    profile: ProfileData
  ): Observable<ProfileData> {
    return this.updateCompanionGQL
      .mutate({
        companion: {
          id: userId,
          emailAddress: profile.contactEmailAddress,
          phoneNumber: profile.phoneNumber || null,
        },
      })
      .pipe(
        filter(
          (
            res
          ): res is MutationResult<UpdateCompanionMutation> & {
            data: UpdateCompanionMutation;
          } => res.data != null
        ),
        tap((res) => {
          this.companionDetails = res.data.updateCompanion!;
        }),
        map((res) => {
          return res.data.updateCompanion.contactProfile;
        })
      );
  }
}
