import { AllowanceBeneficiaries } from "@air-gmbh/data-access/graphql";
import { CalendarTranslationPaths } from "@air-gmbh/ui/inputs";
import { FinancialStructureSection } from "@air-gmbh/util/financial-structure";
import { RiskTolerances } from "@air-gmbh/util/types";

export const allowanceBeneficiariesMapper: Record<
  AllowanceBeneficiaries,
  string
> = {
  [AllowanceBeneficiaries.Unknown]: "general.unknown",
  [AllowanceBeneficiaries.ContactPerson]:
    "tour.personalInformation.contactMember",
  [AllowanceBeneficiaries.Partner]: "tour.personalInformation.partner.title",
  [AllowanceBeneficiaries.Couple]: "tour.personalInformation.household.couple",
  [AllowanceBeneficiaries.None]: "tour.personalInformation.household.external",
};

const FS_RISK = `tour.financialStructure.${FinancialStructureSection.RISK}`;

export const riskToleranceTranslations: Record<RiskTolerances, string> = {
  [RiskTolerances.Unknown]: "general.unknown",
  [RiskTolerances.VeryConservative]: `${FS_RISK}.VERY_CONSERVATIVE.title`,
  [RiskTolerances.Conservative]: `${FS_RISK}.CONSERVATIVE.title`,
  [RiskTolerances.Balanced]: `${FS_RISK}.BALANCED.title`,
  [RiskTolerances.GrowthOriented]: `${FS_RISK}.GROWTH_ORIENTED.title`,
  [RiskTolerances.RevenueOriented]: `${FS_RISK}.REVENUE_ORIENTED.title`,
};

function generateCalendarTranslationPaths(): CalendarTranslationPaths {
  return {
    month: Array.from({ length: 12 }, (_, i) => `general.month.${i + 1}.full`),
    monthShort: Array.from(
      { length: 12 },
      (_, i) => `general.month.${i + 1}.short`
    ),
    weekDay: Array.from({ length: 7 }, (_, i) => `general.day.${i + 1}`),
  };
}

export const APP_CALENDAR_TRANSLATION_PATHS =
  generateCalendarTranslationPaths();
