import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ToggleState } from "./toggle.type";

@Component({
  selector: "air-toggle",
  templateUrl: "./toggle.component.html",
  styleUrls: ["./toggle.component.css"],
})
export class ToggleComponent {
  toggleState = ToggleState;
  @Input() id?: string;
  @Input() value: ToggleState = ToggleState.Disabled;
  @Input() disabled = false;
  @Input() disableInternalClick = false;
  @Output() valueChange = new EventEmitter<ToggleState>();

  updateValue(): void {
    if (!this.disableInternalClick) {
      switch (this.value) {
        case ToggleState.Neutral:
          this.value = ToggleState.Active;
          break;
        case ToggleState.Active:
          this.value = ToggleState.Disabled;
          break;
        case ToggleState.Disabled:
          this.value = ToggleState.Active;
          break;
      }
      this.valueChange.emit(this.value);
    }
  }
}
