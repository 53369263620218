import { UpdateEmploymentInput as UpdateEmploymentInputGQL } from "@air-gmbh/data-access/graphql";
import { UpdateEmploymentInput } from "@air-gmbh/util/types";
import { Injectable } from "@angular/core";
import { TypeMapper } from "../../common/mapper.interface";
import { EmploymentCategoriesToGQL } from "./categories";
import { LocationToGQL } from "./location";

@Injectable()
export class UpdateEmploymentInputMapper
  implements TypeMapper<UpdateEmploymentInputGQL, UpdateEmploymentInput>
{
  toGraphQLType(rawType: UpdateEmploymentInput): UpdateEmploymentInputGQL {
    return {
      employmentId: rawType.employmentId,
      employmentCategory:
        rawType.employmentCategory != null
          ? EmploymentCategoriesToGQL[rawType.employmentCategory]
          : null,
      employmentFederalState:
        rawType.federalState != null
          ? LocationToGQL[rawType.federalState]
          : null,
      employmentIsPhysicalWork: rawType.isPhysicalWork,
      hasPensionObligation: rawType.hasPensionPaymentObligation,
    };
  }
}
