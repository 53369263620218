import {
  ChildMapper,
  EmploymentMapper,
  GrossIncomeMapper,
  GrossPensionMapper,
  MemberMapper,
  MemberRetirementMapper,
  PersonalInformationMapper,
  PetMapper,
  ProfileMapper,
  VehicleMapper,
} from "@air-gmbh/data-access/mappers";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { PersonalInformationService } from "./personal-information.service";

@NgModule({
  imports: [CommonModule],
  providers: [
    PersonalInformationService,
    PersonalInformationMapper,
    ChildMapper,
    PetMapper,
    VehicleMapper,
    GrossPensionMapper,
    GrossIncomeMapper,
    MemberRetirementMapper,
    EmploymentMapper,
    MemberMapper,
    ProfileMapper,
  ],
})
export class DataAccessPersonalInformationModule {}
