import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MouseOverDirective } from "./mouse-over/mouse-over.directive";
import { OuterClickDirective } from "./outer-click/outer-click.directive";

@NgModule({
  declarations: [OuterClickDirective, MouseOverDirective],
  imports: [CommonModule],
  exports: [OuterClickDirective, MouseOverDirective],
})
export class UtilDirectivesModule {}
