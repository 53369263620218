import { Alert } from "@air-gmbh/ui/alert";
import { ENVIRONMENT_CONFIG, IEnvironment } from "@air-gmbh/util/environment";
import {
  AfterViewInit,
  Component,
  HostListener,
  Inject,
  OnInit,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { BreadcrumbService } from "xng-breadcrumb";
import { AlertService } from "./shared/services/alert.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
})
export class AppComponent implements OnInit, AfterViewInit {
  alert: Alert = new Alert();

  constructor(
    @Inject(ENVIRONMENT_CONFIG) private readonly environment: IEnvironment,
    private readonly translate: TranslateService,
    private readonly alertService: AlertService,
    private readonly breadcrumbService: BreadcrumbService
  ) {
    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang(this.environment.locale.fallbackLanguage);

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    this.translate.use(this.environment.locale.language);
  }

  ngAfterViewInit(): void {
    this.resizeContainer();
  }

  @HostListener("window:resize", ["$event"])
  _onResize(): void {
    this.resizeContainer();
  }

  ngOnInit(): void {
    this.alertService
      .getState()
      .subscribe((value: Alert) => (this.alert = value));
  }

  closeAlert(): void {
    this.alertService.hide();
  }

  private resizeContainer(): void {
    // Get the viewport height and multiple it by 1% to get a value for a vh unit
    const vh = window.innerHeight * 0.01;
    // Set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }
}
