import { PetTypes as PetTypesGQL } from "@air-gmbh/data-access/graphql";
import { Pet, PetTypes } from "@air-gmbh/util/types";
import { Injectable } from "@angular/core";
import { TypeMapper } from "../common/mapper.interface";

export interface PetGQL {
  petType: PetTypesGQL;
  quantity: number;
}

@Injectable()
export class PetMapper implements TypeMapper<PetGQL, Pet> {
  toRawType(graphQLType: PetGQL): Pet {
    return {
      type: PetTypes[graphQLType.petType],
      quantity: graphQLType.quantity,
    };
  }
}
