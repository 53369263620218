<ng-container *ngIf="!isLoading; else loading">
  <div
    class="air-chip"
    [ngClass]="{
      'bg-secondary text-white-pure':
        color === 'secondary' && variant === 'dark',
      'bg-secondary-lighter text-secondary-darker':
        color === 'secondary' && variant === 'light',
      'bg-primary-light text-primary-dark':
        color === 'primary' && variant === 'light',
      'bg-primary text-white': color === 'primary' && variant === 'dark',
      'bg-info-50 text-info-700': color === 'info' && variant === 'light',
      'bg-info-700 text-white': color === 'info' && variant === 'dark',
      'bg-success-700 text-white': color === 'success' && variant === 'dark',
      'bg-success-50 text-success-700':
        color === 'success' && variant === 'light',
      'bg-error-700 text-white': color === 'error' && variant === 'dark',
      'bg-error-50 text-error-700': color === 'error' && variant === 'light'
    }"
  >
    <ng-content></ng-content>
  </div>
</ng-container>

<ng-template #loading>
  <div class="h-7 w-24 overflow-hidden rounded-full">
    <content-loader
      [speed]="3"
      viewBox="0 0 100 30"
      [backgroundColor]="loadingBackground"
      [foregroundColor]="loadingForeground"
    >
      <svg:rect x="0" y="0" rx="16" ry="16" width="100%" height="100%" />
    </content-loader>
  </div>
</ng-template>
