import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "cleanPhoneNumber",
})
export class CleanPhoneNumberPipe implements PipeTransform {
  transform(cellphoneNumber: string): string;
  transform(cellphoneNumber: null | undefined): null;
  transform(cellphoneNumber: string | null | undefined): string | null;
  transform(cellphoneNumber: string | null | undefined): string | null {
    return cellphoneNumber != null && this.hasAtLeastOneDigit(cellphoneNumber)
      ? this.keepOnlyAllowedCharacters(cellphoneNumber)
      : null;
  }

  private hasAtLeastOneDigit(cellphoneNumber: string): boolean {
    return /\d/.test(cellphoneNumber);
  }

  private keepOnlyAllowedCharacters(cellphoneNumber: string): string {
    return cellphoneNumber.replace(/[^ +()-\d]/g, "");
  }
}
