import { MaritalStatus as MaritalStatusGQL } from "@air-gmbh/data-access/graphql";
import {
  Child,
  MaritalStatus,
  PersonalInformation,
  Pet,
  Vehicle,
} from "@air-gmbh/util/types";
import { Injectable } from "@angular/core";
import { isNotNullish } from "@tool-belt/type-predicates";
import { Nullable } from "ts-toolbelt/out/Union/Nullable";
import { TypeMapper } from "../common/mapper.interface";
import { MemberGraphQL, MemberMapper } from "../member/member.mapper";
import { ChildGQL, ChildMapper } from "./child.mapper";
import { PetGQL, PetMapper } from "./pet.mapper";
import { VehicleGQL, VehicleMapper } from "./vehicle.mapper";

export interface HouseholdPersonalInformationGraphQL {
  id: string;
  maritalStatus: MaritalStatusGQL;
  marriageDate?: Nullable<string>;
  pets: Nullable<PetGQL>[];
  members: Nullable<MemberGraphQL>[];
  childrenV2: Nullable<ChildGQL>[];
  vehiclesV2: Nullable<VehicleGQL>[];
}

@Injectable()
export class PersonalInformationMapper
  implements
    TypeMapper<HouseholdPersonalInformationGraphQL, PersonalInformation>
{
  constructor(
    private readonly childMapper: ChildMapper,
    private readonly petMapper: PetMapper,
    private readonly vehicleMapper: VehicleMapper,
    private readonly memberMapper: MemberMapper
  ) {}

  toRawType(
    graphQLType: HouseholdPersonalInformationGraphQL
  ): PersonalInformation {
    const personalInformation: PersonalInformation = {
      householdId: graphQLType.id,
      maritalStatus: MaritalStatus[graphQLType.maritalStatus],
      marriageDate: graphQLType.marriageDate ?? undefined,
      members: graphQLType.members
        .filter(isNotNullish)
        .map((mem) => this.memberMapper.toRawType(mem)),
    };

    const pets: Pet[] = graphQLType.pets
      .filter(isNotNullish)
      .map((pet) => this.petMapper.toRawType(pet));
    if (pets.length > 0) {
      personalInformation.pets = pets;
    }

    const vehicles: Vehicle[] = graphQLType.vehiclesV2
      .filter(isNotNullish)
      .map((vehicle) => this.vehicleMapper.toRawType(vehicle));
    if (vehicles.length > 0) {
      personalInformation.vehicles = vehicles;
    }

    const children: Child[] = graphQLType.childrenV2
      .filter(isNotNullish)
      .map((child) => this.childMapper.toRawType(child));
    if (children.length > 0) {
      personalInformation.children = children;
    }

    return personalInformation;
  }
}
