import { AuthService } from "@air-gmbh/data-access/auth";
import {
  CompanionDetailsGQL,
  DetailsClientGQL,
  UpdateClientEmailAddressGQL,
  UpdateClientPhoneNumberGQL,
  UpdateCompanionGQL,
} from "@air-gmbh/data-access/graphql";
import { RolesCheck } from "@air-gmbh/util/constants";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Guid } from "../../helpers/guid-helper";
import { ClientProfileService } from "./client-profile-service";
import { CompanionProfileService } from "./companion-profile-service";
import { EmptyGuidProfileService } from "./empty-profile-service";
import { IProfileService } from "./i-profile-service";
import { NoRolesProfileService } from "./no-roles-profile-service";

@Injectable({
  providedIn: "root",
})
export class ProfileServiceProvider {
  constructor(
    private readonly companionDetailsGQL: CompanionDetailsGQL,
    private readonly updateCompanionGQL: UpdateCompanionGQL,
    private readonly clientDetailsGQL: DetailsClientGQL,
    private readonly updateClientEmailGQL: UpdateClientEmailAddressGQL,
    private readonly updateClientPhoneNumberGQL: UpdateClientPhoneNumberGQL,
    private readonly authService: AuthService
  ) {}

  getService(userId: string, roles: RolesCheck): Observable<IProfileService> {
    if (Guid.isEmpty(userId) && roles.isAdmin) {
      return this.authService
        .getUserData()
        .pipe(
          map((userData) => new EmptyGuidProfileService(userData.userData))
        );
    } else if (roles.isAdmin || roles.isCompanion) {
      return this.companionDetailsGQL
        .fetch({ id: userId })
        .pipe(
          map(
            (res) =>
              new CompanionProfileService(
                res.data.companion,
                this.updateCompanionGQL
              )
          )
        );
    } else if (roles.isClient) {
      return this.clientDetailsGQL
        .fetch({ id: userId })
        .pipe(
          map(
            (res) =>
              new ClientProfileService(
                res.data.client,
                this.updateClientEmailGQL,
                this.updateClientPhoneNumberGQL
              )
          )
        );
    } else {
      return this.authService
        .getUserData()
        .pipe(map((userData) => new NoRolesProfileService(userData.userData)));
    }
  }
}
