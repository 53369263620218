import { Component, Input, OnChanges } from "@angular/core";

export type IconSize = "sm" | "md" | "lg" | "xl" | "2xl";

@Component({
  selector: "air-icon",
  templateUrl: "./icon.component.html",
})
export class IconComponent implements OnChanges {
  @Input() faClasses?: string;
  @Input() variant: "solid" | "light" = "light";
  @Input() size: IconSize = "xl";

  faClass?: string;

  ngOnChanges(): void {
    this.faClass = undefined;
    setTimeout(() => {
      if (this.faClasses == null) {
        this.faClass = "";
        return;
      }
      let classArray = this.faClasses
        .trim()
        .split(" ")
        .concat([this.variant, this.size === "md" ? "" : this.size]);
      this.faClass = classArray
        .filter((classEl) => classEl !== "")
        .map((classEl) => {
          let trimmedClass = classEl.trim();
          if (!trimmedClass.startsWith("fa-")) {
            trimmedClass = `fa-${trimmedClass}`;
          }
          return trimmedClass;
        })
        .join(" ");
    });
  }
}
