import { Profile } from "../user";

export interface Child {
  id: string;
  allowanceBeneficiary: AllowanceBeneficiaries;
  profile?: Profile;
}

export enum AllowanceBeneficiaries {
  UNKNOWN = "UNKNOWN",
  CONTACT_PERSON = "CONTACT_PERSON",
  PARTNER = "PARTNER",
  COUPLE = "COUPLE",
  NONE = "NONE",
}
