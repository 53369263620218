export type ChipColor = "secondary" | "primary" | "info" | "success" | "error";
export type ChipVariant = "dark" | "light";

export type ChipLoadingConfig = Record<
  ChipColor,
  Record<ChipVariant, Record<"foreground" | "background", string>>
>;

export const chipLoadingConfig: ChipLoadingConfig = {
  primary: {
    light: {
      background: "var(--color-primary-light)",
      foreground: "var(--color-primary-lighter)",
    },
    dark: {
      background: "var(--color-primary)",
      foreground: "var(--color-primary-light)",
    },
  },
  secondary: {
    light: {
      background: "var(--color-secondary-lighter)",
      foreground: "var(--color-secondary-light)",
    },
    dark: {
      background: "var(--color-secondary)",
      foreground: "var(--color-secondary-light)",
    },
  },
  info: {
    light: {
      background: "var(--color-info-light)",
      foreground: "var(--color-info-highlight)",
    },
    dark: {
      background: "var(--color-info-dark)",
      foreground: "var(--color-info-highlight)",
    },
  },
  success: {
    light: {
      background: "var(--color-success-light)",
      foreground: "var(--color-success-highlight)",
    },
    dark: {
      background: "var(--color-success-dark)",
      foreground: "var(--color-success-highlight)",
    },
  },
  error: {
    light: {
      background: "var(--color-error-light)",
      foreground: "var(--color-error-highlight)",
    },
    dark: {
      background: "var(--color-error-dark)",
      foreground: "var(--color-error-highlight)",
    },
  },
};
