import { IEnvironment, IFlags } from "@air-gmbh/util/environment";
import { LogLevel } from "angular-auth-oidc-client";

// Flags
const flags: IFlags = {
  // Expects Record<string, boolean>
  features: {
    auth: true,
    stable: true,
    airboardComparing: false,
    airboardRedesign: false,
    deletePartnerMaritalStatus: false,
    civilServantPensioner: false,
    editPageLayout: false,
    assetRedesign: false,
  },
  // Expects Record<string, any>
  variants: {},
};

export const environment: IEnvironment = {
  environmentName: "FCB Production",
  production: true,
  locale: {
    language: "de-DE",
    fallbackLanguage: "en-US",
    currency: "EUR",
  },
  frontend_url: "https://app.fcb.air-gmbh.com/",
  keycloak: {
    base: "https://id.fcb.air-gmbh.com/",
    authority: "auth/realms/air/protocol/openid-connect",
    wellknownEndpoint: "auth/realms/air/.well-known/openid-configuration",
    redirectUrl: "redirect",
    postLogoutRedirectUri: "login",
    clientId: "app-client",
    scope: "openid offline_access",
    responseType: "code",
    logLevel: LogLevel.Error,
    ignoreNonceAfterRefresh: true,
  },
  graphql: {
    uri: "https://api.app.fcb.air-gmbh.com/graphql",
    wsUri: "wss://api.app.fcb.air-gmbh.com/graphql",
  },
  sentry: {
    reporting: true,
    dsn: "https://3deb12d2f3574b3f84287ef604530ea3@o1122118.ingest.sentry.io/6159444",
  },
  hubspot: {
    contactForm: {
      portalId: "14563601",
      formId: "2bdd64b4-6931-4800-b0cf-9616ce1c509e",
      emailFieldName: "email",
      companionTypeFormName: "coachart",
    },
    meetingSrc:
      "https://meetings.hubspot.com/kontakt155/reihum-digital-hunters?embed=true",
  },
  flags,
  debounceTime: 350,
  alertTime: 4500,
  loadingDebounce: 350,
  printDelay: 350,
  externalURLs: {
    AIRGmbh: "https://air-gmbh.com",
    imprint: "https://air-gmbh.com/legal/impressum/",
  },
};
