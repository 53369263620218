export enum TourStatus {
  PersonalInformation,
  LifestyleOverview,
  LifestyleToday,
  LifestylePension,
  LifestyleInvalid,
  WishesPlans,
  FinancialStructureOverview,
  FinancialStructureIncome,
  FinancialStructureRisk,
  FinancialStructureAssets,
  RiskPotentials,
  SummaryBoard,
  Airboard = -1,
}
