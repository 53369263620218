<div class="fixed z-10 w-full bg-grey-light print:bg-transparent">
  <div
    class="flex h-20 items-center justify-between p-4"
    [ngClass]="{
      'md:px-8 lg:px-16': !fullWidth
    }"
  >
    <a [routerLink]="startRoute">
      <div
        [ngClass]="{
          'w-14': logoVariant === 'default',
          'w-28': logoVariant === 'airboard'
        }"
      >
        <air-logo [variant]="logoVariant"></air-logo>
      </div>
    </a>
    <app-step-bar
      *ngIf="hasStepBar && collapsed"
      [collapsed]="true"
    ></app-step-bar>

    <div class="flex items-center gap-4">
      <air-recalculate-button
        *ngIf="householdId != null && showRecalculateBtn"
        [householdId]="householdId"
      ></air-recalculate-button>
      <app-settings-dropdown class="print:hidden"></app-settings-dropdown>
      <app-client-badge *ngIf="showClientBadge"></app-client-badge>
      <app-account-dropdown class="print:hidden"></app-account-dropdown>
    </div>
  </div>
</div>
<div class="h-20 py-4"></div>
<section
  *ngIf="hasStepBar && !collapsed"
  class="flex flex-col py-0 sm:p-14 sm:py-0"
>
  <app-step-bar></app-step-bar>
</section>
