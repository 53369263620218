import { Pipe, PipeTransform } from "@angular/core";

/**
 * Converts a cent value to a euro value by dividing by 100.
 */
@Pipe({
  name: "euros",
})
export class EurosPipe implements PipeTransform {
  transform(cents: number): number;
  transform(cents: null | undefined): null;
  transform(cents: number | null | undefined): number | null;
  transform(cents: number | null | undefined): number | null {
    return cents != null ? Math.round(cents) / 100 : null;
  }
}
