import { UiIconModule } from "@air-gmbh/ui/icon";
import { UtilDirectivesModule } from "@air-gmbh/util/directives";
import {
  CentsPipe,
  CleanPhoneNumberPipe,
  EurosPipe,
} from "@air-gmbh/util/pipes";
import { CommonModule, DatePipe } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ContentLoaderModule } from "@ngneat/content-loader";
import { TranslateModule } from "@ngx-translate/core";
import { CheckboxComponent } from "./checkbox/checkbox.component";
import { CurrencyInputComponent } from "./currency-input/currency-input.component";
import { DateInputComponent } from "./date-input/date-input.component";
import { EmailInputComponent } from "./email-input/email-input.component";
import { InputComponent } from "./input/input.component";
import { NumberInputComponent } from "./number-input/number-input.component";
import { PhoneNumberInputComponent } from "./phone-number-input/phone-number-input.component";
import { SelectComponent } from "./select/select.component";
import { SuffixInputComponent } from "./suffix-input/suffix-input.component";
import { TextareaComponent } from "./textarea/textarea.component";

@NgModule({
  declarations: [
    InputComponent,
    EmailInputComponent,
    PhoneNumberInputComponent,
    NumberInputComponent,
    CurrencyInputComponent,
    DateInputComponent,
    CheckboxComponent,
    SelectComponent,
    TextareaComponent,
    SuffixInputComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    UiIconModule,
    ContentLoaderModule,
    UtilDirectivesModule,
  ],
  providers: [DatePipe, EurosPipe, CentsPipe, CleanPhoneNumberPipe],
  exports: [
    InputComponent,
    EmailInputComponent,
    PhoneNumberInputComponent,
    NumberInputComponent,
    CurrencyInputComponent,
    DateInputComponent,
    CheckboxComponent,
    SelectComponent,
    TextareaComponent,
    SuffixInputComponent,
  ],
})
export class UiInputsModule {}
