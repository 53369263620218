import { AirError } from "./error.class";
import { ErrorCategory, ErrorInfo } from "./error.type";
import { GraphQLNativeError } from "./graphql-error.type";

export class ErrorUtil {
  /**
   * Transforms an error into a structured error with information.
   */
  static transformError(error: unknown): ErrorInfo | unknown {
    let errorResult: ErrorInfo | undefined = this.transformAirError(error);
    if (errorResult == null) {
      return this.transformGraphQLError(error) ?? error;
    }
    return errorResult;
  }

  /**
   * Checks that the received error is an identified and treated error
   * @param error Potential treated error
   * @returns whether error is identified
   */
  static hasKnownCategory(error: unknown): error is ErrorInfo {
    const category = (error as Partial<ErrorInfo>).category;
    return category != null && Object.values(ErrorCategory).includes(category);
  }

  /**
   * Checks that the received error is a native GraphQL error
   * @param error Potential native GraphQL error
   * @returns whether error is GraphQL native error
   */
  private static isGraphQLNativeError(
    error: unknown
  ): error is GraphQLNativeError {
    return (error as Partial<GraphQLNativeError>).message != null;
  }

  /**
   * Checks that the received error is an AirError
   * @param error Potential AirError
   * @returns whether error is AirError
   */
  private static isAirError(error: unknown): error is AirError {
    return ["AirError", "MissingIdError"].includes((error as AirError).name);
  }

  /**
   * Transform a native GraphQL error.
   */
  private static transformGraphQLError(error: unknown): ErrorInfo | undefined {
    if (this.isGraphQLNativeError(error)) {
      return {
        category: ErrorCategory.GRAPHQL,
        message: "error.unexpected",
        trace: this.extractGraphQLErrorTrace(error),
      };
    }
    return undefined;
  }
  /**
   * Transform a frontend error.
   */
  private static transformAirError(error: unknown): ErrorInfo | undefined {
    if (this.isAirError(error)) {
      return {
        category: ErrorCategory.FRONTEND,
        message: error.message,
        trace: error.stack,
      };
    }
    return undefined;
  }

  /**
   * Extrcats the information needed for the trace field from the GraphQL native error
   * @param error GraphQLNativeError
   * @returns trace to include in the error
   */
  private static extractGraphQLErrorTrace(error: GraphQLNativeError): string {
    if (
      error.networkError != null &&
      error.networkError.error != null &&
      error.networkError.error.errors != null &&
      error.networkError.error.errors[0] != null
    ) {
      return error.networkError.error.errors[0].message;
    }

    if (error.graphQLErrors && error.graphQLErrors[0]) {
      return error.graphQLErrors[0].message;
    }

    return error.message;
  }
}
