import { PropertyUsages as PropertyUsagesGQL } from "@air-gmbh/data-access/graphql";
import {
  Asset,
  AssetCategory,
  RealEstateSubcategory,
} from "@air-gmbh/util/types";
import { Injectable } from "@angular/core";
import { TypeMapper } from "../../common/mapper.interface";

export interface PropertyAssetGQL {
  id: string;
  name: string;
  usageStatus: PropertyUsagesGQL;
  value: number;
  isInventory: boolean;
}

@Injectable()
export class RealEstateAssetMapper
  implements TypeMapper<PropertyAssetGQL, Asset>
{
  toRawType(graphQLType: PropertyAssetGQL): Asset {
    return {
      id: graphQLType.id,
      amount: graphQLType.value,
      category: AssetCategory.REAL_ESTATE,
      subcategory: RealEstateSubcategory[graphQLType.usageStatus],
      name: graphQLType.name,
      isInventory: graphQLType.isInventory,
    };
  }
}
