import {
  ClientDetailsFragment,
  UpdateClientEmailAddressGQL,
  UpdateClientPhoneNumberGQL,
} from "@air-gmbh/data-access/graphql";
import { isNotNullish } from "@tool-belt/type-predicates";
import { Observable, of } from "rxjs";
import { filter, map, tap } from "rxjs/operators";
import { IProfileService, ProfileData } from "./i-profile-service";

export class ClientProfileService implements IProfileService {
  get profileData(): Observable<ProfileData> {
    return of({
      contactEmailAddress: this.clientDetails.emailAddress,
      phoneNumber: this.clientDetails.phoneNumber,
    });
  }
  get name(): Observable<string> {
    const { firstName, lastName } = this.clientDetails;
    return of(`${firstName} ${lastName}`);
  }

  constructor(
    private clientDetails: ClientDetailsFragment,
    private readonly updateClientEmailGQL: UpdateClientEmailAddressGQL,
    private readonly updateClientPhoneNumberGQL: UpdateClientPhoneNumberGQL
  ) {}

  updateEmail(email: string): Observable<string> {
    if (email === this.clientDetails.emailAddress) {
      return of(email);
    } else {
      return this.updateClientEmailGQL
        .mutate({ client: { id: this.clientDetails.id, emailAddress: email } })
        .pipe(
          map((res) => res.data),
          filter(isNotNullish),
          map((data) => data.updateClientEmailAddress.emailAddress),
          tap((newEmail) => {
            this.clientDetails.emailAddress = newEmail;
          })
        );
    }
  }

  updatePhoneNumber(
    phoneNumber: string
  ): Observable<string | null | undefined> {
    if (phoneNumber === this.clientDetails.phoneNumber) {
      return of(phoneNumber);
    } else {
      return this.updateClientPhoneNumberGQL
        .mutate({
          client: {
            id: this.clientDetails.id,
            phoneNumber,
          },
        })
        .pipe(
          map((res) => res.data),
          filter(isNotNullish),
          map((data) => data.updateClientPhoneNumber.phoneNumber),
          tap((newPhoneNumber) => {
            this.clientDetails.phoneNumber = newPhoneNumber;
          })
        );
    }
  }
}
