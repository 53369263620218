// value in cents
export const MAX_MINIJOB_AMOUNT_YEARLY = 624000;

export enum FinancialStructureSection {
  ASSETS = "assets",
  GROSS_INCOME = "income",
  RISK = "risk",
}

export enum FINANCIAL_STRUCTURE_ASSETS {
  PROPERTIES_USING = "propertiesUsing",
  PROPERTIES_RENTED = "propertiesRented",
  PROPERTIES_PENSION = "propertiesRentedForPension",
  HOLDINGS = "holdings",
  INVESTMENTS = "investmentsCapitalMarket",
  LOANS = "loans",
  ILLIQUID_ASSETS = "illiquidAssets",
  CASH = "cash",
  PROVISION = "retirementProvision",
}
