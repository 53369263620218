import { CoverageNeededLevels as CoverageNeededLevelsGraphQL } from "@air-gmbh/data-access/graphql";
import {
  CoverageLevels,
  LoadingAmount,
  RiskBalance,
} from "@air-gmbh/util/types";
import { Injectable } from "@angular/core";
import { TypeMapper } from "../common/mapper.interface";

export type RiskBalanceGraphQL = {
  coverageNeeded: CoverageNeededLevelsGraphQL;
  suggestedTotalCount: number;
  suggestedSelectedCount: number;
  suggestedCosts: LoadingAmount;
  inventoryCosts: LoadingAmount;
};
export type RiskBalanceTotalGraphQL = {
  totalCosts: LoadingAmount;
  essentialBalance: RiskBalanceGraphQL;
  neededBalance: RiskBalanceGraphQL;
  optionalBalance: RiskBalanceGraphQL;
};

@Injectable()
export class RiskBalanceMapper
  implements TypeMapper<RiskBalanceTotalGraphQL, RiskBalance>
{
  toRawType(graphQLType: RiskBalanceTotalGraphQL): RiskBalance {
    return {
      total: graphQLType.totalCosts,
      essential: {
        suggestedTotalCount: graphQLType.essentialBalance.suggestedTotalCount,
        suggestedSelectedCount:
          graphQLType.essentialBalance.suggestedSelectedCount,
        suggestedCosts: graphQLType.essentialBalance.suggestedCosts,
        inventoryCosts: graphQLType.essentialBalance.inventoryCosts,
        coverageNeeded: CoverageLevels.ESSENTIAL,
      },
      needed: {
        suggestedTotalCount: graphQLType.neededBalance.suggestedTotalCount,
        suggestedSelectedCount:
          graphQLType.neededBalance.suggestedSelectedCount,
        suggestedCosts: graphQLType.neededBalance.suggestedCosts,
        inventoryCosts: graphQLType.neededBalance.inventoryCosts,
        coverageNeeded: CoverageLevels.NEEDED,
      },
      optional: {
        suggestedTotalCount: graphQLType.optionalBalance.suggestedTotalCount,
        suggestedSelectedCount:
          graphQLType.optionalBalance.suggestedSelectedCount,
        suggestedCosts: graphQLType.optionalBalance.suggestedCosts,
        inventoryCosts: graphQLType.optionalBalance.inventoryCosts,
        coverageNeeded: CoverageLevels.OPTIONAL,
      },
    };
  }
}
