import { ButtonColor, ButtonVariant } from "@air-gmbh/ui/button";
import {
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
} from "@angular/core";
import { DropdownElement } from "./dropdown-element";

@Component({
  selector: "app-button-dropdown",
  templateUrl: "./button-dropdown.component.html",
})
export class ButtonDropdownComponent {
  @Input() title: string = "";
  @Input() buttonVariant: ButtonVariant = "contained";
  @Input() buttonColor: ButtonColor = "primary";
  @Input() buttonLeftIcon?: string;
  @Input() buttonRightIcon?: string;
  @Input() buttonOnlyIcon?: string;
  @Input() elements: Array<DropdownElement> = [];
  @Input() open: boolean = false;
  @Input() showChevron: boolean = true;
  @Input() withPadding: boolean = true;
  @Output() openChange = new EventEmitter<boolean>();

  toggle(): void {
    this.open = !this.open;
    this.openChange.emit(this.open);
  }

  close(): void {
    this.open = false;
    this.openChange.emit(this.open);
  }

  getString(val: string | TemplateRef<any>): string | null {
    return this.isString(val) ? val : null;
  }

  getTemplateRef(val: string | TemplateRef<any>): TemplateRef<any> | null {
    return !this.isString(val) ? val : null;
  }

  private isString(val: string | TemplateRef<any>): val is string {
    return typeof val === "string";
  }
}
