import {
  GrossIncomeTypes as GrossIncomeTypesGQL,
  MemberTypes as MemberTypesGQL,
} from "@air-gmbh/data-access/graphql";
import {
  GrossIncome,
  GrossIncomeType,
  MemberTypes,
} from "@air-gmbh/util/types";
import { Injectable } from "@angular/core";
import { Nullable } from "ts-toolbelt/out/Union/Nullable";
import { TypeMapper } from "../common/mapper.interface";

export interface GrossIncomeMemberGQL {
  id: string;
  type: MemberTypesGQL;
  isInPension: boolean;
}

export interface GrossIncomeGL {
  id: string;
  yearlyAmount: number;
  monthlyAmount: number;
  grossIncomeType: GrossIncomeTypesGQL;
  synchronizationTargetId?: string;
  member?: Nullable<GrossIncomeMemberGQL>;
}

@Injectable()
export class GrossIncomeMapper
  implements TypeMapper<GrossIncomeGL, GrossIncome>
{
  toRawType(gqlType: GrossIncomeGL): GrossIncome {
    return {
      id: gqlType.id,
      yearlyAmount: gqlType.yearlyAmount,
      monthlyAmount: gqlType.monthlyAmount,
      type: this.mapGrossIncomeKind(gqlType.grossIncomeType),
      member:
        gqlType.member != null
          ? {
              id: gqlType.member.id,
              type: MemberTypes[gqlType.member.type],
              isMemberRetired: gqlType.member.isInPension,
            }
          : undefined,
    };
  }

  private mapGrossIncomeKind(kind: GrossIncomeTypesGQL): GrossIncomeType {
    const mapper: Record<GrossIncomeTypesGQL, GrossIncomeType> = {
      [GrossIncomeTypesGQL.Unknown]: GrossIncomeType.UNKNOWN,
      [GrossIncomeTypesGQL.AgricultureAndForestry]:
        GrossIncomeType.AGRICULTURE_AND_FORESTRY,
      [GrossIncomeTypesGQL.BusinessOperations]:
        GrossIncomeType.BUSINESS_OPERATIONS,
      [GrossIncomeTypesGQL.SelfEmployedWork]:
        GrossIncomeType.SELF_EMPLOYED_WORK,
      [GrossIncomeTypesGQL.EmployedWork]: GrossIncomeType.EMPLOYED_WORK,
      [GrossIncomeTypesGQL.Capital]: GrossIncomeType.CAPITAL,
      [GrossIncomeTypesGQL.LettingProperty]: GrossIncomeType.LETTING_PROPERTY,
      [GrossIncomeTypesGQL.MiscActive]: GrossIncomeType.MISC_ACTIVE,
      [GrossIncomeTypesGQL.MiscPassive]: GrossIncomeType.MISC_PASSIVE,
      [GrossIncomeTypesGQL.TaxFree]: GrossIncomeType.TAX_FREE,
    };
    return mapper[kind];
  }
}
