import { Pipe, PipeTransform } from "@angular/core";

/**
 * Converts a euro value to a cent value by multiplying by 100.
 */
@Pipe({
  name: "cents",
})
export class CentsPipe implements PipeTransform {
  transform(euros: number): number;
  transform(euros: null | undefined): null;
  transform(euros: number | null | undefined): number | null;
  transform(euros: number | null | undefined): number | null {
    return euros != null ? Math.round(euros * 100) : null;
  }
}
