import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Severity } from "./alert";

@Component({
  selector: "air-alert",
  templateUrl: "./alert.component.html",
  styleUrls: ["./alert.component.css"],
})
export class AlertComponent {
  @Input() text = "";
  @Input() severity: Severity = "info";
  @Input() visible = false;

  @Output() closeAlert: EventEmitter<void> = new EventEmitter();

  onClose(): void {
    this.closeAlert.emit();
  }
}
