import { UserService } from "@air-gmbh/data-access/auth";
import { ParamsService } from "@air-gmbh/util/general";
import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

import { HouseholdService } from "@air-gmbh/data-access/household";
import { ClientAccountComponent } from "../../../../user-menu/client-menu-dropdown/client-account.component";

/**
 * @deprecated
 */
@Component({
  selector: "app-client-badge",
  templateUrl: "./client-badge.component.html",
  styleUrls: ["./client-badge.component.css"],
})
export class ClientBadgeComponent extends ClientAccountComponent {
  constructor(
    protected override readonly router: Router,
    protected override readonly paramsService: ParamsService,
    protected override readonly userService: UserService,
    protected override readonly translate: TranslateService,
    protected override readonly householdService: HouseholdService
  ) {
    super(router, paramsService, userService, translate, householdService);
  }
}
