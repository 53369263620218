import { TourStatus } from "@air-gmbh/util/constants";
import { FinancialStructureSection } from "@air-gmbh/util/financial-structure";
import { LifestyleSection } from "@air-gmbh/util/lifestyle";

export const isTourStatus = (value: any): value is TourStatus =>
  Object.values(TourStatus).includes(value);

export const TOUR_REDIRECT: Record<TourStatus, string[]> = {
  [TourStatus.PersonalInformation]: ["personal-information"],
  [TourStatus.LifestyleOverview]: ["lifestyle"],
  [TourStatus.LifestyleToday]: ["lifestyle", String(LifestyleSection.TODAY)],
  [TourStatus.LifestylePension]: [
    "lifestyle",
    String(LifestyleSection.RETIREMENT),
  ],
  [TourStatus.LifestyleInvalid]: [
    "lifestyle",
    String(LifestyleSection.MINIMUM_INCOME),
  ],
  [TourStatus.WishesPlans]: ["wish-plan"],
  [TourStatus.FinancialStructureOverview]: ["financial-structure"],
  [TourStatus.FinancialStructureAssets]: [
    "financial-structure",
    String(FinancialStructureSection.ASSETS),
  ],
  [TourStatus.FinancialStructureIncome]: [
    "financial-structure",
    String(FinancialStructureSection.GROSS_INCOME),
  ],
  [TourStatus.FinancialStructureRisk]: [
    "financial-structure",
    String(FinancialStructureSection.RISK),
  ],
  [TourStatus.RiskPotentials]: ["risk-potentials"],
  [TourStatus.SummaryBoard]: ["summary"],
  [TourStatus.Airboard]: ["airboard"],
};
