import { AirError } from "@air-gmbh/util/error";
import { fromEntries } from "@air-gmbh/util/general";
import {
  LifestyleSummary,
  LifestyleTypes,
  MappedLifestyleCosts,
} from "@air-gmbh/util/types";
import { Injectable } from "@angular/core";
import { isNotNullish } from "@tool-belt/type-predicates";
import { TypeMapper } from "../common/mapper.interface";
import { MemberRetirementMapper } from "../member/member-retirement.mapper";
import { LifestyleHouseholdGraphQL } from "./household-lifestyle.mapper";
import { LifestyleMapperHelper } from "./lifestyle-mapper.helper";
import { LifestyleMapper } from "./lifestyle.mapper";

@Injectable()
export class LifestyleSummaryMapper
  implements TypeMapper<LifestyleHouseholdGraphQL, LifestyleSummary>
{
  constructor(
    private readonly lifestyleMapper: LifestyleMapper,
    private readonly memberRetirementMapper: MemberRetirementMapper
  ) {}

  toRawType(graphQLType: LifestyleHouseholdGraphQL): LifestyleSummary {
    const total: Partial<MappedLifestyleCosts> = fromEntries<
      LifestyleTypes,
      number
    >(
      graphQLType.lifestyles
        .filter(isNotNullish)
        .map((lifestyle) => [
          this.lifestyleMapper.toRawType(lifestyle).type,
          this.lifestyleMapper.toRawType(lifestyle).total,
        ])
    );

    if (LifestyleMapperHelper.isMappedLifestyleCosts(total)) {
      return {
        householdId: graphQLType.id,
        total,
        areMembersRetired: this.memberRetirementMapper.toRawType(
          graphQLType.members?.filter(isNotNullish)
        ),
      };
    }

    throw new AirError(
      LifestyleSummaryMapper,
      "Lifestyle summary mapping failed"
    );
  }
}
