import {
  EmploymentCategories,
  EmploymentsByHouseholdIdGQL,
  HouseholdPersonalInformationGQL,
  MaritalStatus,
  MemberTypes,
  UpdateEmploymentGQL,
  UpdateMaritalStatusGQL,
} from "@air-gmbh/data-access/graphql";
import { PersonalInformationMapper } from "@air-gmbh/data-access/mappers";
import { AirError, ErrorUtil } from "@air-gmbh/util/error";
import { PersonalInformation } from "@air-gmbh/util/types";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { MemberEmployment, UpdateMaritalStatus } from "./types";

@Injectable()
export class PersonalInformationService {
  constructor(
    private readonly editMaritalStatusGQL: UpdateMaritalStatusGQL,
    private readonly updateEmploymentGQL: UpdateEmploymentGQL,
    private readonly employmentsByHouseholdIdGQL: EmploymentsByHouseholdIdGQL,
    private readonly householdPersonalInformationGQL: HouseholdPersonalInformationGQL,
    private readonly personalInformationMapper: PersonalInformationMapper
  ) {}

  updateMaritalStatus(
    householdId: string,
    maritalStatus: MaritalStatus
  ): Observable<UpdateMaritalStatus | null> {
    return this.editMaritalStatusGQL
      .mutate({
        maritalStateUpdate: { householdId, maritalStatus },
      })
      .pipe(
        map((res) => {
          if (res.data?.updateHouseholdMaritalInfo.errors != null) {
            // TODO: Throw bussines logic errors
          }
          if (res.data?.updateHouseholdMaritalInfo.household != null) {
            const ret: UpdateMaritalStatus = {
              maritalStatus:
                res.data.updateHouseholdMaritalInfo.household.maritalStatus,
              members: res.data.updateHouseholdMaritalInfo.household.members,
            };
            return ret;
          }
          return null;
        }),
        catchError((err) => {
          throw ErrorUtil.transformError(err);
        })
      );
  }

  updateHouseholdMemberEmployment(
    householdId: string,
    updatedMember: MemberTypes,
    employmentCategorie: EmploymentCategories
  ): Observable<MemberEmployment | null> {
    return this.employmentsByHouseholdIdGQL
      .fetch({
        input: {
          householdId,
        },
      })
      .pipe(
        map((res) => {
          return res.data.householdById.household?.members
            .filter(
              (member) =>
                member?.type === updatedMember && member.employmentsV2 != null
            )
            .map((member) => {
              return member != null && member.employmentsV2[0] != null
                ? member.employmentsV2[0].id
                : null;
            });
        }),
        switchMap((targetEmploymentId) => {
          if (targetEmploymentId != null && targetEmploymentId[0] != null) {
            return this.updateEmploymentGQL
              .mutate({
                input: {
                  employmentId: targetEmploymentId[0],
                  employmentCategory: employmentCategorie,
                },
              })
              .pipe(
                map((res) => {
                  const employment = res.data?.updateEmployment.employment;
                  if (employment != null) {
                    return {
                      employmentId: employment.id,
                      employmentCategory: employment.employmentCategory,
                    };
                  } else {
                    throw new Error(
                      "No employments in member or target Member"
                    );
                  }
                }),
                catchError((err) => {
                  throw ErrorUtil.transformError(err);
                })
              );
          } else {
            throw new Error("No Target Employment Id");
          }
        }),
        catchError((err) => {
          throw ErrorUtil.transformError(err);
        })
      );
  }

  fetchHouseholdMemberOccupation(
    householdId: string,
    targetMember: MemberTypes
  ): Observable<MemberEmployment | null> {
    return this.employmentsByHouseholdIdGQL
      .fetch({
        input: {
          householdId,
        },
      })
      .pipe(
        map((res) => {
          return res.data.householdById.household?.members.filter(
            (member) =>
              member?.type === targetMember && member.employmentsV2 != null
          );
        }),
        map((member) => {
          const employment = member?.[0]?.employmentsV2[0];
          if (employment != null) {
            return {
              employmentId: employment.id,
              employmentCategory: employment.employmentCategory,
            };
          }
          throw new Error("Member does not exist or has no Ocupation");
        }),
        catchError((err) => {
          throw ErrorUtil.transformError(err);
        })
      );
  }

  fetchPersonalInformation(
    householdId: string
  ): Observable<PersonalInformation> {
    return this.householdPersonalInformationGQL
      .fetch({ input: { householdId } })
      .pipe(
        map((res) => {
          const { household } = res.data.householdById;
          if (household != null) {
            return this.personalInformationMapper.toRawType(household);
          }
          throw new AirError(
            PersonalInformationService,
            "There's no household to map"
          );
        }),
        catchError((err) => throwError(ErrorUtil.transformError(err)))
      );
  }
}
