import { LifestyleTypes as LifestyleTypesGraphQL } from "@air-gmbh/data-access/graphql";
import { LifestyleUtil } from "@air-gmbh/util/lifestyle";
import { Lifestyle } from "@air-gmbh/util/types";
import { Injectable } from "@angular/core";
import { TypeMapper } from "../common/mapper.interface";
import { LifestyleMapperHelper } from "./lifestyle-mapper.helper";

export interface LifestyleGraphQL {
  id: string;
  food: number;
  utilityCost: number;
  rent: number;
  clothing: number;
  education: number;
  media: number;
  vacation: number;
  mobility: number;
  miscellaneous: number;
  buffer: number;
  lifestyleType: LifestyleTypesGraphQL;
}

@Injectable()
export class LifestyleMapper
  implements TypeMapper<LifestyleGraphQL, Lifestyle>
{
  toRawType(graphQLType: LifestyleGraphQL): Lifestyle {
    return {
      id: graphQLType.id,
      type: LifestyleMapperHelper.getLifestyleType(graphQLType.lifestyleType),
      food: graphQLType.food,
      utilityCost: graphQLType.utilityCost,
      rent: graphQLType.rent,
      clothing: graphQLType.clothing,
      education: graphQLType.education,
      media: graphQLType.media,
      vacation: graphQLType.vacation,
      mobility: graphQLType.mobility,
      miscellaneous: graphQLType.miscellaneous,
      buffer: graphQLType.buffer,
      total: LifestyleUtil.getLifestyleTotalCosts(graphQLType),
    };
  }
}
