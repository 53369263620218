import { DataAccessAuthModule } from "@air-gmbh/data-access/auth";
import { DataAccessMemberModule } from "@air-gmbh/data-access/member";
import { UiAlertModule } from "@air-gmbh/ui/alert";
import { CALENDAR_TRANSLATION_PATHS } from "@air-gmbh/ui/inputs";
import { WISH_CATEGORY_TRANSLATION_PATH } from "@air-gmbh/ui/wish";
import { ENVIRONMENT_CONFIG } from "@air-gmbh/util/environment";
import { registerLocaleData } from "@angular/common";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import localeDe from "@angular/common/locales/de";
import localeDeExtra from "@angular/common/locales/extra/de";
import {
  APP_INITIALIZER,
  DEFAULT_CURRENCY_CODE,
  ErrorHandler,
  LOCALE_ID,
  NgModule,
  Provider,
} from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { Router } from "@angular/router";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import * as Sentry from "@sentry/angular";
import { environment } from "../environments/environment";
import { AirboardRedirectComponent } from "./airboard-redirect/airboard-redirect.component";
import { AppAuthModule } from "./app-auth.module";
import { AppComponent } from "./app.component";
import { AuthRoutingModule } from "./auth-routing.module";
import { FeedbackModule } from "./feedback/feedback.module";
import { LoginComponent } from "./login/login.component";
import { OptInModule } from "./opt-in/opt-in.module";
import { RedirectComponent } from "./redirect/redirect.component";
import { APP_CALENDAR_TRANSLATION_PATHS } from "./shared/constants/translations";
import { GraphQLModule } from "./shared/graphql.module";
import { LayoutsModule } from "./shared/layouts/layouts.module";
import { AlertService } from "./shared/services/alert.service";

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

if (environment.locale.language === "de-DE") {
  registerLocaleData(localeDe, environment.locale.language, localeDeExtra);
}

const providers: Provider[] = [
  {
    provide: LOCALE_ID,
    useValue: environment.locale.language,
  },
  {
    provide: DEFAULT_CURRENCY_CODE,
    useValue: environment.locale.currency,
  },
  {
    provide: WISH_CATEGORY_TRANSLATION_PATH,
    useValue: "wish.category",
  },
  AlertService,
  {
    provide: CALENDAR_TRANSLATION_PATHS,
    useValue: APP_CALENDAR_TRANSLATION_PATHS,
  },
  {
    provide: ENVIRONMENT_CONFIG,
    useValue: environment,
  },
];

if (environment.sentry.reporting) {
  providers.push(
    ...[
      {
        provide: ErrorHandler,
        useValue: Sentry.createErrorHandler(),
      },
      {
        provide: Sentry.TraceService,
        deps: [Router],
      },
      {
        provide: APP_INITIALIZER,
        useFactory: () => (): void => {},
        deps: [Sentry.TraceService],
        multi: true,
      },
    ]
  );
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RedirectComponent,
    AirboardRedirectComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AuthRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      defaultLanguage: environment.locale.fallbackLanguage,
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    LayoutsModule,
    GraphQLModule,
    UiAlertModule,
    AppAuthModule,
    FeedbackModule,
    OptInModule,
    DataAccessMemberModule,
    DataAccessAuthModule,
  ],
  providers,
  bootstrap: [AppComponent],
})
export class AppModule {}
