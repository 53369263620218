import { Injectable } from "@angular/core";
import { Nullable } from "ts-toolbelt/out/Union/Nullable";
import { TypeMapper } from "../common/mapper.interface";

export interface WishBalanceGraphQL {
  household?: Nullable<{
    id: string;
    wishBalanceV2?: Nullable<{ costs: number }>;
  }>;
}

@Injectable()
export class WishBalanceMapper
  implements TypeMapper<WishBalanceGraphQL, number>
{
  toRawType(graphQLType: WishBalanceGraphQL): number {
    return graphQLType.household?.wishBalanceV2?.costs ?? 0;
  }
}
