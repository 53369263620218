import { Client, Profile, RegistrationStatus } from "@air-gmbh/util/types";
import { Injectable } from "@angular/core";
import { Nullable } from "ts-toolbelt/out/Union/Nullable";
import { TypeMapper } from "../common/mapper.interface";

export interface ClientGraphQL {
  id: string;
  firstName: string;
  lastName: string;
  registrationStatus: RegistrationStatus;
  createdDate: string;
  lastUpdatedDate: string;
  tourStatus: number;
  emailAddressV2?: Nullable<string>;
  clientVersionChanged: boolean;
  hasApprovedPrivacyInfo: boolean;
  companionHouseholdV2?: Nullable<{ id: string }>;
}

@Injectable({ providedIn: "root" })
export class ClientMapper implements TypeMapper<ClientGraphQL, Client> {
  toRawType(graphQLType: ClientGraphQL): Client {
    const profile = new Profile(
      undefined,
      graphQLType.firstName,
      graphQLType.lastName
    );
    return {
      id: graphQLType.id,
      registrationStatus: graphQLType.registrationStatus,
      profile,
      createdDate: graphQLType.createdDate,
      lastUpdatedDate: graphQLType.lastUpdatedDate,
      tourStatus: graphQLType.tourStatus,
      companionHouseholdId: graphQLType.companionHouseholdV2?.id,
      email: graphQLType.emailAddressV2 ?? undefined,
      clientVersionChanged: graphQLType.clientVersionChanged,
      hasApprovedPrivacyInfo: graphQLType.hasApprovedPrivacyInfo,
    };
  }
}
