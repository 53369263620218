import { FeatureRecalculateButtonModule } from "@air-gmbh/feature/recalculate-button";
import { LogoModule } from "@air-gmbh/ui/assets";
import { UiIconModule } from "@air-gmbh/ui/icon";
import { UiToggleModule } from "@air-gmbh/ui/toggle";
import { UtilDirectivesModule } from "@air-gmbh/util/directives";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { ProfileService } from "../../services/profile/profile-service";
import { SharedModule } from "../../shared.module";
import { ButtonDropdownModule } from "../button-dropdown/button-dropdown.module";
import { SettingsDropdownModule } from "../settings-dropdown/settings-dropdown.module";
import { StepBarModule } from "../step-bar/step-bar.module";
import { AccountDropdownComponent } from "./account-dropdown/account-dropdown.component";
import { ClientBadgeComponent } from "./client-badge/client-badge.component";
import { HeaderComponent } from "./header.component";

@NgModule({
  declarations: [
    HeaderComponent,
    AccountDropdownComponent,
    ClientBadgeComponent,
  ],
  imports: [
    ButtonDropdownModule,
    CommonModule,
    TranslateModule,
    UiIconModule,
    StepBarModule,
    RouterModule,
    UiToggleModule,
    SharedModule,
    SettingsDropdownModule,
    UtilDirectivesModule,
    LogoModule,
    FeatureRecalculateButtonModule,
  ],
  exports: [HeaderComponent],
  providers: [ProfileService],
})
export class HeaderModule {}
