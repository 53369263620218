import { UserService } from "@air-gmbh/data-access/auth";
import { Injectable, OnDestroy } from "@angular/core";
import { combineLatest, Observable, ReplaySubject, Subscription } from "rxjs";
import { switchMap } from "rxjs/operators";
import { IProfileService, ProfileData } from "./i-profile-service";
import { ProfileServiceProvider } from "./profile-service-provider";

@Injectable()
export class ProfileService implements IProfileService, OnDestroy {
  private service = new ReplaySubject<IProfileService>(1);
  private sub = new Subscription();

  get profileData(): Observable<ProfileData> {
    return this.service.pipe(switchMap((service) => service.profileData));
  }
  get name(): Observable<string> {
    return this.service.pipe(switchMap((service) => service.name));
  }

  constructor(
    private readonly userService: UserService,
    private readonly profileServiceProvider: ProfileServiceProvider
  ) {
    this.sub.add(
      combineLatest([this.userService.getUserId(), this.userService.getRoles()])
        .pipe(
          switchMap(([userId, roles]) =>
            this.profileServiceProvider.getService(userId, roles)
          )
        )
        .subscribe((service) => {
          this.service.next(service);
        })
    );
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  updateEmail(email: string): Observable<string> {
    return this.service.pipe(
      switchMap((service) => service.updateEmail(email))
    );
  }

  updatePhoneNumber(
    phoneNumber: string
  ): Observable<string | null | undefined> {
    return this.service.pipe(
      switchMap((service) => service.updatePhoneNumber(phoneNumber))
    );
  }
}
