import { Member } from "../member";
import { Child } from "./child.type";
import { Pet } from "./pet.type";
import { Vehicle } from "./vehicle.type";

export enum MaritalStatus {
  UNKNOWN = "UNKNOWN",
  SINGLE = "SINGLE",
  MARRIED = "MARRIED",
  WIDOWED = "WIDOWED",
  DIVORCED = "DIVORCED",
}

export interface PersonalInformation {
  householdId: string;
  maritalStatus: MaritalStatus;
  marriageDate?: string;
  members: Member[];
  pets?: Pet[];
  children?: Child[];
  vehicles?: Vehicle[];
}
