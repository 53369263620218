import { Companion } from "@air-gmbh/util/types";
import { Injectable } from "@angular/core";
import { Nullable } from "ts-toolbelt/out/Union/Nullable";
import { ClientGraphQL, ClientMapper } from "../client/client.mapper";
import { TypeMapper } from "../common/mapper.interface";
import { ProfileGQL, ProfileMapper } from "../common/profile.mapper";

export interface CompanionGraphQL {
  id: string;
  profile: ProfileGQL;
  contactProfile: {
    contactEmailAddress: string;
    phoneNumber?: Nullable<string>;
  };
  isActive: boolean;
  clients?: Nullable<ClientGraphQL[]>;
}

@Injectable({ providedIn: "root" })
export class CompanionMapper
  implements TypeMapper<CompanionGraphQL, Companion>
{
  constructor(
    private readonly clientMapper: ClientMapper,
    private readonly profileMapper: ProfileMapper
  ) {}

  toRawType(graphQLType: CompanionGraphQL): Companion {
    const companion: Companion = {
      id: graphQLType.id,
      contactProfile: {
        email: graphQLType.contactProfile.contactEmailAddress,
        phoneNumber: graphQLType.contactProfile.phoneNumber ?? undefined,
      },
      profile: this.profileMapper.toRawType(graphQLType.profile),
      isActive: graphQLType.isActive,
    };

    if (graphQLType.clients != null) {
      companion.clients = graphQLType.clients.map((client) =>
        this.clientMapper.toRawType(client)
      );
    }

    return companion;
  }
}
