import { ENVIRONMENT_CONFIG, IEnvironment } from "@air-gmbh/util/environment";
import { Component, Inject } from "@angular/core";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.css"],
})
export class FooterComponent {
  //imprint = this.environment.externalURLs.imprint;
  //privacyPolicy = DATA_PRIVACY_URL.privacyPolicy;
  //privacyInformation = DATA_PRIVACY_URL.privacyInformation;
  constructor(
    @Inject(ENVIRONMENT_CONFIG) private readonly environment: IEnvironment
  ) {}
}
