export enum ToggleState {
  Disabled = "Disabled",
  Active = "Active",
  Neutral = "Neutral",
}

export namespace ToggleState {
  export const toBoolean = (toggleState: ToggleState): boolean | null => {
    switch (toggleState) {
      case ToggleState.Neutral:
        return null;
      case ToggleState.Active:
        return true;
      case ToggleState.Disabled:
        return false;
    }
  };

  export const fromBoolean = (booleanState: boolean): ToggleState => {
    if (booleanState) {
      return ToggleState.Active;
    } else {
      return ToggleState.Disabled;
    }
  };
}
