<ng-container [ngSwitch]="variant">
  <img
    *ngSwitchCase="'airboard'"
    src="/assets/ui/logo/logo-airboard.svg"
    alt="AIRboard logo"
    class="w-full"
  />
  <img
    *ngSwitchCase="'up-to-you'"
    src="/assets/ui/logo/logo-air-up-to-you.svg"
    alt="AIR logo up to you"
    class="w-full"
  />
  <img
    *ngSwitchDefault
    src="/assets/ui/logo/logo-air.svg"
    alt="AIR logo"
    class="w-full"
  />
</ng-container>
