import { SettingsMapper } from "@air-gmbh/data-access/mappers";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { SettingsService } from "./settings.service";

@NgModule({
  imports: [CommonModule],
  providers: [SettingsService, SettingsMapper],
})
export class DataAccessSettingsModule {}
