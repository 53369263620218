import {
  EmploymentMapper,
  GrossIncomeMapper,
  GrossPensionMapper,
  MemberMapper,
  ProfileMapper,
} from "@air-gmbh/data-access/mappers";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MemberService } from "./services/member.service";

@NgModule({
  imports: [CommonModule],
  providers: [
    MemberService,
    MemberMapper,
    ProfileMapper,
    EmploymentMapper,
    GrossIncomeMapper,
    GrossPensionMapper,
  ],
})
export class DataAccessMemberModule {}
