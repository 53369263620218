import { EmploymentCategories as EmploymentCategoriesGQL } from "@air-gmbh/data-access/graphql";
import { Injectable } from "@angular/core";
import { isNotNullish } from "@tool-belt/type-predicates";
import { Nullable } from "ts-toolbelt/out/Union/Nullable";
import { TypeMapper } from "../common/mapper.interface";

export interface MemberEmploymentCategoryGQL {
  id: string;
  employmentsV2?: Nullable<{
    id: string;
    employmentCategory: EmploymentCategoriesGQL;
  }>[];
}

/**
 * @deprecated If you want to know the retirement state of a member, rather
 * rely on the `isMemberRetired` property of the `Member` type instead of
 * the employment categories.
 */
@Injectable({
  providedIn: "root",
})
export class MemberRetirementMapper
  implements TypeMapper<MemberEmploymentCategoryGQL[], boolean>
{
  toRawType(graphQLType?: MemberEmploymentCategoryGQL[]): boolean {
    if (graphQLType != null && graphQLType.filter(isNotNullish).length > 0) {
      return graphQLType
        .map((mem) =>
          mem.employmentsV2
            ?.filter(isNotNullish)
            .map((emp) => emp.employmentCategory)
        )
        .map((employments?: EmploymentCategoriesGQL[]) =>
          employments != null
            ? employments.some((emp) =>
                [
                  EmploymentCategoriesGQL.CivilServantPensioner,
                  EmploymentCategoriesGQL.Pensioner,
                ].includes(emp)
              )
            : false
        )
        .every((isRetired) => isRetired === true);
    }
    return false;
  }
}
