import {
  IlliquidAssetTypes,
  IncomeTaxCategory as IncomeTaxCategoryGQL,
} from "@air-gmbh/data-access/graphql";
import {
  AssetCategory,
  IlliquidAsset,
  IlliquidAssetsSubcategory,
  IncomeTaxCategory,
} from "@air-gmbh/util/types";
import { Injectable } from "@angular/core";
import { TypeMapper } from "../../common/mapper.interface";
import { Nullable } from "ts-toolbelt/out/Union/Nullable";
import { LoanAssetGQL, LoanAssetMapper } from "../loans";
import { isNotNullish } from "@tool-belt/type-predicates";

export interface IlliquidGQL {
  id: string;
  name: string;
  type: IlliquidAssetTypes;
  value: number;
  isInventory: boolean;
  taxCategoryOfYield: IncomeTaxCategoryGQL;
  capitalCommitmentYears?: Nullable<number>;
  estimatedValueIncreasePerYear?: Nullable<number>;
  yieldPerMonthAmount?: Nullable<number>;
  comments?: Nullable<string>;
  linkedLoans: Nullable<LoanAssetGQL>[];
}

@Injectable()
export class IlliquidMapper implements TypeMapper<IlliquidGQL, IlliquidAsset> {
  constructor(private readonly loanAssetMapper: LoanAssetMapper) {}

  toRawType(illiquidGQL: IlliquidGQL): IlliquidAsset {
    return {
      id: illiquidGQL.id,
      name: illiquidGQL.name,
      category: AssetCategory.ILLIQUID_ASSETS,
      subcategory: IlliquidAssetsSubcategory[illiquidGQL.type],
      amount: illiquidGQL.value,
      isInventory: illiquidGQL.isInventory,
      capitalCommitmentYears: illiquidGQL.capitalCommitmentYears ?? undefined,
      taxIncomeCategory: IncomeTaxCategory[illiquidGQL.taxCategoryOfYield],
      estimatedValueIncreasePerYear:
        illiquidGQL.estimatedValueIncreasePerYear ?? undefined,
      yield: illiquidGQL.yieldPerMonthAmount ?? undefined,
      comments: illiquidGQL.comments ?? undefined,
      linkedAssets: illiquidGQL.linkedLoans
        .filter(isNotNullish)
        .map((value) => this.loanAssetMapper.toRawType(value)),
    };
  }
}
