import { LoadingAmount } from "../common";

export interface GrossPension {
  id: string;
  synchronizationTargetId?: string;
  costs: LoadingAmount;
  isUserEntered: boolean;
  grossPensionType: GrossPensionType;
}

export enum GrossPensionType {
  UNKNOWN = "UNKNOWN",
  STATE = "STATE",
  SUPPLEMENTARY_FUND = "SUPPLEMENTARY_FUND",
  CIVIL_SERVANT_PROVISION = "CIVIL_SERVANT_PROVISION",
}
