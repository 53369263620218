<div
  *ngIf="visible"
  class="relative z-10 pt-2"
  airOuterClick
  (clickOutside)="hide()"
>
  <svg
    class="absolute top-0 h-2 text-black"
    [class.left-6]="placement === 'right'"
    [class.right-6]="placement === 'left'"
    x="0px"
    y="0px"
    viewBox="0 0 255 255"
    xml:space="preserve"
  >
    <polygon class="fill-current" points="255,255 127.5,127.5 0,255" />
  </svg>
  <div class="body-sm rounded-md bg-black p-2 text-white-pure shadow">
    <ng-content></ng-content>
  </div>
</div>
