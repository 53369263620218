import { Profile } from "../user";
import { Employment } from "./employment.type";
import { GrossIncome } from "./gross-income.type";
import { GrossPension } from "./gross-pension.type";

export type ContactPerson = "contactMember" | "partner";

export enum MemberTypes {
  UNKNOWN = "UNKNOWN",
  CONTACT_MEMBER = "CONTACT_MEMBER",
  PARTNER = "PARTNER",
}

export type KnownMemberTypes = Exclude<MemberTypes, MemberTypes.UNKNOWN>;

export interface Member {
  id: string;
  type: MemberTypes;
  isMemberRetired: boolean;
  pensionEntryAge?: number;
  paysChurchTax?: boolean;
  hasPublicHealthInsurance?: boolean;
  privateHealthCosts?: number;
  profile?: Profile;
  employments?: Employment[];
  grossIncomes?: GrossIncome[];
  grossPensions?: GrossPension[];
  creditRate?: number;
}

export type UpdateMemberInfo = {
  memberId: string;
  paysChurchTax?: boolean;
  pensionEntryAge?: number;
  isInPension?: boolean;
};

//TODO: Remove and replace with Existing Mapped Elements
export type CreateMemberResponse = {
  id: string;
  isPartner: boolean;
};
