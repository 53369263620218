import { UiButtonModule } from "@air-gmbh/ui/button";
import { UiIconModule } from "@air-gmbh/ui/icon";
import { UtilDirectivesModule } from "@air-gmbh/util/directives";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ButtonDropdownComponent } from "./button-dropdown.component";

@NgModule({
  declarations: [ButtonDropdownComponent],
  imports: [CommonModule, UiIconModule, UiButtonModule, UtilDirectivesModule],
  exports: [ButtonDropdownComponent],
})
export class ButtonDropdownModule {}
