import { LoanTypes } from "@air-gmbh/data-access/graphql";
import { Asset, AssetCategory } from "@air-gmbh/util/types";
import { Injectable } from "@angular/core";
import { TypeMapper } from "../../common/mapper.interface";

export interface LoanAssetGQL {
  id: string;
  name: string;
  type: LoanTypes;
  value: number;
  isInventory: boolean;
}

@Injectable()
export class LoanAssetMapper implements TypeMapper<LoanAssetGQL, Asset> {
  toRawType(loan: LoanAssetGQL): Asset {
    return {
      id: loan.id,
      name: loan.name,
      category: AssetCategory.LOANS,
      subcategory: loan.type,
      amount: loan.value,
      isInventory: loan.isInventory,
    };
  }
}
