export enum AirboardStatus {
  PersonalInformation,
  Lifestyle,
  WishesPlans,
  FinancialStructure,
  RiskPotentials,
}

export type AirboardEdit = {
  icon: string;
  redirect: string;
  title: string;
};

export const AIRBOARD_EDIT: Record<AirboardStatus, AirboardEdit> = {
  [AirboardStatus.PersonalInformation]: {
    redirect: "personal-information",
    icon: "user",
    title: "tour.personalInformation.title",
  },
  [AirboardStatus.Lifestyle]: {
    redirect: "lifestyle",
    icon: "home",
    title: "tour.lifestyle.title",
  },
  [AirboardStatus.WishesPlans]: {
    redirect: "wish-plan",
    icon: "heart",
    title: "tour.wishesAndPlans.title",
  },
  [AirboardStatus.FinancialStructure]: {
    redirect: "financial-structure",
    icon: "money-bill",
    title: "tour.financialStructure.title",
  },
  [AirboardStatus.RiskPotentials]: {
    redirect: "risk-potentials",
    icon: "shield-exclamation",
    title: "tour.riskPotential.capitalRiskOtherProvisions",
  },
};
