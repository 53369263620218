import {
  GrossIncomeTypes as GrossIncomeTypesGQL,
  GrossPensionTypes as GrossPensionTypesGQL,
} from "@air-gmbh/data-access/graphql";
import { IncomeSummary } from "@air-gmbh/util/types";
import { Injectable } from "@angular/core";
import { isNotNullish } from "@tool-belt/type-predicates";
import { Nullable } from "ts-toolbelt/out/Union/Nullable";
import { TypeMapper } from "../common/mapper.interface";

export interface MemberIncomeSummaryGQL {
  id: string;
  isInPension: boolean;
  creditRate: number;
  employmentsV3: Nullable<{
    id: string;
    grossIncome?: Nullable<{
      id: string;
      monthlyAmount: number;
    }>;
  }>[];
  grossIncomesV2: Nullable<{
    id: string;
    monthlyAmount: number;
    grossIncomeType: GrossIncomeTypesGQL;
  }>[];
  grossPensionsV2: Nullable<{
    id: string;
    grossPensionType: GrossPensionTypesGQL;
    costs: { amount: number };
  }>[];
}

@Injectable()
export class IncomeSummaryMapper
  implements TypeMapper<MemberIncomeSummaryGQL[], IncomeSummary>
{
  toRawType(graphQLType: MemberIncomeSummaryGQL[]): IncomeSummary {
    if (graphQLType.length === 0) {
      return {
        activeIncome: 0,
        passiveIncome: 0,
        creditRate: 0,
        statutoryPension: 0,
      };
    }

    return {
      activeIncome: this.getActiveIncomeSum(graphQLType),
      passiveIncome: this.getPassiveIncomeSum(graphQLType),
      creditRate: graphQLType
        .map((member) => member.creditRate)
        .reduce((prev, next) => prev + next),
      statutoryPension: this.getStatutoryPension(graphQLType),
    };
  }

  private getActiveIncomeSum(members: MemberIncomeSummaryGQL[]): number {
    return members
      .map((member) => {
        return member.employmentsV3
          .filter(isNotNullish)
          .map((employment) => employment.grossIncome)
          .filter(isNotNullish)
          .map((income) => income.monthlyAmount)
          .reduce((prev, next) => prev + next, 0);
      })
      .reduce((prev, next) => prev + next);
  }

  private getPassiveIncomeSum(members: MemberIncomeSummaryGQL[]): number {
    return members
      .map((member) => {
        const incomes = member.grossIncomesV2
          .filter(isNotNullish)
          .filter((income) =>
            [
              GrossIncomeTypesGQL.MiscPassive,
              GrossIncomeTypesGQL.TaxFree,
            ].includes(income.grossIncomeType)
          );

        return incomes
          .map((income) => income.monthlyAmount)
          .reduce((prev, next) => prev + next, 0);
      })
      .reduce((prev, next) => prev + next);
  }

  private getStatutoryPension(members: MemberIncomeSummaryGQL[]): number {
    const retiredMembers = members.filter((member) => member.isInPension);
    if (retiredMembers.length === 0) {
      return 0;
    }
    return retiredMembers
      .map((member) => {
        const pensions = member.grossPensionsV2
          .filter(isNotNullish)
          .filter(
            (pension) => pension.grossPensionType === GrossPensionTypesGQL.State
          );

        return pensions
          .map((pension) => pension.costs.amount)
          .reduce((prev, next) => prev + next, 0);
      })
      .reduce((prev, next) => prev + next);
  }
}
