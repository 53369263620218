import { TourStatus } from "@air-gmbh/util/constants";
import { AirError } from "@air-gmbh/util/error";
import { distinctUntilObjectChanged } from "@air-gmbh/util/general";
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Subscription, combineLatest } from "rxjs";
import { filter } from "rxjs/operators";
import { C } from "ts-toolbelt";
import { TourNavigationService } from "../../services/tour-navigation.service";
import { Step } from "./step/step.component";

export class TourStatusUndefinedError extends AirError {
  constructor(source: C.Class | string) {
    super(source, "Tour status is null");
  }
}

@Component({
  selector: "app-step-bar",
  templateUrl: "./step-bar.component.html",
  styleUrls: ["./step-bar.component.css"],
})
export class StepBarComponent implements OnInit, OnDestroy {
  @Input() collapsed = false;

  @Output() collapsedChange = new EventEmitter<boolean>();

  highestVisitedStep = 0;
  activeStep = 0;
  isValid = false;
  steps: Step[] = [
    new Step(
      [TourStatus.PersonalInformation],
      "user",
      this.translate.instant("tour.personalInformation.title")
    ),
    new Step(
      [
        TourStatus.LifestyleOverview,
        TourStatus.LifestyleToday,
        TourStatus.LifestylePension,
        TourStatus.LifestyleInvalid,
      ],
      "home",
      this.translate.instant("tour.lifestyle.title")
    ),
    new Step(
      [TourStatus.WishesPlans],
      "heart",
      this.translate.instant("tour.wishesAndPlans.title")
    ),
    new Step(
      [
        TourStatus.FinancialStructureOverview,
        TourStatus.FinancialStructureAssets,
        TourStatus.FinancialStructureIncome,
        TourStatus.FinancialStructureRisk,
      ],
      "money-bill",
      this.translate.instant("tour.financialStructure.title.dash")
    ),
    new Step(
      [TourStatus.RiskPotentials],
      "shield-exclamation",
      this.translate.instant("tour.riskPotential.title")
    ),
  ];

  private sub = new Subscription();

  get showPreviousButton(): boolean {
    return this.activeStep !== TourStatus.PersonalInformation;
  }

  get canVisitNext(): boolean {
    return this.isValid;
  }

  constructor(
    private readonly tourNavigation: TourNavigationService,
    private readonly translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.sub.add(
      combineLatest([
        this.tourNavigation.isValid$,
        this.tourNavigation.areRetired$,
      ]).subscribe(([isValid, areRetired]) => {
        this.isValid = isValid;
        if (areRetired) {
          // Remove pension and invalid lifestyle for retired user from steps
          this.steps = this.steps.map((step) => {
            step.tourStatuses = step.tourStatuses.filter(
              (stepTourStatus) =>
                ![
                  TourStatus.LifestyleInvalid,
                  TourStatus.LifestylePension,
                ].includes(stepTourStatus)
            );
            return step;
          });
        }
      })
    );

    this.sub.add(
      this.tourNavigation.tourStatus$
        .pipe(
          distinctUntilObjectChanged(),
          filter((tourStatus): tourStatus is TourStatus => tourStatus != null)
        )
        .subscribe(
          (tourStatus: TourStatus) =>
            (this.activeStep = this.getStepIndexOfTourStatus(tourStatus))
        )
    );
    this.sub.add(
      this.tourNavigation.highestVisitedStatus$
        .pipe(
          distinctUntilObjectChanged(),
          filter((tourStatus): tourStatus is TourStatus => tourStatus != null)
        )
        .subscribe(
          (tourStatus: TourStatus) =>
            (this.highestVisitedStep =
              this.getStepIndexOfTourStatus(tourStatus))
        )
    );
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  goNext(): void {
    if (this.canVisitNext) {
      this.tourNavigation.next();
    }
  }

  goPrevious(): void {
    this.tourNavigation.previous();
  }

  goTo(newIdx: number): void {
    if (this.canVisit(newIdx)) {
      const tourStatus = this.stepAt(newIdx).tourStatuses[0];
      if (tourStatus != null) {
        this.tourNavigation.goTo(tourStatus);
      } else {
        throw new TourStatusUndefinedError(StepBarComponent);
      }
    }
  }

  canVisit(newIdx: number): boolean {
    // TODO: consider if to allow visiting next steps < highestVisited when current form invalid
    if (newIdx <= this.activeStep || newIdx <= this.highestVisitedStep) {
      return true;
    } else {
      const tourStatus = this.tourNavigation.tourStatus;
      if (tourStatus != null) {
        return (
          this.isValid &&
          newIdx <= this.highestVisitedStep + 1 &&
          this.isAtLastSubStep(tourStatus, this.stepAt(this.activeStep))
        );
      } else {
        return false;
      }
    }

    /**
     * TODO: what if current step valid but highestVisited invalid
     * => would require backend as we have to save validity somewhere also after page reload
     */
  }

  private stepAt(idx: number): Step {
    const step = this.steps[idx];
    if (step != null) {
      return step;
    } else {
      throw new AirError(StepBarComponent, "No step at given index");
    }
  }

  /**
   * @param tourStatus current tourStatus
   * @param step current step
   * @returns true for steps which consist of only one "substep".
   * For steps consisting of multiple substeps, true if current substep is last substep
   */
  private isAtLastSubStep(tourStatus: TourStatus, step: Step): boolean {
    if (step.tourStatuses.length === 0) {
      throw new AirError(StepBarComponent, "TourStep has no statuses");
    } else {
      const lastStepStatus = step.tourStatuses[step.tourStatuses.length - 1];
      return tourStatus === lastStepStatus;
    }
  }

  private getStepIndexOfTourStatus(tourStatus: TourStatus): number {
    return this.steps.findIndex((step: Step) =>
      step.tourStatuses.includes(tourStatus)
    );
  }
}
