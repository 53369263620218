import { UserService } from "@air-gmbh/data-access/auth";
import {
  TourStatusClientGQL,
  UpdateTourStatusGQL,
} from "@air-gmbh/data-access/graphql";
import { HouseholdService } from "@air-gmbh/data-access/household";
import { MemberRetirementMapper } from "@air-gmbh/data-access/mappers";
import { ROLES, TourStatus } from "@air-gmbh/util/constants";
import { Injectable } from "@angular/core";
import { Router, UrlTree } from "@angular/router";
import { isNotNullish } from "@tool-belt/type-predicates";
import { BehaviorSubject, Observable, combineLatest, of } from "rxjs";
import {
  catchError,
  delay,
  distinctUntilChanged,
  filter,
  first,
  map,
  switchMap,
  tap,
} from "rxjs/operators";
import { TOUR_REDIRECT, isTourStatus } from "../constants/tour";
import { GraphQLService } from "./graphQL.service";

@Injectable({
  providedIn: "root",
})
export class TourNavigationService {
  private readonly tourStatusSubject = new BehaviorSubject<
    TourStatus | undefined
  >(undefined);
  private readonly highestVisitedStatusSubject = new BehaviorSubject<
    TourStatus | undefined
  >(undefined);
  private readonly areRetiredSubject = new BehaviorSubject<boolean>(false);
  private readonly isValidSubject = new BehaviorSubject<boolean>(true);
  areRetired$ = this.areRetiredSubject.asObservable();
  isValid$ = this.isValidSubject.asObservable().pipe(distinctUntilChanged());
  tourStatus$: Observable<TourStatus | undefined>;
  highestVisitedStatus$: Observable<TourStatus | undefined>;
  tourStatus?: TourStatus;
  highestVisitedStatus?: TourStatus;
  areMembersRetired = false;
  clientId?: string;
  householdId?: string;

  setValid(isValid: boolean): void {
    this.isValidSubject.next(isValid);
  }

  constructor(
    private readonly router: Router,
    private readonly tourStatusGQL: TourStatusClientGQL,
    private readonly updateTourStatusGQL: UpdateTourStatusGQL,
    private readonly userService: UserService,
    private readonly graphql: GraphQLService,
    private readonly householdService: HouseholdService,
    private readonly memberRetirementMapper: MemberRetirementMapper
  ) {
    this.tourStatus$ = this.tourStatusSubject.asObservable();
    this.tourStatusSubject.subscribe((tourStatus) => {
      this.tourStatus = tourStatus;
      if (
        this.tourStatus != null &&
        (this.highestVisitedStatus == null ||
          this.tourStatus > this.highestVisitedStatus ||
          this.tourStatus < 0)
      ) {
        this.highestVisitedStatusSubject.next(this.tourStatus);
      }
    });
    this.highestVisitedStatus$ =
      this.highestVisitedStatusSubject.asObservable();
    this.highestVisitedStatusSubject.subscribe(
      (tourStatus) => (this.highestVisitedStatus = tourStatus)
    );
  }

  private parseTourUrlFromStatus(
    tour: TourStatus,
    clientId: string,
    householdId: string
  ): UrlTree {
    const urlPath: string[] = [];
    if (tour === TourStatus.Airboard) {
      TOUR_REDIRECT[tour].forEach((pathSegment) => urlPath.push(pathSegment));
      urlPath.push(householdId);
    } else {
      urlPath.push("tour", clientId);
      TOUR_REDIRECT[tour].forEach((pathSegment) => urlPath.push(pathSegment));
    }
    return this.router.createUrlTree(urlPath);
  }

  createUrlTreeFromStatus(tour: TourStatus): Observable<UrlTree> {
    if (this.clientId == null) {
      throw new Error("Error in TourNavigationService: No clientId available.");
    }
    if (this.householdId == null) {
      /**
       * fetchTourStatus is only executed for side-effect of getting householdId
       */
      return this.fetchTourStatus(this.clientId).pipe(
        switchMap(() => {
          return of(
            this.parseTourUrlFromStatus(tour, this.clientId!, this.householdId!)
          );
        })
      );
    }
    return of(
      this.parseTourUrlFromStatus(tour, this.clientId, this.householdId)
    );
  }

  async goTo(tour: TourStatus, replaceUrl = false): Promise<boolean> {
    const url = await this.createUrlTreeFromStatus(tour).toPromise();

    await this.updateBackendTourStatus(tour, this.clientId!)
      .pipe(first())
      .toPromise();

    const navigationResult = await this.router.navigateByUrl(url, {
      replaceUrl,
    });
    if (navigationResult) {
      this.tourStatusSubject.next(tour);
    }
    return navigationResult;
  }

  async next(): Promise<boolean> {
    if (this.tourStatus == null || this.clientId == null) {
      return this.router.navigate(["error"]);
    }
    this.updateMembersRetired(
      await this.householdService
        .areMembersRetiredFromClient(this.clientId)
        .toPromise()
    );
    if (this.tourStatus === TourStatus.Airboard) {
      return Promise.resolve(false);
    } else if (
      this.areMembersRetired &&
      [
        TourStatus.LifestyleToday,
        TourStatus.LifestylePension,
        TourStatus.LifestyleInvalid,
      ].includes(this.tourStatus)
    ) {
      this.tourStatus = TourStatus.WishesPlans;
      return this.goTo(this.tourStatus);
    }
    this.tourStatus += 1;
    if (!isTourStatus(this.tourStatus)) {
      this.tourStatus = -1;
    }
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    return this.goTo(this.tourStatus);
  }

  async previous(): Promise<boolean> {
    if (this.tourStatus == null || this.clientId == null) {
      return this.router.navigate(["error"]);
    }

    if (this.areMembersRetired && this.tourStatus === TourStatus.WishesPlans) {
      this.tourStatus = TourStatus.LifestyleToday;
      return this.goTo(this.tourStatus);
    }

    this.tourStatus = Math.max(0, this.tourStatus - 1);
    if (!isTourStatus(this.tourStatus)) {
      this.tourStatus = -1;
    }
    return this.goTo(this.tourStatus);
  }

  async resumeTour(clientId: string): Promise<boolean> {
    let tourStatus = await this.fetchTourStatus(clientId).toPromise();
    this.updateMembersRetired(
      await this.householdService
        .areMembersRetiredFromClient(clientId)
        .toPromise()
    );
    if (
      this.areMembersRetired &&
      [TourStatus.LifestyleInvalid, TourStatus.LifestylePension].includes(
        tourStatus
      )
    ) {
      tourStatus = TourStatus.WishesPlans;
    }
    return this.goTo(tourStatus, true);
  }

  detectCurrentTourStatusByUrl(routerPath: string): void {
    const currentPath = routerPath.split("/");
    if (currentPath.length <= 3) {
      return;
    }
    let detectedTourStatus: TourStatus | null = null;
    for (const [tourStatusKey, tourPathSegments] of Object.entries(
      TOUR_REDIRECT
    )) {
      if (
        this.comparePathSegments(
          this.router.createUrlTree(currentPath.slice(3)),
          this.router.createUrlTree(tourPathSegments)
        )
      ) {
        detectedTourStatus = Number(tourStatusKey) as TourStatus;
      }
    }
    if (detectedTourStatus == null) {
      this.router.navigate(["error"]);
      throw new Error("Failed to identify current tour status");
    }
    this.tourStatusSubject.next(detectedTourStatus);
  }

  isTourFinished(clientId: string): Observable<boolean> {
    return this.fetchTourStatus(clientId).pipe(
      map((tourStatus) => tourStatus === TourStatus.Airboard)
    );
  }

  fetchTourStatus(clientId: string): Observable<TourStatus> {
    this.clientId = clientId;

    const tourStatus = combineLatest([
      this.userService.getHighestRole().pipe(first()),
      this.tourStatusGQL.fetch({ clientId }),
      this.householdService.areMembersRetiredFromClient(clientId),
    ]).pipe(
      map(([role, tourStatusRes, areMembersRetired]) => ({
        backendTourStatus: tourStatusRes.data.client.tourStatus,
        householdId:
          role === ROLES.CLIENT
            ? tourStatusRes.data.client.clientHousehold.id
            : tourStatusRes.data.client.companionHousehold.id,
        areMembersRetired,
      }))
    );

    tourStatus.subscribe(
      (status) => {
        this.highestVisitedStatusSubject.next(status.backendTourStatus);
        if (this.tourStatus == null) {
          this.tourStatusSubject.next(status.backendTourStatus);
        }
        this.householdId = status.householdId;
        // TODO: This helper shouldn't be used in here, but it's to avoid complicating the code in here
        this.updateMembersRetired(status.areMembersRetired);
      },
      (error) => this.graphql.handleInvalidClientError(error)
    );
    return tourStatus.pipe(
      map((status) => status.backendTourStatus),
      filter((status) => isTourStatus(status))
    );
  }

  private comparePathSegments(
    currentPath: UrlTree,
    comparedPath: UrlTree
  ): boolean {
    const pathArray = currentPath.toString().split("/");
    if (pathArray.length > 3) {
      pathArray.pop();
    }
    return pathArray.join("/").startsWith(comparedPath.toString());
  }

  private updateBackendTourStatus(
    newTourStatus: TourStatus,
    clientId: string
  ): Observable<boolean> {
    if (
      this.highestVisitedStatus == null ||
      (newTourStatus <= this.highestVisitedStatus &&
        newTourStatus !== TourStatus.Airboard)
    ) {
      return of(false);
    }

    const isAirboard = newTourStatus === TourStatus.Airboard;
    // The mutation has to be slightly delayed if we arrive on the airboard
    // to avoid some race conditions on the backend (see AIR-1801). This is
    // a temporary fix.

    const update$ = this.updateTourStatusGQL.mutate({
      clientId,
      tourStatus: newTourStatus,
    });
    return of(undefined).pipe(
      delay(isAirboard ? 500 : 0),
      switchMap(() => update$),
      tap((updateResponse) => {
        // TODO: Handle null tourStatus as error in AIR-326
        const updatedTourStatus =
          updateResponse.data?.updateTourStatusInClient.tourStatus;
        // TODO: The mapper should not be used here
        this.updateMembersRetired(
          this.memberRetirementMapper.toRawType(
            updateResponse.data?.updateTourStatusInClient.companionHouseholdV2?.members.filter(
              isNotNullish
            )
          )
        );
        if (updatedTourStatus != null) {
          this.tourStatusSubject.next(updatedTourStatus);
        }
        return of(true);
      }),
      catchError((err) => this.graphql.handleUnexpectedError(err))
    );
  }

  private updateMembersRetired(areRetired: boolean): void {
    this.areMembersRetired = areRetired;
    this.areRetiredSubject.next(this.areMembersRetired);
  }
}
