import { Amount } from "../common";

export enum RiskTolerances {
  Unknown = "UNKNOWN",
  VeryConservative = "VERY_CONSERVATIVE",
  Conservative = "CONSERVATIVE",
  Balanced = "BALANCED",
  GrowthOriented = "GROWTH_ORIENTED",
  RevenueOriented = "REVENUE_ORIENTED",
}

export type Risk = {
  householdId: string;
  riskTolerance: RiskTolerances;
  riskBuffer: Amount;
};
