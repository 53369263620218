import {
  AirboardConfigurationService,
  SettingsService,
} from "@air-gmbh/data-access/configuration";
import { RecalculateService } from "@air-gmbh/data-access/recalculate";
import { ToggleState } from "@air-gmbh/ui/toggle";
import { MissingIdError } from "@air-gmbh/util/error";
import { ParamsService } from "@air-gmbh/util/general";
import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { Subscription } from "rxjs";
import { switchMap } from "rxjs/operators";
import { DropdownElement } from "../../shared/components/button-dropdown/dropdown-element";
import { MenuDropdownItem } from "../menu-dropdown/menu-dropdown.type";

@Component({ template: "", providers: [ParamsService] })
export class ToolsComponent implements OnInit, OnDestroy {
  @ViewChild("inflation", { static: true }) inflation?: TemplateRef<any>;
  @ViewChild("inventory", { static: true }) inventory?: TemplateRef<any>;
  @ViewChild("retirement", { static: true }) retirement?: TemplateRef<any>;
  @ViewChild("recalculate", { static: true }) recalculate?: TemplateRef<any>;
  @ViewChild("consultancyDocumentation", { static: true })
  consultancyDocumentation?: TemplateRef<any>;

  items: MenuDropdownItem[] = [];
  householdId?: string;

  // TODO: Remove one the SettingsDropdownComponent is replaced by the sidebar menu
  open = false;
  elements: DropdownElement[] = [];

  private inflationToggle = false;
  private retirementToggle = true;
  private inventoryToggle = false;

  private sub = new Subscription();

  get inflationToggleState(): ToggleState {
    return ToggleState.fromBoolean(this.inflationToggle);
  }

  get inventoryToggleState(): ToggleState {
    return ToggleState.fromBoolean(this.inventoryToggle);
  }

  get retirementToggleState(): ToggleState {
    return ToggleState.fromBoolean(this.retirementToggle);
  }

  constructor(
    protected readonly paramsService: ParamsService,
    protected readonly airboardConfiguration: AirboardConfigurationService,
    protected readonly recalculateService: RecalculateService,
    protected readonly settingsService: SettingsService
  ) {}

  ngOnInit(): void {
    this.sub.add(
      this.airboardConfiguration.isComparing$.subscribe(
        (isComparingEnabled) => {
          this.inventoryToggle = isComparingEnabled;
        }
      )
    );
    this.sub.add(
      this.paramsService
        .getParam("householdId")
        .pipe(
          switchMap((householdId) => {
            this.householdId = householdId;
            return this.settingsService.getSettings(householdId);
          })
        )
        .subscribe(
          (settings) => {
            this.inflationToggle = settings.considerInflation;
            this.retirementToggle = settings.considerRetirement;
            this.items = [
              {
                template: this.inflation,
                show: this.inflation != null,
                handler: this.inflationToggleStateChange.bind(this),
              },
              {
                template: this.inventory,
                show: this.inventory != null,
                handler: this.inventoryToggleStateChange.bind(this),
              },
              {
                template: this.retirement,
                show: this.retirement != null && !settings.areMembersRetired,
                handler: this.retirementToggleStateChange.bind(this),
              },
              {
                text: "action.forceRecalculation",
                icon: "rotate-exclamation",
                show: true,
                handler: this.forceRecalculation.bind(this),
              },
              {
                text: "action.consultancyDocumentation",
                icon: "print",
                show: true,
                handler: this.openConsultancyDocumentation.bind(this),
              },
            ];
            this.elements = [
              {
                click: this.inflationToggleStateChange.bind(this),
                show: this.inflation != null,
                textColor: "text-black",
                title: this.inflation ?? "",
              },
              {
                click: this.inventoryToggleStateChange.bind(this),
                show: this.inventory != null,
                textColor: "text-black",
                title: this.inventory ?? "",
              },
              {
                click: this.retirementToggleStateChange.bind(this),
                show: this.retirement != null && !settings.areMembersRetired,
                textColor: "text-black",
                title: this.retirement ?? "",
              },
              {
                click: this.forceRecalculation.bind(this),
                show: this.recalculate != null,
                textColor: "text-black",
                title: this.recalculate ?? "",
              },
              {
                click: this.openConsultancyDocumentation.bind(this),
                show: this.consultancyDocumentation != null,
                textColor: "text-black",
                title: this.consultancyDocumentation ?? "",
              },
            ];
          },
          () => {
            // We don't want to throw an error if this fails, as this will just
            // be hidden then
          }
        )
    );
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  closeSettingsMenu(): void {
    this.open = false;
  }

  private inflationToggleStateChange(): void {
    if (this.householdId == null) {
      throw new MissingIdError(ToolsComponent, "householdId");
    }
    this.inflationToggle = !this.inflationToggle;
    this.sub.add(
      this.settingsService
        .toggleInflation(this.householdId, this.inflationToggle)
        .subscribe()
    );
  }

  private inventoryToggleStateChange(): void {
    this.airboardConfiguration.toggleComparing();
  }

  private retirementToggleStateChange(): void {
    if (this.householdId == null) {
      throw new MissingIdError(ToolsComponent, "householdId");
    }
    this.retirementToggle = !this.retirementToggle;
    this.sub.add(
      this.settingsService
        .toggleRetirement(this.householdId, this.retirementToggle)
        .subscribe()
    );
  }

  private forceRecalculation(): void {
    if (this.householdId == undefined) {
      throw new MissingIdError(ToolsComponent, "householdId");
    }
    this.recalculateService.recalculate(this.householdId);
    this.closeSettingsMenu();
  }

  private openConsultancyDocumentation(): void {
    if (this.householdId == undefined) {
      throw new MissingIdError(ToolsComponent, "householdId");
    }
    window.open(`consultancy-documentation/${this.householdId}`, "_blank");
    this.closeSettingsMenu();
  }
}
