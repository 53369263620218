import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AbsoluteNumberPipe } from "./absolute-number.pipe";
import { CentsPipe } from "./cents.pipe";
import { CleanPhoneNumberPipe } from "./clean-phone-number.pipe";
import { CurrencyUnitsPipe } from "./currency-units.pipe";
import { EurosPipe } from "./euros.pipe";
import { RoundCurrencyPipe } from "./round-currency.pipe";

@NgModule({
  declarations: [
    AbsoluteNumberPipe,
    CentsPipe,
    EurosPipe,
    RoundCurrencyPipe,
    CleanPhoneNumberPipe,
    CurrencyUnitsPipe,
  ],
  imports: [CommonModule],
  exports: [
    AbsoluteNumberPipe,
    CentsPipe,
    EurosPipe,
    RoundCurrencyPipe,
    CleanPhoneNumberPipe,
    CurrencyUnitsPipe,
  ],
})
export class PipesModule {}
