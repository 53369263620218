import { LoginResponse, UserDataResult } from "angular-auth-oidc-client";

export interface UserData {
  realm_roles: string[];
  sub: string;
  preferred_username?: string;
  airId: string;
}

export type AirLoginResponse = Omit<LoginResponse, "userData"> & {
  // This is required to write like this, as it doesn't match with `LoginResponse`
  // if it written with the `?` shorthand
  userData: UserData | undefined;
};

export type AirUserDataResult = Omit<UserDataResult, "userData"> & {
  userData: UserData;
};

export const mockUserData: UserData = {
  realm_roles: ["admin", "companion", "client"],
  sub: "mockref",
  preferred_username: "mock@example.com",
  airId: "mockId",
};
