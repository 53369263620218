import { MemberTypes as MemberTypesGQL } from "@air-gmbh/data-access/graphql";
import { AirError } from "@air-gmbh/util/error";
import { Member, MemberTypes } from "@air-gmbh/util/types";
import { Injectable } from "@angular/core";
import { isNotNullish } from "@tool-belt/type-predicates";
import { Nullable } from "ts-toolbelt/out/Union/Nullable";
import { TypeMapper } from "../common/mapper.interface";
import { ProfileGQL, ProfileMapper } from "../common/profile.mapper";
import { GrossPensionGQL } from "../member";
import { EmploymentGraphQL, EmploymentMapper } from "./employment";
import { GrossIncomeGL, GrossIncomeMapper } from "./gross-income.mapper";
import { GrossPensionMapper } from "./pension.mapper";

export interface MemberGraphQL {
  id: string;
  type: MemberTypesGQL;
  isInPension: boolean;
  paysChurchTax?: boolean;
  pensionEntryAge?: number;
  hasPublicHealthInsurance?: boolean;
  privateHealthCosts?: number;
  profileV2?: Nullable<ProfileGQL>;
  employmentsV2?: Nullable<EmploymentGraphQL>[];
  employmentsV3?: Nullable<EmploymentGraphQL>[];
  grossIncomesV2?: Nullable<GrossIncomeGL>[];
  grossPensionsV2?: Nullable<GrossPensionGQL>[];
  creditRate?: number;
}

@Injectable()
export class MemberMapper implements TypeMapper<MemberGraphQL, Member> {
  constructor(
    private readonly profileMapper: ProfileMapper,
    private readonly employmentMapper: EmploymentMapper,
    private readonly grossIncomeMapper: GrossIncomeMapper,
    private readonly pensionMapper: GrossPensionMapper
  ) {}

  toRawType(graphQLType: MemberGraphQL): Member {
    const result: Member = {
      id: graphQLType.id,
      type: MemberTypes[graphQLType.type],
      paysChurchTax: graphQLType.paysChurchTax,
      pensionEntryAge: graphQLType.pensionEntryAge,
      hasPublicHealthInsurance: graphQLType.hasPublicHealthInsurance,
      privateHealthCosts: graphQLType.privateHealthCosts,
      creditRate: graphQLType.creditRate,
      isMemberRetired: graphQLType.isInPension,
    };

    if (graphQLType.profileV2 != null) {
      result.profile = this.profileMapper.toRawType(graphQLType.profileV2);
    }

    if (graphQLType.employmentsV2 != null) {
      result.employments = graphQLType.employmentsV2
        .filter(isNotNullish)
        .map((emp) => this.employmentMapper.toRawType(emp));
    }

    if (graphQLType.employmentsV3) {
      if (result?.employments != null) {
        throw new AirError(
          MemberMapper,
          "Can't map response if employmentsV2 and V3 is used"
        );
      }
      result.employments = graphQLType.employmentsV3
        .filter(isNotNullish)
        .map(this.employmentMapper.toRawType);
    }

    if (graphQLType.grossIncomesV2 != null) {
      result.grossIncomes = graphQLType.grossIncomesV2
        .filter(isNotNullish)
        .map((income) => this.grossIncomeMapper.toRawType(income));
    }

    if (graphQLType.grossPensionsV2 != null) {
      result.grossPensions = graphQLType.grossPensionsV2
        .filter(isNotNullish)
        .map((pension) => this.pensionMapper.toRawType(pension));
    }

    return result;
  }
}
