import { Member } from ".";

export interface GrossIncome {
  id: string;
  type: GrossIncomeType;
  yearlyAmount: number;
  monthlyAmount: number;
  member?: Member;
}

export enum GrossIncomeType {
  UNKNOWN = "UNKNOWN",
  AGRICULTURE_AND_FORESTRY = "AGRICULTURE_AND_FORESTRY",
  BUSINESS_OPERATIONS = "BUSINESS_OPERATIONS",
  SELF_EMPLOYED_WORK = "SELF_EMPLOYED_WORK",
  EMPLOYED_WORK = "EMPLOYED_WORK",
  CAPITAL = "CAPITAL",
  LETTING_PROPERTY = "LETTING_PROPERTY",
  MISC_ACTIVE = "MISC_ACTIVE",
  MISC_PASSIVE = "MISC_PASSIVE",
  TAX_FREE = "TAX_FREE",
}
