export type Severity = "info" | "warning" | "error" | "success";

export class Alert {
  message: string;
  severity: Severity;
  show: boolean;

  constructor(
    message: string = "",
    severity: Severity = "info",
    show: boolean = false
  ) {
    this.message = message;
    this.severity = severity;
    this.show = show;
  }
}
