import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { UiIconModule } from "@air-gmbh/ui/icon";
import { StepBarComponent } from "./step-bar.component";
import { StepComponent } from "./step/step.component";

@NgModule({
  declarations: [StepBarComponent, StepComponent],
  imports: [CommonModule, UiIconModule],
  exports: [StepBarComponent],
})
export class StepBarModule {}
