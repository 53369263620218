<div
  *ngIf="!collapsed; else collapsedTpl"
  class="flex w-28 flex-col items-center gap-4"
>
  <div
    class="step-icon-wrapper"
    [ngClass]="{
      active: active,
      visited: !active && visited
    }"
  >
    <air-icon
      class="step-icon"
      [ngClass]="{
        active: active,
        visited: !active && visited
      }"
      [faClasses]="step?.icon"
      size="2xl"
    >
    </air-icon>
  </div>
  <label
    class="step-label w-28 text-center"
    [ngClass]="{
      active: active,
      visited: !active && visited
    }"
  >
    {{ step?.name }}
  </label>
</div>

<ng-template #collapsedTpl>
  <div class="flex flex-row items-center gap-4">
    <div class="step-icon-wrapper">
      <air-icon class="step-icon" [faClasses]="step?.icon" size="2xl">
      </air-icon>
    </div>
    <label class="step-label w-28 text-center">
      {{ step?.name }}
    </label>
  </div>
</ng-template>
