import { UserService } from "@air-gmbh/data-access/auth";
import { AirboardConfigurationService } from "@air-gmbh/data-access/configuration";
import { ENVIRONMENT_CONFIG, IEnvironment } from "@air-gmbh/util/environment";
import { PrintService } from "@air-gmbh/util/general";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AccordionService } from "../components/accordion/accordion.service";

@Injectable({
  providedIn: "root",
})
export class PrintEditPageService {
  constructor(
    @Inject(ENVIRONMENT_CONFIG) private readonly environment: IEnvironment,
    private readonly airboardConfiguration: AirboardConfigurationService,
    private readonly userService: UserService,
    private readonly accordionService: AccordionService,
    private readonly printService: PrintService
  ) {}

  print(): void {
    this.airboardConfiguration.setComparingTo(true);
    this.accordionService.expandAll();
    const delay = this.environment.printDelay;
    setTimeout(() => {
      this.adjustTextareaHeights();
      setTimeout(() => {
        this.printService.print();
      }, delay);
    }, delay);
  }

  showPrintButton(): Observable<boolean> {
    return this.userService.getRoles().pipe(map((roles) => roles.isCompanion));
  }

  private adjustTextareaHeights(): void {
    const collection: HTMLCollectionOf<HTMLTextAreaElement> =
      document.getElementsByTagName("textarea");
    const textareaCount = collection.length;

    for (let i = 0; i < textareaCount; i++) {
      const textarea: HTMLTextAreaElement | null = collection.item(i);

      if (textarea !== null) {
        textarea.style.height = "1px";
        textarea.style.height = (textarea.scrollHeight + 16).toString() + "px";
      }
    }
  }
}
