import { Directive, OnDestroy, PipeTransform } from "@angular/core";
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormBuilder,
  UntypedFormControl,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { Subscription } from "rxjs";

@Directive()
export abstract class AbstractInput
  implements OnDestroy, ControlValueAccessor, Validators
{
  value: UntypedFormControl = this.fb.control(null);
  errors: ValidationErrors | null = null;

  protected sub = new Subscription();

  /**
   * This array is a collection of pipes that will be applied if programmatic
   * changes from model to view are requested.
   *
   * The pipes will be applied in order of position in the array.
   */
  protected writePipes: PipeTransform[] = [];

  /**
   * This array is a collection of pipes that will be applied if the control
   * value is changed over the UI.
   *
   * The pipes will be applied in order of position in the array.
   */
  protected onChangePipes: PipeTransform[] = [];
  private validationRunning = false;

  constructor(
    protected readonly fb: UntypedFormBuilder,
    protected readonly customValidators: ValidatorFn[] = []
  ) {}

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  /**
   * Allows to hook custom validators into the validation process by
   * setting the additional validators as return of the function.
   *
   * This function is executed during `registerOnChange()` of the
   * `ControlValueAccessor` api. If you overwrite this with your own logic,
   * keep in mind that you have to apply your validators by yourself.
   *
   * ### Example
   * ```typescript
   * export class class YourInputComponent extends AbstractInput {
   *
   *  protected getCustomValidators(): ValidatorFn[] {
   *   return [Validators.maxLength(5)];
   *  }
   *
   * }
   * ```
   */
  protected getCustomValidators?(): ValidatorFn[];

  private transformWithPipes(value: any, pipes: PipeTransform[]): any {
    if (pipes.length === 0) {
      return value;
    }
    let transformedValue = value;
    pipes.forEach((pipe) => {
      transformedValue = pipe.transform(transformedValue);
    });
    return transformedValue;
  }

  writeValue(value: string | number): void {
    this.value.setValue(this.transformWithPipes(value, this.writePipes), {
      emitEvent: false,
    });
  }

  registerOnChange(onChange: (value: any) => void): void {
    this.value = this.fb.control({
      value: this.value.value,
      disabled: this.value.disabled,
    });
    if (this.getCustomValidators) {
      this.value.setValidators(this.getCustomValidators());
    }
    const sub = this.value.valueChanges.subscribe((v) => {
      onChange(this.transformWithPipes(v, this.onChangePipes));
    });
    this.sub.add(sub);
  }

  onTouched = (): void => {
    // Implementation by NG_VALUE_ACCESSOR and called through froms API
  };

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  setDisabledState(disabled: boolean): void {
    if (disabled) {
      this.value.disable();
    } else {
      this.value.enable();
    }
  }

  /**
   * Returns own and parent's validation errors.
   * As the parent's control is passed to this method, we use it to validate its validators
   * as well as this components validators, as they are part of the parents validators.
   * Exits after single recursive call.
   * @param control Parent form group
   * @returns errors from the form control
   */
  validate(control: AbstractControl): ValidationErrors | null {
    if (!this.validationRunning) {
      let errors: ValidationErrors | null = null;
      if (control.validator != null) {
        this.validationRunning = true;
        errors = control.validator(control);
        this.validationRunning = false;
      }
      if (this.value.errors != null) {
        errors =
          errors != null
            ? { ...errors, ...this.value.errors }
            : this.value.errors;
      }
      this.errors = errors;
    }
    return this.value.errors;
  }
}
