import { Alert, Severity } from "@air-gmbh/ui/alert";
import { ENVIRONMENT_CONFIG, IEnvironment } from "@air-gmbh/util/environment";
import { Inject, Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable()
export class AlertService {
  constructor(
    @Inject(ENVIRONMENT_CONFIG) private readonly environment: IEnvironment
  ) {}

  private readonly state: BehaviorSubject<Alert> = new BehaviorSubject(
    new Alert()
  );

  getState(): Observable<Alert> {
    return this.state;
  }

  showInfo(message: string, displayTime?: number): void {
    this.show(message, "info", displayTime);
  }

  showWarning(message: string, displayTime?: number): void {
    this.show(message, "warning", displayTime);
  }

  showError(message: string, displayTime?: number): void {
    this.show(message, "error", displayTime);
  }

  showSuccess(message: string, displayTime?: number): void {
    this.show(message, "success", displayTime);
  }

  private show(
    message: string,
    severity: Severity,
    displayTime = this.environment.alertTime
  ): void {
    this.state.next(new Alert(message, severity, true));
    setTimeout(() => this.hide(), displayTime);
  }

  hide(): void {
    this.state.next(new Alert());
  }
}
