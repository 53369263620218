import { AuthService, UserService } from "@air-gmbh/data-access/auth";
import { OptInService } from "@air-gmbh/data-access/data-protection";
import { HouseholdService } from "@air-gmbh/data-access/household";
import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { UserAccountComponent } from "../../../../user-menu/user-menu-dropdown/user-account.component";
import { ProfileService } from "../../../services/profile/profile-service";
import { TourNavigationService } from "../../../services/tour-navigation.service";

/**
 * @deprecated
 */
@Component({
  selector: "app-account-dropdown",
  templateUrl: "./account-dropdown.component.html",
  styleUrls: ["account-dropdown.component.css"],
})
export class AccountDropdownComponent extends UserAccountComponent {
  constructor(
    protected override readonly router: Router,
    protected override readonly authService: AuthService,
    protected override readonly userService: UserService,
    protected override readonly tourNavService: TourNavigationService,
    protected override readonly profileService: ProfileService,
    protected override readonly optInService: OptInService,
    protected override readonly householdService: HouseholdService,
    protected override readonly translate: TranslateService
  ) {
    super(
      router,
      authService,
      userService,
      tourNavService,
      profileService,
      optInService,
      householdService,
      translate
    );
  }
}
