import { Component, Input } from "@angular/core";
import { SpinnerColor, SpinnerSize } from "./spinner.type";

@Component({
  selector: "air-spinner",
  templateUrl: "./spinner.component.html",
  styleUrls: ["./spinner.component.css"],
})
export class SpinnerComponent {
  @Input() size: SpinnerSize = SpinnerSize.MEDIUM;
  @Input() color: SpinnerColor = SpinnerColor.BLACK;

  get classes(): string {
    return `${this.size} text-${this.color}`;
  }
}
