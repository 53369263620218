import { Injectable, OnDestroy } from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";
import { Observable, Subscription } from "rxjs";
import { map } from "rxjs/operators";

/**
 * This service should make it easier to work with the Params object provided by
 * Angular.
 *
 * **In order for this to work, you need to provide it inside the component**,
 * as this is provided `ActivatedRoute` which is different for every component.
 *
 * @example
 * ```ts
 * @Component({
 *  ...,
 *  providers: [ParamsService],
 * })
 * class TestComponent {
 *   constructor(
 *     private paramsService: ParamsService
 *   ) {}
 * }
 * ```
 */
@Injectable()
export class ParamsService implements OnDestroy {
  private params?: Params;
  private sub = new Subscription();

  constructor(public route: ActivatedRoute) {
    this.sub.add(route.params.subscribe((params) => (this.params = params)));
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  hasParamSnapshot(paramName: string): boolean {
    return this.paramExists(this.params, paramName);
  }

  hasParam(paramName: string): Observable<boolean> {
    return this.route.params.pipe(
      map((params) => this.paramExists(params, paramName))
    );
  }

  getParamSnapshot(paramName: string): string | undefined {
    return this.params?.[paramName];
  }

  /**
   * Safely get a parameter from the currently `ActivatedRoute`.
   *
   * @remark
   * This uses the generic in order to prevent future problems when activating
   * `noUncheckedIndexAccess` in our tsconfig.json
   *
   * @param paramName The name to retrieve from the params
   * @returns An Observable which either returns the content of the param or
   * throws an error if that doesn't exist
   */
  getParam<T extends string>(paramName: T): Observable<string> {
    return this.route.params.pipe(
      map((params) => {
        if (!this.paramExists(params, paramName)) {
          throw new Error(
            `${paramName} doesn't exist on currently activated route`
          );
        }
        return params[paramName];
      })
    );
  }

  private paramExists<T extends string>(
    params: Params | undefined,
    paramName: T
  ): params is { [key in T]: string } {
    return params?.[paramName] != null;
  }
}
