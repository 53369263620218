import {
  GrossPensionTypes as GrossPensionTypeGQL,
  LoadingAmount as LoadingAmountGQL,
} from "@air-gmbh/data-access/graphql";
import { GrossPension, GrossPensionType } from "@air-gmbh/util/types";
import { Injectable } from "@angular/core";
import { TypeMapper } from "../common/mapper.interface";

export interface GrossPensionGQL {
  id: string;
  costs: LoadingAmountGQL;
  grossPensionType: GrossPensionTypeGQL;
  calculateAmount: boolean;
}

@Injectable()
export class GrossPensionMapper
  implements TypeMapper<GrossPensionGQL, GrossPension>
{
  static pensionTypeToRawType(
    pensionType: GrossPensionTypeGQL
  ): GrossPensionType {
    return {
      [GrossPensionTypeGQL.Unknown]: GrossPensionType.UNKNOWN,
      [GrossPensionTypeGQL.State]: GrossPensionType.STATE,
      [GrossPensionTypeGQL.SupplementaryFund]:
        GrossPensionType.SUPPLEMENTARY_FUND,
      [GrossPensionTypeGQL.CivilServantProvision]:
        GrossPensionType.CIVIL_SERVANT_PROVISION,
    }[pensionType];
  }

  toRawType(graphQLType: GrossPensionGQL): GrossPension {
    return {
      id: graphQLType.id,
      costs: {
        amount: graphQLType.costs.amount,
        isRecalculating: graphQLType.costs.isRecalculating,
      },
      isUserEntered: !graphQLType.calculateAmount,
      grossPensionType: GrossPensionMapper.pensionTypeToRawType(
        graphQLType.grossPensionType
      ),
    };
  }
}
