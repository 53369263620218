import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { BreadcrumbModule } from "xng-breadcrumb";
import { FooterModule } from "../components/footer/footer.module";
import { HeaderModule } from "../components/header/header.module";
import { StepBarModule } from "../components/step-bar/step-bar.module";
import { SharedModule } from "../shared.module";
import { CompanionLayoutComponent } from "./companion-layout/companion-layout.component";
import { FullScreenLayoutComponent } from "./full-screen-layout/full-screen-layout.component";
import { TourLayoutComponent } from "./tour-layout/tour-layout.component";

@NgModule({
  declarations: [
    CompanionLayoutComponent,
    TourLayoutComponent,
    FullScreenLayoutComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    HeaderModule,
    BreadcrumbModule,
    StepBarModule,
    FooterModule,
  ],
})
export class LayoutsModule {}
