import { DatePipe } from "@angular/common";
import {
  Component,
  Inject,
  InjectionToken,
  Input,
  OnInit,
} from "@angular/core";
import {
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormBuilder,
} from "@angular/forms";
import { CustomInputComponent } from "../custom-input/custom-input.component";

export type CalendarTranslationPaths = {
  month: string[];
  monthShort: string[];
  weekDay: string[];
};

export const CALENDAR_TRANSLATION_PATHS =
  new InjectionToken<CalendarTranslationPaths>("CALENDAR_TRANSLATION_PATHS");

@Component({
  selector: "air-date-input",
  templateUrl: "./date-input.component.html",
  styleUrls: ["./date-input.component.css"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: DateInputComponent,
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: DateInputComponent,
      multi: true,
    },
  ],
})
export class DateInputComponent extends CustomInputComponent implements OnInit {
  @Input() override placeholder = "";

  get MONTH_FULL_NAMES(): string[] {
    return this.translations.month;
  }

  get MONTH_SHORT_NAMES(): string[] {
    return this.translations.monthShort;
  }

  get DAYS(): string[] {
    return this.translations.weekDay;
  }

  showDateInput = false;
  showMonthInput = false;

  private internalValue = new Date();
  month = 0;
  year = 1970;
  day = 1;

  numberOfDays: number[] = [];
  blankdays: number[] = [];

  constructor(
    private readonly datePipe: DatePipe,
    @Inject(CALENDAR_TRANSLATION_PATHS)
    private readonly translations: CalendarTranslationPaths,
    fb: UntypedFormBuilder
  ) {
    super(fb);
    this.value = this.fb.control("");
  }

  ngOnInit(): void {
    this.setValues(this.value.value);
  }

  // Overwrite to set internal and input value in correct format
  override writeValue(value: string): void {
    this.setValues(value);
  }

  // Overwrite to set internal value on change
  override registerOnChange(onChange: (value: string) => void): void {
    this.value = this.fb.control({
      value: this.value.value,
      disabled: this.value.disabled,
    });
    this.sub.add(
      this.value.valueChanges.subscribe((v) => {
        if (isNaN(Date.parse(v))) {
          this.setInternalValue(null);
        } else {
          this.setInternalValue(new Date(Date.parse(v)));
        }
        onChange(v);
      })
    );
  }

  private setValues(value: string): void {
    const parsed = Date.parse(value);
    if (!isNaN(parsed)) {
      const date = new Date(parsed);
      this.value.setValue(this.datePipe.transform(date, "yyyy-MM-dd"));
      this.setInternalValue(date);
    } else {
      this.value.setValue("");
      this.setInternalValue(new Date());
    }
  }

  private setInternalValue(date: Date | null): void {
    this.internalValue = date ?? new Date();
    this.month = this.internalValue.getMonth();
    this.year = this.internalValue.getFullYear();
    this.day = this.internalValue.getDate();
    this.getDaysOfMonth();
  }

  isSelectedDay(date: number): boolean {
    const d = new Date(this.year, this.month, date);
    return this.internalValue.toDateString() === d.toDateString();
  }

  isSelectedMonth(month: number): boolean {
    return (
      this.internalValue.getFullYear() === this.year &&
      this.internalValue.getMonth() === month
    );
  }

  selectDay(day: number): void {
    this.day = day;
    this.internalValue = new Date(this.year, this.month, this.day);
    this.value.setValue(
      this.datePipe.transform(this.internalValue, "yyyy-MM-dd") as string,
      {
        emitEvent: true,
      }
    );
    this.hideDateInput();
  }

  selectMonth(month: number): void {
    this.month = month;
    this.getDaysOfMonth();
    this.showDateInput = true;
    this.showMonthInput = false;
  }

  previousMonth(): void {
    if (this.month === 0) {
      this.month = 11;
      this.year--;
    } else {
      this.month--;
    }
    this.getDaysOfMonth();
  }

  nextMonth(): void {
    if (this.month === 11) {
      this.month = 0;
      this.year++;
    } else {
      this.month++;
    }
    this.getDaysOfMonth();
  }

  previousYear(): void {
    this.year--;
  }

  nextYear(): void {
    this.year++;
  }

  toggleDateInput(): void {
    if (this.value.status !== "DISABLED") {
      this.showDateInput = !(this.showDateInput || this.showMonthInput);
      this.showMonthInput = false;
    }
  }

  hideDateInput(): void {
    this.showDateInput = false;
    this.showMonthInput = false;
  }

  seeMonthInput(): void {
    this.showDateInput = false;
    this.showMonthInput = true;
  }

  private getDaysOfMonth(): void {
    let daysInMonth: number = new Date(this.year, this.month + 1, 0).getDate();
    let dayOfWeek: number = new Date(this.year, this.month).getDay();
    let blankdaysArray: number[] = [];
    for (let i = 1; i < dayOfWeek; i++) {
      blankdaysArray.push(i);
    }

    let daysArray: number[] = [];
    for (let j = 1; j < daysInMonth; j++) {
      daysArray.push(j);
    }

    this.blankdays = blankdaysArray;
    this.numberOfDays = daysArray;
  }
}
