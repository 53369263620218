import { AirError } from "@air-gmbh/util/error";
import { fromEntries } from "@air-gmbh/util/general";
import {
  HouseholdLifestyle,
  Lifestyle,
  LifestyleTypes,
} from "@air-gmbh/util/types";
import { Injectable } from "@angular/core";
import { isNotNullish } from "@tool-belt/type-predicates";
import { Nullable } from "ts-toolbelt/out/Union/Nullable";
import { TypeMapper } from "../common/mapper.interface";
import {
  MemberEmploymentCategoryGQL,
  MemberRetirementMapper,
} from "../member/member-retirement.mapper";
import { LifestyleMapperHelper } from "./lifestyle-mapper.helper";
import { LifestyleGraphQL, LifestyleMapper } from "./lifestyle.mapper";

export interface LifestyleHouseholdGraphQL {
  id: string;
  lifestyles: Nullable<LifestyleGraphQL>[];
  members?: Nullable<MemberEmploymentCategoryGQL>[];
}
type MappedHouseholdLifestyles = Record<LifestyleTypes, Lifestyle>;

@Injectable()
export class HouseholdLifestyleMapper
  implements TypeMapper<LifestyleHouseholdGraphQL, HouseholdLifestyle>
{
  constructor(
    private readonly lifestyleMapper: LifestyleMapper,
    private readonly memberRetirementMapper: MemberRetirementMapper
  ) {}

  toRawType(graphQLType: LifestyleHouseholdGraphQL): HouseholdLifestyle {
    const lifestyles: Partial<MappedHouseholdLifestyles> = fromEntries<
      LifestyleTypes,
      Lifestyle
    >(
      graphQLType.lifestyles
        .filter(isNotNullish)
        .map((lifestyle) => [
          this.lifestyleMapper.toRawType(lifestyle).type,
          this.lifestyleMapper.toRawType(lifestyle),
        ])
    );

    if (LifestyleMapperHelper.isMappedHouseholdLifestyle(lifestyles)) {
      return {
        householdId: graphQLType.id,
        lifestyles,
        areMembersRetired: this.memberRetirementMapper.toRawType(
          graphQLType.members?.filter(isNotNullish)
        ),
      };
    }

    throw new AirError(
      HouseholdLifestyleMapper,
      "Household lifestyle mapping failed"
    );
  }
}
