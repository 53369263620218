import {
  CreateMemberGQL,
  GetMembersInfoGQL,
  GetMembersInfoQuery,
  MemberTypes as MemberTypesGQL,
  UpdateMemberInfoGQL,
} from "@air-gmbh/data-access/graphql";
import { MemberGraphQL, MemberMapper } from "@air-gmbh/data-access/mappers";
import { AirError, ErrorUtil } from "@air-gmbh/util/error";
import { throwOnNullishOperator } from "@air-gmbh/util/general";
import {
  CreateMemberResponse,
  Member,
  MemberTypes,
  UpdateMemberInfo,
} from "@air-gmbh/util/types";
import { Injectable } from "@angular/core";
import { ApolloQueryResult } from "@apollo/client/core";
import { isNotNullish } from "@tool-belt/type-predicates";
import { Observable, OperatorFunction, pipe, throwError } from "rxjs";
import { catchError, distinctUntilChanged, map } from "rxjs/operators";
import { Nullable } from "ts-toolbelt/out/Union/Nullable";

@Injectable()
export class MemberService {
  constructor(
    private readonly createMemberGQL: CreateMemberGQL,
    private readonly getMembersInfoGQL: GetMembersInfoGQL,
    private readonly updateMembersInfoGQL: UpdateMemberInfoGQL,
    private readonly memberMapper: MemberMapper
  ) {}

  createMember(
    householdId: string,
    isPartner: boolean
  ): Observable<CreateMemberResponse> {
    return this.createMemberGQL
      .mutate({
        input: {
          householdId: householdId,
          memberType: isPartner
            ? MemberTypesGQL.Partner
            : MemberTypesGQL.ContactMember,
        },
      })
      .pipe(
        map((mutationResults) => mutationResults.data?.createMember.member),
        throwOnNullishOperator(
          new AirError(MemberService, "Create member failed")
        ),
        map((createdMember) => {
          return {
            id: createdMember.id,
            isPartner:
              createdMember.type === MemberTypesGQL.Partner ? true : false,
          };
        }),
        catchError((error) => throwError(() => ErrorUtil.transformError(error)))
      );
  }

  getMemberInfo(householdId: string): Observable<Member[]> {
    return this.getMembersInfoGQL
      .fetch({ input: { householdId } })
      .pipe(this.mapMembersInfo());
  }

  /**
   * Watch the apollo cache of the members of a household.
   *
   * @param householdId
   * @param options Gives the opportunity to force an initial fetch. This
   * is sometimes useful, when you don't want to receive potential outdated
   * cache values on subscribe.
   */
  watchMemberInfo(
    householdId: string,
    options?: { fetchInitial: boolean }
  ): Observable<Member[]> {
    return this.getMembersInfoGQL
      .watch(
        { input: { householdId } },
        { fetchPolicy: options?.fetchInitial ? "network-only" : undefined }
      )
      .valueChanges.pipe(this.mapMembersInfo());
  }

  getMemberInfoOf(
    householdId: string,
    memberType: MemberTypes
  ): Observable<Nullable<Member>> {
    return this.getMemberInfo(householdId).pipe(
      map((member) => member.find((mem) => mem.type === memberType))
    );
  }

  /**
   * Watch the apollo cache of a member with a certain type of a household.
   *
   * @param householdId
   * @param options Gives the opportunity to force an initial fetch. This
   * is sometimes useful, when you don't want to receive potential outdated
   * cache values on subscribe.
   */
  watchMemberInfoOf(
    householdId: string,
    memberType: MemberTypes,
    options?: { fetchInitial: boolean }
  ): Observable<Nullable<Member>> {
    return this.watchMemberInfo(householdId, options).pipe(
      map((member) => member.find((mem) => mem.type === memberType))
    );
  }

  updateMemberInfo(input: UpdateMemberInfo): Observable<Member> {
    return this.updateMembersInfoGQL.mutate({ input }).pipe(
      map((mutationResults) => mutationResults.data?.updateMemberInfo.member),
      throwOnNullishOperator(
        new AirError(MemberService, "Update member info failed")
      ),
      map((member: MemberGraphQL) => this.memberMapper.toRawType(member)),
      catchError((error) => throwError(() => ErrorUtil.transformError(error)))
    );
  }

  isMemberRetired(householdId: string, memberId: string): Observable<boolean> {
    return this.getMemberInfo(householdId).pipe(
      map((members) => members.find((member) => member.id === memberId)),
      throwOnNullishOperator(
        new AirError(MemberService, "Member doesn't exist")
      ),
      map((member) => member.isMemberRetired),
      catchError((error) => {
        throw ErrorUtil.transformError(error);
      })
    );
  }

  areMembersRetired(householdId: string): Observable<boolean> {
    return this.getMemberInfo(householdId).pipe(
      map((members) => members.every((member) => member.isMemberRetired))
    );
  }

  watchAreMembersRetired(householdId: string): Observable<boolean> {
    return this.watchMemberInfo(householdId).pipe(
      map((members) => members.every((member) => member.isMemberRetired)),
      distinctUntilChanged()
    );
  }

  private mapMembersInfo(): OperatorFunction<
    ApolloQueryResult<GetMembersInfoQuery>,
    Member[]
  > {
    return pipe(
      map((res) => {
        const members =
          res.data.householdById.household?.members?.filter(isNotNullish);
        if (members == null || members.length === 0) {
          throw new AirError(
            MemberService,
            "Houshold doesn't have any members"
          );
        }
        return members;
      }),
      map((members) =>
        members.map((member) => this.memberMapper.toRawType(member))
      ),
      catchError((error) => {
        throw ErrorUtil.transformError(error);
      })
    );
  }
}
