import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { first } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class AirboardConfigurationService {
  private readonly isComparingSubject = new BehaviorSubject<boolean>(false);

  isComparing$ = this.isComparingSubject.asObservable();

  setComparingTo(isComparing: boolean): void {
    this.isComparingSubject.next(isComparing);
  }

  toggleComparing(): void {
    this.isComparing$
      .pipe(first())
      .subscribe((isComparingEnabled) =>
        this.isComparingSubject.next(!isComparingEnabled)
      );
  }
}
