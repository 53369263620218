import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  UrlTree,
} from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { FlagsService } from "../flags.service";

@Injectable({
  providedIn: "root",
})
export class FlagGuard implements CanActivate {
  constructor(
    private readonly flagService: FlagsService,
    private readonly router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot
  ): Observable<boolean | UrlTree> | UrlTree {
    if (route.data["flag"] != null) {
      return this.flagService.hasFeature(route.data["flag"]).pipe(
        map((hasFeature) => {
          if (!hasFeature) {
            return this.router.createUrlTree(["forbidden"]);
          } else {
            return true;
          }
        })
      );
    } else {
      return this.router.createUrlTree(["forbidden"]);
    }
  }
}
