import { Component } from "@angular/core";

/**
 * This layout is meant to be used for pages that want to take the whole height of the screen.
 * This also means that the component being displayed in the router-outlet should
 * be larger that the screen size.
 */
@Component({
  selector: "app-full-screen",
  templateUrl: "./full-screen-layout.component.html",
})
export class FullScreenLayoutComponent {}
