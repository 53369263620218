<div *ngIf="visible" class="alert" [ngClass]="severity">
  <div class="flex items-baseline gap-3" [ngSwitch]="severity">
    <air-icon
      *ngSwitchCase="'warning'"
      faClasses="exclamation-triangle"
      variant="solid"
      size="lg"
      class="shrink-0"
    ></air-icon>
    <air-icon
      *ngSwitchCase="'error'"
      faClasses="times-circle"
      variant="solid"
      size="lg"
      class="shrink-0"
    ></air-icon>
    <air-icon
      *ngSwitchCase="'info'"
      faClasses="info-circle"
      variant="solid"
      size="lg"
      class="shrink-0"
    ></air-icon>
    <air-icon
      *ngSwitchCase="'success'"
      faClasses="check-circle"
      variant="solid"
      size="lg"
      class="shrink-0"
    ></air-icon>

    <span>{{ text | translate }}</span>
  </div>
  <air-button
    buttonType="button"
    [color]="severity"
    onlyIcon="times"
    variant="text"
    (click)="onClose()"
  >
    <span class="sr-only">Dismiss</span>
  </air-button>
</div>
