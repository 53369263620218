<div
  class="flex h-full cursor-pointer flex-col items-center justify-center gap-4 rounded-xl border border-primary bg-white p-4 text-primary transition-colors duration-300 hover:bg-grey-light hover:shadow-sm"
  *ngIf="category"
>
  <air-icon class="mx-auto" [faClasses]="category.icon" size="2xl"></air-icon>

  <span class="body-xl text-current">{{
    categoryTranslationKey | translate
  }}</span>
</div>
