import { GrossIncome } from "./gross-income.type";

export interface Employment {
  id: string;
  employmentCategory: EmploymentCategories;
  federalState: FederalStates;
  isPhysicalWork: boolean;
  hasPensionPaymentObligation: boolean;
  grossIncome?: GrossIncome;
}

export interface EmploymentInput {
  employmentCategory?: EmploymentCategories;
  federalState?: FederalStates;
  isPhysicalWork?: boolean;
  hasPensionPaymentObligation?: boolean;
}

export interface CreateEmploymentInput extends EmploymentInput {
  memberId: string;
  yearlyGrossIncome?: number;
}

export interface UpdateEmploymentInput extends EmploymentInput {
  employmentId: string;
}

export enum EmploymentCategories {
  UNKNOWN = "UNKNOWN",
  EMPLOYEE = "EMPLOYEE",
  UNEMPLOYED = "UNEMPLOYED",
  PUBLIC_SERVANT = "PUBLIC_SERVANT",
  CIVIL_SERVANT = "CIVIL_SERVANT",
  SELF_EMPLOYED = "SELF_EMPLOYED",
  MINIJOB_EMPLOYED = "MINIJOB_EMPLOYED",
  APPRENTICE = "APPRENTICE",
  EXECUTIVE = "EXECUTIVE",
  PENSIONER = "PENSIONER",
  CIVIL_SERVANT_PENSIONER = "CIVIL_SERVANT_PENSIONER",
}

export enum FederalStates {
  UNKNOWN = "UNKNOWN",
  BADEN_WUERTTEMBERG = "BADEN_WUERTTEMBERG",
  BAVARIA = "BAVARIA",
  BERLIN = "BERLIN",
  BRANDENBURG = "BRANDENBURG",
  BREMEN = "BREMEN",
  HAMBURG = "HAMBURG",
  HESSE = "HESSE",
  LOWER_SAXONY = "LOWER_SAXONY",
  MECKLENBURG_WEST_POMERANIA = "MECKLENBURG_WEST_POMERANIA",
  NORTH_RHINE_WESTPHALIA = "NORTH_RHINE_WESTPHALIA",
  RHINELAND_PALATINATE = "RHINELAND_PALATINATE",
  SAARLAND = "SAARLAND",
  SAXONY = "SAXONY",
  SAXONY_ANHALT = "SAXONY_ANHALT",
  SCHLESWIG_HOLSTEIN = "SCHLESWIG_HOLSTEIN",
  THURINGIA = "THURINGIA",
}
