import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { SharedModule } from "../shared/shared.module";
import { OptInComponent } from "./opt-in.component";

@NgModule({
  declarations: [OptInComponent],
  imports: [CommonModule, SharedModule],
  exports: [OptInComponent],
})
export class OptInModule {}
