import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AutoLoginAllRoutesGuard } from "angular-auth-oidc-client";
import { environment } from "../environments/environment";
import { AppRoutingModule } from "./app-routing.module";
import { DataPrivacyModule } from "./data-privacy/data-privacy.module";
import { LoginComponent } from "./login/login.component";
import { FullScreenLayoutComponent } from "./shared/layouts/full-screen-layout/full-screen-layout.component";

// TODO: Compile time check that needs to be refactored
const loginCanActivate = environment.flags.features.auth
  ? [AutoLoginAllRoutesGuard]
  : [];

const routes: Routes = [
  {
    path: "",
    pathMatch: "full",
    redirectTo: "login",
  },
  { path: "login", component: LoginComponent },
  {
    path: "data-privacy",
    component: FullScreenLayoutComponent,
    loadChildren: (): Promise<typeof DataPrivacyModule> =>
      import("./data-privacy/data-privacy.module").then(
        (m) => m.DataPrivacyModule
      ),
  },
  {
    path: "",
    loadChildren: (): Promise<typeof AppRoutingModule> =>
      import("./app-routing.module").then((m) => m.AppRoutingModule),
    canActivate: loginCanActivate,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { paramsInheritanceStrategy: "always" }),
  ],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
