export interface Name {
  firstName: string;
  lastName: string;
}

/**
 * Formats a first and last name to a full name string.
 *
 * @param firstName String with first name
 * @param lastName String with last name
 */
export function formattedFullName(firstName: string, lastName: string): string;
/**
 * Formats a `Name` to a full name string.
 *
 * @param fullName A Name with a first and last name
 */
export function formattedFullName(fullName: Name): string;
export function formattedFullName(
  firstNameOrFullName: string | Name,
  lastName?: string
): string {
  let fullName: Name;
  if (typeof firstNameOrFullName === "string") {
    if (lastName == null) {
      throw new Error("If firstName is a string, lastName must be defined");
    }
    fullName = {
      firstName: firstNameOrFullName,
      lastName,
    };
  } else {
    fullName = firstNameOrFullName;
  }
  return [fullName.firstName, fullName.lastName].join(" ").trim();
}
