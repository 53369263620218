import { TriggerRecalculateGQL } from "@air-gmbh/data-access/graphql";
import { Injectable } from "@angular/core";
import { first } from "rxjs/operators";

@Injectable()
export class RecalculateService {
  constructor(private readonly triggerRecalculateGQL: TriggerRecalculateGQL) {}

  recalculate(householdId: string): void {
    this.triggerRecalculateGQL
      .mutate({
        input: { householdId },
      })
      .pipe(first())
      .subscribe();
  }
}
