import {
  IncomeTaxCategory as IncomeTaxCategoryGQL,
  PropertyUsages,
} from "@air-gmbh/data-access/graphql";
import {
  AssetCategory,
  IncomeTaxCategory,
  Property,
  RealEstateSubcategory,
} from "@air-gmbh/util/types";
import { Injectable } from "@angular/core";
import { isNotNullish } from "@tool-belt/type-predicates";
import { Nullable } from "ts-toolbelt/out/Union/Nullable";
import { TypeMapper } from "../../common/mapper.interface";
import { LoanAssetGQL, LoanAssetMapper } from "../loans";

export interface PropertyGQL {
  id: string;
  name: string;
  usageStatus: PropertyUsages;
  value: number;
  isInventory: boolean;
  capitalCommitmentYears?: Nullable<number>;
  estimatedValueIncreasePerYear?: Nullable<number>;
  taxCategoryOfRent: IncomeTaxCategoryGQL;
  monthlyRentIncome?: Nullable<number>;
  comments?: Nullable<string>;
  linkedLoans: Nullable<LoanAssetGQL>[];
}

@Injectable()
export class PropertyMapper implements TypeMapper<PropertyGQL, Property> {
  constructor(private readonly loanAssetMapper: LoanAssetMapper) {}

  toRawType(graphQLType: PropertyGQL): Property {
    return {
      category: AssetCategory.LOANS,
      id: graphQLType.id,
      amount: graphQLType.value,
      subcategory: RealEstateSubcategory[graphQLType.usageStatus],
      name: graphQLType.name,
      isInventory: graphQLType.isInventory,
      capitalCommitmentYears: graphQLType.capitalCommitmentYears ?? undefined,
      taxIncomeCategory: IncomeTaxCategory[graphQLType.taxCategoryOfRent],
      estimatedIncreaseInValue:
        graphQLType.estimatedValueIncreasePerYear ?? undefined,
      rent: graphQLType.monthlyRentIncome ?? undefined,
      comments: graphQLType.comments ?? undefined,
      linkedAssets: graphQLType.linkedLoans
        .filter(isNotNullish)
        .map((value) => this.loanAssetMapper.toRawType(value)),
    };
  }
}
