import { MonoTypeOperatorFunction, OperatorFunction } from "rxjs";
import { distinctUntilChanged, filter } from "rxjs/operators";
import { throwOnNullish } from "./predicates.util";

/**
 * DistinctUntilChanged for objects which may have different ids.
 * Performs a deep comparison by comparing stringified values.
 */
export function distinctUntilObjectChanged<T>(): MonoTypeOperatorFunction<T> {
  return distinctUntilChanged(
    (previous, current) => JSON.stringify(previous) === JSON.stringify(current)
  );
}

export function throwOnNullishOperator<T>(
  err: Error
): OperatorFunction<T | null | undefined, NonNullable<T>> {
  return filter(throwOnNullish(err));
}
