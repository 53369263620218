import { Asset, AssetCategory, CashSubcategory } from "@air-gmbh/util/types";
import { Injectable } from "@angular/core";
import { TypeMapper } from "../../common/mapper.interface";

export interface CashAssetGQL {
  id: string;
  name: string;
  value: number;
  isInventory: boolean;
}

@Injectable()
export class CashAssetMapper implements TypeMapper<CashAssetGQL, Asset> {
  toRawType(cashAsset: CashAssetGQL): Asset {
    return {
      id: cashAsset.id,
      name: cashAsset.name,
      category: AssetCategory.CASH,
      subcategory: CashSubcategory.CASH,
      amount: cashAsset.value,
      isInventory: cashAsset.isInventory,
    };
  }
}
