import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { LogoComponent } from "./logo.component";

@NgModule({
  imports: [CommonModule],
  exports: [LogoComponent],
  declarations: [LogoComponent],
})
export class LogoModule {}
