export enum ROLES {
  ADMIN = "admin",
  COMPANION = "companion",
  CLIENT = "client",
}

export const REDIRECT_LOGIN: { [key: string]: string } = {
  [ROLES.ADMIN]: "admin/companions",
  [ROLES.COMPANION]: "companion/clients",
  [ROLES.CLIENT]: "airboard",
};

export type RolesCheck = Record<`is${Capitalize<ROLES>}`, boolean>;

export const RANKED_ROLES: Record<ROLES, number> = {
  [ROLES.ADMIN]: 0,
  [ROLES.COMPANION]: 1,
  [ROLES.CLIENT]: 2,
};
