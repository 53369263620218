import { UiChipModule } from "@air-gmbh/ui/chip";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { InputWrapperComponent } from "./input-wrapper.component";

@NgModule({
  declarations: [InputWrapperComponent],
  imports: [CommonModule, UiChipModule, TranslateModule],
  exports: [InputWrapperComponent],
})
export class InputWrapperModule {}
