import {
  HouseholdVersionTypes,
  HouseholdVersionTypes as HouseholdVersionTypesGQL,
} from "@air-gmbh/data-access/graphql";
import { HouseholdIds } from "@air-gmbh/util/types";
import { Injectable } from "@angular/core";
import { isNotNullish } from "@tool-belt/type-predicates";
import { Nullable } from "ts-toolbelt/out/Union/Nullable";
import { TypeMapper } from "../common/mapper.interface";

export interface HouseholdIdGQL {
  id: string;
  isCurrentHousehold: boolean;
  householdType: HouseholdVersionTypesGQL;
}

@Injectable({ providedIn: "root" })
export class HouseholdIdsMapper
  implements TypeMapper<Nullable<HouseholdIdGQL>[], HouseholdIds>
{
  toRawType(householdIdsGQL: Nullable<HouseholdIdGQL>[]): HouseholdIds {
    return householdIdsGQL.filter(isNotNullish).reduce<HouseholdIds>(
      (collectedIds, householdRes) => {
        let householdType: "current" | "suggested" =
          householdRes.isCurrentHousehold ? "current" : "suggested";
        let householdVersion: "companion" | "client" =
          householdRes.householdType === HouseholdVersionTypes.Client
            ? "client"
            : "companion";
        collectedIds[householdVersion][householdType] = householdRes.id;

        return collectedIds;
      },
      { companion: {}, client: {} }
    );
  }
}
