import { EmploymentCategories } from "@air-gmbh/data-access/graphql";
import { Settings } from "@air-gmbh/util/types";
import { Injectable } from "@angular/core";
import { isNotNullish } from "@tool-belt/type-predicates";
import { Nullable } from "ts-toolbelt/out/Union/Nullable";
import { TypeMapper } from "../common/mapper.interface";

export interface SettingsGraphQL {
  id: string;
  considerInflation: boolean;
  isRetirementCostIncludedInMinimumCostCalculation: boolean;
  members: Array<
    Nullable<{
      id: string;
      employmentsV2: Array<
        Nullable<{
          id: string;
          employmentCategory: EmploymentCategories;
        }>
      >;
    }>
  >;
}

@Injectable()
export class SettingsMapper implements TypeMapper<SettingsGraphQL, Settings> {
  toRawType(graphQLType: SettingsGraphQL): Settings {
    const areMembersRetired: boolean =
      graphQLType.members.length > 0
        ? graphQLType.members
            .filter(isNotNullish)
            .map((mem) =>
              mem.employmentsV2
                .filter(isNotNullish)
                .some((emp) =>
                  [
                    EmploymentCategories.CivilServantPensioner,
                    EmploymentCategories.Pensioner,
                  ].includes(emp.employmentCategory)
                )
            )
            .every((isRetired) => isRetired === true)
        : false;

    return {
      considerInflation: graphQLType.considerInflation,
      considerRetirement:
        graphQLType.isRetirementCostIncludedInMinimumCostCalculation,
      areMembersRetired,
    };
  }
}
