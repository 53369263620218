import { UiButtonModule } from "@air-gmbh/ui/button";
import { UiIconModule } from "@air-gmbh/ui/icon";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { AlertComponent } from "./alert.component";

@NgModule({
  declarations: [AlertComponent],
  imports: [CommonModule, UiButtonModule, UiIconModule, TranslateModule],
  exports: [AlertComponent],
})
export class UiAlertModule {}
