import { TourStatus } from "@air-gmbh/util/constants";
import { Lifestyle } from "@air-gmbh/util/types";

export const LIFESTYLE_KEYS: Array<keyof Lifestyle> = [
  "food",
  "utilityCost",
  "rent",
  "clothing",
  "education",
  "media",
  "vacation",
  "mobility",
  "miscellaneous",
  "buffer",
];

export enum LifestyleSection {
  TODAY = "today",
  RETIREMENT = "retirement",
  MINIMUM_INCOME = "minimum-income",
}

export const LIFESTYLE_SECTIONS_TO_TOUR_STATUS: Record<
  LifestyleSection,
  TourStatus | undefined
> = {
  [LifestyleSection.TODAY]: TourStatus.LifestyleToday,
  [LifestyleSection.RETIREMENT]: TourStatus.LifestylePension,
  [LifestyleSection.MINIMUM_INCOME]: TourStatus.LifestyleInvalid,
};
