import { C } from "ts-toolbelt";

export class AirError extends Error {
  constructor(
    protected readonly source: C.Class | string,
    protected readonly reason: string
  ) {
    super(`${AirError.getSourceName(source)}: ${reason}`);
    this.name = this.constructor.name;
  }

  private static getSourceName(source: C.Class | string): string {
    if (typeof source === "string") {
      return source;
    } else {
      return source.name;
    }
  }
}

export class MissingIdError extends AirError {
  constructor(source: C.Class | string, idName: string = "id") {
    super(source, `Missing ${idName}`);
  }
}

export class NullishError extends AirError {
  constructor(source: C.Class | string, name: string) {
    super(source, `${name} is nullish`);
  }
}
