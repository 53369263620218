import { LifestyleBalance, LoadingAmount } from "@air-gmbh/util/types";
import { Injectable } from "@angular/core";
import { Nullable } from "ts-toolbelt/out/Union/Nullable";
import { TypeMapper } from "../common/mapper.interface";

export type LifestyleBalanceGraphQL = {
  id: string;
  lifestyleBalanceV2?: Nullable<{
    today: number;
    total: LoadingAmount;
    retirement: LoadingAmount;
    minimum: LoadingAmount;
  }>;
};

@Injectable()
export class LifestyleBalanceMapper
  implements TypeMapper<LifestyleBalanceGraphQL, LifestyleBalance>
{
  toRawType(graphQLType: LifestyleBalanceGraphQL): LifestyleBalance {
    return (
      graphQLType.lifestyleBalanceV2 ?? {
        today: 0,
        total: {
          amount: 0,
          isRecalculating: true,
        },
        retirement: {
          amount: 0,
          isRecalculating: true,
        },
        minimum: {
          amount: 0,
          isRecalculating: true,
        },
      }
    );
  }
}
