import { HouseholdService } from "@air-gmbh/data-access/household";
import { LogoVariant } from "@air-gmbh/ui/assets";
import { ParamsService } from "@air-gmbh/util/general";
import {
  Component,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
} from "@angular/core";
import {
  BehaviorSubject,
  combineLatest,
  EMPTY,
  Subject,
  Subscription,
} from "rxjs";
import { switchMap } from "rxjs/operators";
import { PrintEditPageService } from "../../services/print-edit-page.service";
import { RedirectService } from "../../services/redirect.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  providers: [ParamsService],
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() hasStepBar = false;
  @Input() showClientBadge = false;
  @Input() fullWidth = false;
  @Input() logoVariant: LogoVariant = "default";

  collapsed = false;
  startRoute: string = "";
  householdId?: string;
  showRecalculateBtn = false;

  private positionSubject = new Subject<number>();
  private deltaYSubject = new BehaviorSubject<number>(0);

  private sub = new Subscription();

  // Scroll event
  @HostListener("window:scroll", ["$event.target"])
  onScroll(el: Document): void {
    this.positionSubject.next(
      el.scrollingElement?.scrollTop ?? document.body.scrollTop
    );
  }

  // Wheel event
  @HostListener("window:wheel", ["$event"])
  onWheel(event: WheelEvent): void {
    this.deltaYSubject.next(event.deltaY);
  }

  constructor(
    private readonly redirectService: RedirectService,
    private readonly printEditPageService: PrintEditPageService,
    private readonly paramsService: ParamsService,
    private readonly householdService: HouseholdService
  ) {}

  ngOnInit(): void {
    this.sub.add(
      combineLatest([this.positionSubject, this.deltaYSubject]).subscribe(
        ([pos, deltaY]) => {
          this.collapsed = pos > 0 || deltaY > 0;
        }
      )
    );

    this.sub.add(
      this.redirectService.getRedirectRoute().subscribe((route) => {
        this.startRoute = [""].concat(route).join("/");
      })
    );

    this.sub.add(
      this.paramsService
        .hasParam("householdId")
        .pipe(
          switchMap((hasHouseholdId) => {
            // Don't return anything if we are on a route without a householdId
            // (e.g. tour, companion interface, admin interface, ...)
            if (!hasHouseholdId) {
              return EMPTY;
            }
            return this.paramsService.getParam("householdId").pipe(
              switchMap((householdId) => {
                this.householdId = householdId;
                return this.householdService.hasHouseholdChanged(
                  this.householdId
                );
              })
            );
          })
        )
        .subscribe((hasChanged) => {
          this.showRecalculateBtn = hasChanged;
        })
    );
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  onPrint(): void {
    this.printEditPageService.print();
  }
}
