import { ButtonColor, ButtonVariant } from "./button.component";

export const BUTTON_CONFIG: Record<
  ButtonVariant,
  Record<ButtonColor, string>
> = {
  contained: {
    primary:
      "border-0 bg-primary text-white hover:bg-primary-dark focus:bg-primary-dark focus:ring-primary-dark",
    secondary:
      "border-0 bg-secondary text-white hover:bg-secondary-dark focus:bg-secondary-dark focus:ring-secondary-dark",
    error: "border-error-700 bg-error-700 text-white focus:ring-error-400",
    info: "border-info-700 bg-info-700 text-white focus:ring-info-400",
    success:
      "border-success-700 bg-success-700 text-white focus:ring-success-400",
    warning:
      "border-warning-700 bg-warning-700 text-white focus:ring-warning-400",
    black: "border-black bg-black text-white focus:ring-grey",
  },
  outlined: {
    primary:
      "border-primary text-primary hover:border-primary-dark hover:bg-primary-lighter hover:text-primary-dark focus:border-primary-dark focus:bg-primary-lighter focus:text-primary-dark",
    secondary:
      "border-secondary text-secondary hover:border-secondary-dark hover:bg-secondary-lighter hover:text-secondary-dark focus:border-secondary-dark focus:bg-secondary-lighter focus:text-secondary-dark",
    error: "border-error-700 text-error-700 hover:bg-error-50",
    info: "border-info-700 text-info-700 hover:bg-info-50",
    success: "border-success-700 text-success-700 hover:bg-success-50",
    warning: "border-warning-700 text-warning-700 hover:bg-warning-50",
    black: "border-black text-black hover:bg-grey focus:bg-grey",
  },
  text: {
    primary:
      "text-primary hover:bg-primary-lighter hover:text-primary-dark focus:bg-primary-lighter focus:text-primary-dark",
    secondary:
      "text-secondary hover:bg-secondary-lighter hover:text-secondary-dark focus:bg-secondary-lighter focus:text-secondary-dark",
    error: "text-error-700 hover:bg-error-50",
    info: "text-info-700 hover:bg-info-50",
    success: "text-success-700 hover:bg-success-50",
    warning: "text-warning-700 hover:bg-warning-50",
    black: "text-black hover:bg-grey focus:bg-grey",
  },
};
