import { EmploymentCategories as EmploymentCategoriesGQL } from "@air-gmbh/data-access/graphql";
import { EmploymentCategories } from "@air-gmbh/util/types";

export const EmploymentCategoriesToGQL = {
  [EmploymentCategories.UNKNOWN]: EmploymentCategoriesGQL.Unknown,
  [EmploymentCategories.EMPLOYEE]: EmploymentCategoriesGQL.Employee,
  [EmploymentCategories.UNEMPLOYED]: EmploymentCategoriesGQL.Unemployed,
  [EmploymentCategories.PUBLIC_SERVANT]: EmploymentCategoriesGQL.PublicServant,
  [EmploymentCategories.CIVIL_SERVANT]: EmploymentCategoriesGQL.CivilServant,
  [EmploymentCategories.SELF_EMPLOYED]: EmploymentCategoriesGQL.SelfEmployed,
  [EmploymentCategories.MINIJOB_EMPLOYED]:
    EmploymentCategoriesGQL.MinijobEmployed,
  [EmploymentCategories.APPRENTICE]: EmploymentCategoriesGQL.Apprentice,
  [EmploymentCategories.EXECUTIVE]: EmploymentCategoriesGQL.Executive,
  [EmploymentCategories.PENSIONER]: EmploymentCategoriesGQL.Pensioner,
  [EmploymentCategories.CIVIL_SERVANT_PENSIONER]:
    EmploymentCategoriesGQL.CivilServantPensioner,
};
