import { AssetCategory } from "@air-gmbh/util/types";

export const ASSET_ICONS: Record<AssetCategory, string> = {
  [AssetCategory.CASH]: "coins",
  [AssetCategory.ILLIQUID_ASSETS]: "chart-mixed",
  [AssetCategory.LIQUID_ASSETS]: "money-bill-transfer",
  [AssetCategory.LOANS]: "landmark",
  [AssetCategory.REAL_ESTATE]: "house-building",
  [AssetCategory.PENSION_INSURANCES]: "piggy-bank",
};
