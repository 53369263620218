export type WishCategory = {
  icon: string;
  /**
   * Path for translation
   */
  name: string;
};

export enum WishSize {
  SMALL,
  LARGE,
}

export function earliestRealizationYear(): number {
  return new Date().getUTCFullYear() + 1;
}
