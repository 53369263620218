import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import * as Sentry from "@sentry/angular";
import { Integrations } from "@sentry/tracing";
import packageJson from "../../../package.json";
import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";

if (environment.sentry.reporting) {
  Sentry.init({
    environment: environment.environmentName,
    dsn: environment.sentry.dsn,
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: [
          "localhost:4200",
          environment.frontend_url,
          environment.graphql.uri,
          environment.graphql.wsUri,
        ],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],
    tracesSampleRate: 1.0,
    release: `v${packageJson.version}`,
  });
}

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
