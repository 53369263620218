import { Inject, Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { ENVIRONMENT_CONFIG, IEnvironment, IFlags } from "./environment-types";

/**
 * Gets feature flags and variants.
 */
@Injectable({ providedIn: "root" })
export class FlagsService {
  constructor(
    @Inject(ENVIRONMENT_CONFIG) private readonly environment: IEnvironment
  ) {}

  #flags = this.environment.flags;

  /**
   * Checks whether a feature is activated.
   *
   * @param featureName Name of the feature to check
   * @returns If a feature should be activated
   */
  hasFeature<T extends keyof IFlags["features"]>(
    featureName: T
  ): Observable<boolean> {
    return of(this.#flags.features[featureName]);
  }

  /**
   * Get the current value of a variant.
   *
   * @param variantName Name of the variant to check
   * @returns Value of the variant
   */
  getVariant<T extends keyof IFlags["variants"]>(
    variantName: T
  ): Observable<IFlags["variants"][T]> {
    return of(this.#flags.variants[variantName]);
  }
}
