import { SpinnerColor, SpinnerSize } from "@air-gmbh/ui/loader";
import { AfterViewInit, Component, ElementRef, Input } from "@angular/core";
import { BUTTON_CONFIG } from "./button.config";

export type ButtonVariant = "outlined" | "contained" | "text";
export type ButtonColor =
  | "primary"
  | "secondary"
  | "error"
  | "info"
  | "success"
  | "warning"
  | "black";
export type ButtonType = "button" | "submit";

@Component({
  selector: "air-button",
  templateUrl: "./button.component.html",
  styleUrls: ["./button.component.css"],
})
export class ButtonComponent implements AfterViewInit {
  @Input() variant: ButtonVariant = "contained";
  @Input() buttonType: ButtonType = "button";
  @Input() color: ButtonColor = "primary";
  @Input() leftIcon?: string;
  @Input() rightIcon?: string;
  @Input() onlyIcon?: string;
  @Input() disabled = false;
  @Input() halfIconSpin = false;
  @Input() isLoading = false;
  @Input() buttonClass = "";

  isSpinning = false;

  get spinnerColor(): SpinnerColor {
    if (this.variant === "contained") {
      return SpinnerColor.WHITE;
    } else {
      return {
        primary: SpinnerColor.PRIMARY,
        secondary: SpinnerColor.SECONDARY,
        error: SpinnerColor.ERROR,
        info: SpinnerColor.INFO,
        success: SpinnerColor.SUCCESS,
        warning: SpinnerColor.WARNING,
        black: SpinnerColor.BLACK,
      }[this.color];
    }
  }

  get spinnerSize(): SpinnerSize {
    return SpinnerSize.SMALL;
  }

  get buttonClasses(): string {
    return BUTTON_CONFIG[this.variant][this.color];
  }

  constructor(private readonly elementRef: ElementRef) {}

  ngAfterViewInit(): void {
    this.removeInternalClickListeners();
  }

  onClick(event: MouseEvent): void {
    if (this.halfIconSpin) {
      this.isSpinning = true;
    }
    if (this.disabled || this.isLoading) {
      event.stopPropagation();
    }
  }

  /**
   * This is a hack to fix an inconsistent behavior
   * in chrome/chromium, where child elements of
   * disabled buttons still emit events
   */
  private removeInternalClickListeners(): void {
    const self = this.elementRef.nativeElement as HTMLElement;
    const btn = self.getElementsByTagName("button")[0];
    if (btn) {
      const elems = btn.getElementsByTagName("*");

      for (let i = 0; i < elems.length; ++i) {
        const elem = elems[i] as HTMLElement;
        if (elem != null && elem.style != null) {
          elem.style.pointerEvents = "none";
        }
      }
    }
  }
}
