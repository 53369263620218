import { AuthService } from "@air-gmbh/data-access/auth";
import { SessionStorageService } from "@air-gmbh/data-access/session-storage";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-login",
  template: "",
})
export class LoginComponent implements OnInit {
  constructor(
    private readonly authService: AuthService,
    private readonly sessionStorageService: SessionStorageService
  ) {}

  ngOnInit(): void {
    this.sessionStorageService.clear();
    this.authService.login();
  }
}
